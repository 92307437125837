<template>
  <b-card>
    <feather-icon
      icon="EditIcon"
      size="16"
      class="mr-50 text-secondary edit-aboutprofile"
      v-b-toggle.sidebar-right-about
      v-if="userDataLocal.hashid == aboutData.hashid"
    />
    <b-carousel :interval="10000" controls id="about-profile" indicators touch>
      <b-carousel-slide>
        <div class="about-details">
          <b-card-text>
            <h5 class="text-capitalize mb-75">
              More About <span class="font-weight-bolder">{{ aboutData.name }}</span>
            </h5>

            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Full Name: </span>
              <span> {{ aboutData.name }}</span>
            </div>
            <!-- <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Location: </span>
              <span> {{ aboutData.lives }}</span>
            </div> -->
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Language: </span>
              <span> {{ aboutData.language }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Bio: </span>
              <span> {{ aboutData.bio }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Date of Join: </span>
              <span> {{ aboutData.date_of_join }}</span>
            </div>
            <div class="my-1 v-list-item-title" v-if="!$can('community_access')">
              <span class="font-medium me-1">Employee ID: </span>
              <span> {{ aboutData.employee_id }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Zircly Role: </span>
              <span> {{ aboutData.role }}</span>
            </div>
            <div class="my-1 v-list-item-title" v-if="aboutData.shift">
              <span class="font-medium me-1">{{ aboutData.shift }} </span>
              <span>
                ({{ aboutData.shift_details.session_1_start_from | formatTime }}
                -
                {{
                  aboutData.shift_details.session_2_end_at | formatTime
                }})</span
              >
            </div>
            <!-- <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Local Time: </span>
              <span> India 06:30PM</span>
            </div> -->
          </b-card-text>
          <b-card-text class="mt-2" v-if="!$can('community_access')" >
            <!-- Work -->
            <h5 class="text-capitalize mb-75 font-weight-bolder">Work</h5>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Team: </span>
              <span> {{ aboutData.department }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Manager: </span>
              <span> {{ aboutData.reporting_manager_name }}</span>
            </div>
          </b-card-text>
        </div>
      </b-carousel-slide>
      <b-carousel-slide>
        <div class="about-details">
          <b-card-text>
            <!-- Work -->
            <h5 class="text-capitalize mb-75 font-weight-bolder">Contact</h5>
            <!-- <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Slack: </span>

            </div> -->
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Email: </span>
              <span> {{ aboutData.email }}</span>
            </div>
          </b-card-text>
          <b-card-text class="mt-2">
            <h5 class="text-capitalize mb-75 font-weight-bolder">
              Let's Talk About
            </h5>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Hobbies: </span>
              <span>{{ aboutData.hobbies }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Music: </span>
              <span> {{ aboutData.music }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Movies: </span>
              <span> {{ aboutData.movies }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Shows: </span>
              <span> {{ aboutData.shows }}</span>
            </div>
          </b-card-text>
        </div>
      </b-carousel-slide>
      <b-carousel-slide>
        <div class="about-details">
          <b-card-text>
            <!-- Work -->
            <h5 class="text-capitalize mb-75 font-weight-bolder">
              Currently Reading
            </h5>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Book Title: </span>
              <span> {{ aboutData.book }}</span>
            </div>
            <div class="my-1 v-list-item-title">
              <span class="font-medium me-1">Author Name: </span>
              <span> {{ aboutData.author }}</span>
            </div>
          </b-card-text>
          <b-card-text class="mt-2">
            <h5 class="text-capitalize mb-75 font-weight-bolder">
              Highly Recommend
            </h5>
            <div
              class="my-1 v-list-item-title"
              v-for="(book, index) in aboutData.highly_recommend_books"
              :key="index"
            >
              <span class="font-medium me-1" v-if="book"
                >Book Title :
              </span>
              <span> {{ book }}</span>
            </div>
          </b-card-text>
        </div>
      </b-carousel-slide>
    </b-carousel>
    <b-sidebar
      id="sidebar-right-about"
      title="Sidebar"
      bg-variant="white"
      right
      shadow
      backdrop
      no-header
      width="50%"
      :visible="show"
      @shown="syncData()"
      no-close-on-backdrop
    >
      <template #default="{ hide }">
        <b-link class="text-primary floating-close close-icon" @click="hide">
          <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
        </b-link>
        <b-form id="editabout-form" class="p-2">
          <b-row>
            <!-- About -->
            <b-col sm="12">
              <div
                class="d-flex align-items-center justify-content-between"
                v-b-toggle.moreabout
              >
                <h5 class="border-bottom w-100 mt-1 pb-50">
                  More About
                  <span class="font-weight-bolder">{{
                    aboutData.first_name
                  }}</span>
                </h5>
                <feather-icon icon="ChevronDownIcon" size="24" class="mr-50" />
              </div>
              <p class="small">
                Tell the {{ aboutData.company }} Zircly more about yourself. Share your
                location, local
              </p>
              <b-collapse id="moreabout" class="">
                <!-- <b-form-group label="Location" label-for="location">
                  <b-input-group>
                    <b-form-input
                      id="location"
                      type="text"                      
                      placeholder="eg: India"
                    />
                  </b-input-group>
                </b-form-group> -->
                <b-form-group label="Language" label-for="language">
                  <b-input-group>
                    <b-form-input
                      id="language"
                      type="text"
                      v-model="form.language"
                      placeholder="eg: English"
                    />
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-col>
            <!-- end About -->

            <!-- work -->
            <b-col sm="12" v-if="!$can('community_access')">
              <div
                class="d-flex align-items-center justify-content-between"
                v-b-toggle.aboutwork
              >
                <h5 class="border-bottom w-100 my-1 pb-50">Work</h5>
                <feather-icon icon="ChevronDownIcon" size="24" class="mr-50" />
              </div>
              <b-collapse id="aboutwork" class="">
                <b-form-group label="Team" label-for="team">
                  <v-select
                    v-model="form.department_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="departmentOptions"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="false"
                    :searchable="true"
                    input-id="department"
                    value.sync="department_id"
                    placeholder="Select Department.."
                  />
                </b-form-group>
              </b-collapse>
            </b-col>
            <!-- end Work -->

            <!-- Contact -->
            <b-col sm="12">
              <div
                class="d-flex align-items-center justify-content-between"
                v-b-toggle.aboutcontact
              >
                <h5 class="border-bottom w-100 my-1 pb-50">Contact</h5>
                <feather-icon icon="ChevronDownIcon" size="24" class="mr-50" />
              </div>
              <b-collapse id="aboutcontact" class="">
                <b-form-group
                  :label="`Communication Channel ${aboutData.channel_type}`"
                  label-for="slack"
                >
                  <b-input-group>
                    <b-form-input
                      id="slack"
                      type="text"
                      v-model="form.channel_id"
                      placeholder="eg: Jane Doe"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Personal Email" label-for="personal_email">
                  <b-input-group>
                    <b-form-input
                      id="personal_email"
                      v-model="form.personal_email"
                      type="text"
                      placeholder="eg: test@example.com"
                    />
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-col>
            <!-- end Contact-->
            <!-- Let's Talk About -->
            <b-col sm="12">
              <div
                class="d-flex align-items-center justify-content-between"
                v-b-toggle.letstalk
              >
                <h5 class="border-bottom w-100 my-1 pb-50">Let's Talk About</h5>
                <feather-icon icon="ChevronDownIcon" size="24" class="mr-50" />
              </div>
              <b-collapse id="letstalk" class="">
                <b-form-group label="Hobbies" label-for="hobbies">
                  <b-input-group>
                    <b-form-input
                      id="hobbies"
                      v-model="form.hobbies"
                      type="text"
                      placeholder="eg: Reading Books"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Music" label-for="music">
                  <b-input-group>
                    <b-form-input
                      id="music"
                      v-model="form.music"
                      type="text"
                      placeholder="eg: aaaa"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Movies" label-for="movies">
                  <b-input-group>
                    <b-form-input
                      id="movies"
                      v-model="form.movies"
                      type="text"
                      placeholder="eg: short story"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Shows" label-for="show">
                  <b-input-group>
                    <b-form-input
                      id="shows"
                      v-model="form.shows"
                      type="text"
                      placeholder="eg: Jane Doe"
                    />
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-col>
            <!-- end Let's Talk About-->
            <!-- Currently Reading -->
            <b-col sm="12">
              <div
                class="d-flex align-items-center justify-content-between"
                v-b-toggle.aboutreading
              >
                <h5 class="border-bottom w-100 my-1 pb-50">
                  Currently Reading
                </h5>
                <feather-icon icon="ChevronDownIcon" size="24" class="mr-50" />
              </div>
              <b-collapse id="aboutreading" class="">
                <b-form-group label="Book Title" label-for="book-title">
                  <b-input-group>
                    <b-form-input
                      id="book"
                      type="text"
                      v-model="form.book"
                      placeholder="eg: Reading Book"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Author Name" label-for="author">
                  <b-input-group>
                    <b-form-input
                      id="author"
                      type="text"
                      v-model="form.author"
                      placeholder="eg: Jane Doe"
                    />
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-col>
            <!-- end Currently Reading-->
            <!-- Highly Recommend -->
            <b-col sm="12" class="py-2">
              <div
                class="d-flex align-items-center justify-content-between"
                v-b-toggle.aboutrecommend
              >
                <h5 class="border-bottom w-100 my-1 pb-50">Highly Recommend</h5>
                <feather-icon icon="ChevronDownIcon" size="24" class="mr-50" />
              </div>
              <b-collapse id="aboutrecommend" class="">
                <b-form-group
                  v-for="(book, index) in highly_recommend_books"
                  :key="index"
                  :label="`Book Title & Author ${index + 1}`"
                  :label-for="`title-author-${index}`"
                >
                  <b-input-group>
                    <b-form-input
                      :id="`title-author-${index}`"
                      type="text"
                      v-model="highly_recommend_books[index]"
                      placeholder="eg: Reading Book / Author: Jane Doe"
                    />
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-col>
            <!-- end Highly Recommend-->
            <b-col sm="12">
              <b-form-group
                class="d-flex align-items-center justify-content-end"
              >
                <b-button variant="outline-primary" @click="hide">Cancel</b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-1"
                  @click="formSubmitted()"
                  >Save</b-button
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCarouselSlide,
  BCarousel,
  CarouselPlugin,
  BButton,
  BButtonGroup,
  BDropdown,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormTextarea,
  BSidebar,
  VBTooltip,
  BCollapse,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import { CollapsePlugin } from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BButtonGroup,
    BDropdown,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormTextarea,
    BSidebar,
    VBTooltip,
    BCollapse,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isBusy: true,
      fullScreen: false,
      show: false,
      userDataLocal: this.$cookies.get("userData"),
      data_local: {
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        body: "",
        scheduled_time: "",
        scheduled_timezone: "",
        is_scheduled: false,
      },
      defaultForm: {
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        body: "",
        scheduled_time: "",
        scheduled_timezone: "",
        is_scheduled: false,
      },
      customFields: [],
      // timezones: timezones,
      form: {
        fullName: "",
        location: "",
        language: "",
        team: "",
        manager: "",
        slack: "",
        email: "",
        hobbies: "",
        music: "",
        movies: "",
        shows: "",
        book: "",
        author: "",
      },
      myTimezoneFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.offset.toLowerCase().indexOf(temp) > -1
        );
      },
      departmentOptions: [],
      highly_recommend_books: [
        "",
        "",
        "",
        "",
      ],
    };
  },
  created() {
    this.fetchDepartmentsList();

    // const objects = [
    //   "department_id",
    //   "personal_email",
    //   "channel_id",
    //   "hobbies",
    //   "music",
    //   "movies",
    //   "shows",
    //   "book",
    //   "author",
    //   "highly_recommend_books",
    // ];

    // objects.forEach((property) => {
    //   if (this.aboutData.hasOwnProperty(property)) {
    //     this.form[property] = this.aboutData[property];
    //   }
    // });
  },

  methods: {
    syncData() {
      const objects = [
        "department_id",
        "personal_email",
        "channel_id",
        "hobbies",
        "music",
        "movies",
        "shows",
        "book",
        "author",
        "highly_recommend_books",
      ];

      objects.forEach((property) => {
        if (this.aboutData.hasOwnProperty(property)) {
          this.form[property] = this.aboutData[property];
        }
      });
      if(this.aboutData.highly_recommend_books){
        this.highly_recommend_books = this.aboutData.highly_recommend_books;
      }
    },
    fetchDepartmentsList() {
      this.isBusy = true;

      this.$http
        .get("/list/departments")
        .then((res) => {
          this.departmentOptions = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    formSubmitted() {
      this.form.highly_recommend_books = this.highly_recommend_books;
      this.isBusy = true;
      this.$http
        .post("/user/update-profile-details", this.form)
        .then((res) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "BellIcon",
              variant: "success",
              text: "Profile Update success.",
            },
          });
          this.fetch_user_data(this.userDataLocal.hashid);
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error.message || error,
            },
          });
        });
    },
    fetch_user_data(userId) {
      const self = this;
      this.isBusy = true;
      let formData = {};
      formData.url = "profile/" + userId;
      this.$store
        .dispatch("app/get", formData)
        .then((res) => {
          this.userAbout = res.data.data;
          this.isBusy = false;
        })
        .catch((err) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee data failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
  },
};
</script>
