<template>
  <div>
    <EventView
      :item-id.sync="eventId"
      :sidebar-event-view-active.sync="sidebarEventViewActive"
    />
    <b-carousel id="post_events" :interval="4000" :controls="recentEvents.length > 1 ? true : false" dark>
      <b-carousel-slide v-for="(event, index) in recentEvents" :key="index">
        <b-card
          tag="article"
          class="mb-0 p-0"
          :id="`event-card-${event.hashid}`"
          :img-src="event.poster_img"
          img-height="300px"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              visibilityChanged(isVisible, entry, event),
            once: true,
            throttle: 0,
            throttleOptions: {
              leading: 'visible',
            },
            intersection: {
              threshold: 0,
            },
          }"
        >
          <div class="d-flex mb-1 justify-content-between">
            <!-- Event Title and Organizer Name-->
            <div class="w-100">
              <b-card-title class="mb-1">{{
                event.name | str_limit(30)
              }}</b-card-title>
              <b-card-sub-title>
                {{ event.user.name | str_limit(30) }}
              </b-card-sub-title>
            </div>
            <!-- End Event Title and Organizer Name-->
          </div>
          <!-- Description -->
          <b-card-text v-html="event.description" class="description-view mb-75"> </b-card-text>
          <!-- <b-button class="view-more py-25 mb-1" variant="outline-primary maybe" pill v-b-toggle.view_event_sidebar @click="editRecord(event)">View More</b-button> -->
          <b-button class="view-more py-25 mb-1" :variant="(!event.isGoing || event.isGoing == 'No') ? 'primary' : 'outline-primary'" pill v-b-toggle.view_event_sidebar @click="editRecord(event)">View More</b-button>
          <!-- Event Details -->
          <div class="event-fullinfo">
            <div class="media1 mb-75 d-flex align-items-center pt-25">
              <div class="media-aside mr-75 align-this-center">
                <span
                  class="b-avatar text-dark"
                  style="width: 18px; height: 18px"
                  ><span class="b-avatar-custom">
                    <feather-icon size="18" icon="CalendarIcon" />
                  </span>
                </span>
              </div>
              <div class="media-body">
                <!-- <h6 class="mb-0">Sat, May 25, 2020</h6> -->
                <h6 class="mb-0">{{ event.start_date_n_time | formatDate }}</h6>
              </div>
            </div>
            <div class="media1 mb-75 d-flex align-items-center">
              <div class="media-aside mr-75 align-this-center">
                <span
                  class="b-avatar text-dark"
                  style="width: 18px; height: 18px"
                  ><span class="b-avatar-custom">
                    <feather-icon size="18" icon="ClockIcon" />
                  </span>
                </span>
              </div>
              <div class="media-body">
                <h6 class="mb-0">{{ event.start_date_n_time | formatTimeFormDate }} to {{ event.end_date_n_time | formatTimeFormDate }}</h6>
              </div>
            </div>
            <div class="media1 mb-75 d-flex align-items-center" v-if="event.video_conferencing != 'no' && event.join_url">
              <div class="media-aside mr-75 align-this-center">
                <span
                  class="b-avatar text-dark"
                  style="width: 18px; height: 18px"
                  ><span class="b-avatar-custom">
                    <feather-icon icon="ExternalLinkIcon" size="18"/>
                  </span>
                </span>
              </div>
              <div class="media-body" >
                <b-link
                variant="info"
                class="event-link"
                id="recent-event-copy-link"
                v-b-tooltip.hover.v-default
                v-b-tooltip.left
                title="Double Click Copy Link"
                v-if="event.video_conferencing == 'zoom' && event.join_url"
                @click="copy(event.join_url)"
                v-on:dblclick="counter += 1, open_join_url(event.join_url, event.hashid)"
              >
                Zoom Call
              </b-link>
              <b-link
                variant="info"
                class="event-link"
                id="recent-event-copy-link"
                v-if="event.video_conferencing == 'meet' && event.join_url"
                @click="copy(event.join_url)"
                v-on:dblclick="counter += 1, open_join_url(event.join_url, event.hashid)"
              >
                Google Meet
              </b-link>
              <b-tooltip target="copy-link" placement="top" class="copy-tooltip">
                <!-- Copy icon code is here -->
              <b-button
                type="button"
                v-clipboard:copy="joinUrl(event.join_url, event.hashid)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                target="_blank"
                variant="flat-secondary"
                class="bg-transparent"
              >
                <feather-icon icon="CopyIcon" /> <span>Click to Copy</span>
              </b-button>
              </b-tooltip>
              </div>
            </div>
            <div class="media1 mb-1 d-flex align-items-center" v-if="event.video_conferencing == 'zoom'">
              <div class="media-aside mr-75 align-this-center">
                <span
                  class="b-avatar"
                  style="width: 18px; height: 18px"
                  ><span class="b-avatar-custom">
                    <b-img :src="require('@/assets/images/icons/security.svg')" width="20"/>
                  </span>
                </span>
              </div>
              <div class="media-body">
                <b-link variant="info" class="b-avatar-custom">{{event.password}}</b-link>
              </div>
            </div> 
          </div>         
        <div class="divider"></div>
        <div class="event-accpt d-flex align-items-center justify-content-between">
          <h6 class="mb-0">Going?</h6>
          <div class="demo-inline-spacing justify-content-start">
            <b-nav-item-dropdown
              right
              :toggle-class="(!event.isGoing || event.isGoing == 'No') ? 'd-flex align-items-center dropdown-user-link option-going' : 'd-flex align-items-center dropdown-user-link option-going active'"
              class="dropdown-user"
            >
              <template #button-content>
                <div class="d-sm-flex d-none user-nav text-center">
                  <p class="user-name font-weight-bolder mb-0 w-100">
                    {{ event.isGoing || 'Yes' }}
                  </p>
                  <!-- <span class="user-status"> {{ userData.designation }}</span> -->
                </div>
              </template>
              <b-dropdown-item v-for="option in optionsSelect" link-class="d-flex align-items-center" @click="event.isGoing = option; updateIsGoing(event)">
                <span>{{option}}</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- <b-form-group>
              <v-select
                v-model="event.isGoing"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsSelect"
                :clearable="false"
                :searchable="true"
                class="w-100"
                @input="updateIsGoing(event)"
              />
            </b-form-group> -->
          </div>
        </div>
        </b-card>
      </b-carousel-slide>
      </b-carousel>
      <!-- Loader -->
      <b-col cols="12" order="4" v-if="busy">
        <div
          class="text-center mb-2 d-flex align-items-center justify-content-center"
        >
          <div
            :show="busy"
            class="d-flex align-items-center"
          >
            <b-spinner
              variant="primary"
              class="m-1"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </b-col>
      <!-- No Event Data -->
      <b-col cols="12" order="4" v-else>
        <div
          v-if="recentEvents.length == 0"
          class="text-center my-5 empty-data"
        >
        <div v-if="isBusy" class="text-center my-5 empty-data"
          >
            <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="100" class="mr-50" style="background-color: transparent" />
            <h4> Loading </h4>
            <p class="d-block mb-1">Please Wait</p>
          </div>
          <div v-else>
          <b-img :src="require('@/assets/images/icons/eventpoll/empty-event.svg')" size="101" class="mb-2" />
          <b-img :src="require('@/assets/images/icons/eventpoll/dark-event.svg')" size="101" class="mb-2 dark-eventimg d-none"/>
          <h4>No Upcoming Events at the Moment!</h4>
          <b-button class="mt-1 mt-lg-0 blog-btn-block" variant="outline-primary" v-b-toggle.add-event-sidebar pill>
            <span class="text-nowrap">Create Event</span>
          </b-button>
          </div>
        </div>
      </b-col>
  
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import QuillInput from "@/components/QuillInput.vue";
import EventView from "@/views/feed/events/EventView.vue";
import { bus } from "@/main.js";
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
    BCardActions,
    BCardActionsContainer,
    EventView,
  },
  props: {
    recentEvents:{
      type:Array
    }
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  data() {
    return {
      isBusy: true,
      newUserData: this.$cookies.get("userData"),
      sidebarEventViewActive: false,
      eventId: "",
      optionsSelect: [
        'Yes',
        'No',
        'Maybe',
      ],
      busy: false,
      lastPage: 1,
      nextPage: 1,
      currentPage: 1,
      type: "",
    };
  },
  computed: {},
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    recentEvents(val){
      if(val.length >= 0){
        this.isBusy = false;
      }
    },
    sidebarEventAddActive(val) {
      this.$refs.add_event_sidebar.show();
    },
    searchQuery(val) {
      if (val.length > 3 || val.length == 0) {
        this.getEvents();
      }
    },
  },
  methods: {
    joinUrl(url, hash_id) {
      let new_url = this.$router.resolve({
        path: "/join",
        query: { type: "event", hash_id: hash_id, url: url },
      });
      return window.location.origin + new_url.href;
    },
    visibilityChanged(isVisible, entry, customArgument) {
      if (isVisible) {
        this.isVisible = isVisible;
        this.getEventData(customArgument.hashid);
      }
    },
    getEventData(itemId) {
      if (!itemId) {
        console.log("no itemId");
        return true;
      }
      
      let data = {};
      data.url = "/events/" + itemId;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    open_join_url(url, hash_id) {
      let data = {};
      data.url = url;
      console.log(url);
      let routeData = this.$router.resolve({
        path: "/join",
        query: { type: "event", hash_id: hash_id, url: url },
      });
      return window.open(routeData.href, "_blank");
    },
    copy(copyText){
      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    editRecord(event) {
      this.eventId = event.hashid;
    },
    getEvents() {
      
      let data = {};
      data.url = "/events";
      data.params = { q: this.searchQuery, type: this.type,createdBy: this.$route.params.id,perPage:3 };
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.recentEvents = res.data.data;
          this.busy = false;
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.currentPage = res.data.meta.current_page;
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Events list Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary rounded-pill",
          cancelButton: "btn btn-outline-danger rounded-pill ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    deleteRecord(id) {
      
      let data = {};
      data.url = "events/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
            document.getElementById("event-card-" + id).remove();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Event Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Event Deleted Successfully.",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Event Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          // this.refetchData();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Event Deletion failed.1",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },

    
    openWindow(link) {
      window.open(link);
    },

    updateIsGoing(event) {
      
      if (!event.hashid) {
        console.log("no itemId");
        return true;
      }

      let data = {};
      data.url = "/events/" + event.hashid+ "/update-status";
      data.params = {
        isGoing: event.isGoing,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>