<template>
  <div>
    <PollEdit
      :item-id.sync="pollId"
      :sidebar-poll-edit-active.sync="sidebarPollEditActive"
    />
    <!-- <div class="d-flex justify-content-between align-items-center mb-2"> -->
    <!-- <b-button variant="primary" pill v-b-toggle.add-poll-sidebar>
        <span class="text-nowrap">Create Poll</span>
      </b-button> -->
    <!-- <b-link class="text-body" @click="getPolls()">
        <feather-icon icon="RefreshCcwIcon" size="18" class="mr-25" />
      </b-link> -->
    <!-- </div> -->
    <div
      ref="div-scroll"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isBusy"
      infinite-scroll-distance="10"
    >
      <b-input-group class="input-group-merge mb-1 round" v-if="false">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          placeholder="Search Title"
        />
      </b-input-group>
      <div v-for="(poll, index) in polls" :key="index">
        <b-card
          tag="article"
          class="mb-2"
          :id="`poll-card-${poll.hashid}`"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              visibilityChanged(isVisible, entry, poll),
            once: true,
            throttle: 0,
            throttleOptions: {
              leading: 'visible',
            },
            intersection: {
              threshold: 0,
            },
          }"
        >
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div class="d-flex align-items-center">
              <!-- avatar -->
              <b-avatar :src="poll.user.avatar" size="50" class="mr-1" />
              <!--/ avatar -->
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ poll.user.name }}
                </h6>
                <small class="text-muted">{{
                  poll.created_at | formatDateTime
                }}</small>
              </div>
            </div>
            <b-dropdown
              class="blog-dropdown"
              v-if="
                (($can('poll_delete') || $can('poll_edit')) &&
                  poll.user.hashid == userData.hashid) ||
                $can('Zircly Admin')
              "
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!-- <b-dropdown-item
              class="btn-sm p-0"
              v-b-toggle.view_poll_sidebar
              @click="editRecord(poll)"
              v-if="$can('poll_show')"
            >
              <feather-icon icon="EyeIcon" size="13" class="mr-50" />
              <span>View</span>
            </b-dropdown-item> -->
              <b-dropdown-item
                class="btn-sm p-0"
                v-b-modal.edit-poll-sidebar
                @click="editRecord(poll)"
                v-if="
                  ($can('poll_edit') && poll.user.hashid == userData.hashid) ||
                  $can('Zircly Admin')
                "
              >
                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(poll)"
                v-if="
                  ($can('poll_delete') &&
                    poll.user.hashid == userData.hashid) ||
                  $can('Zircly Admin')
                "
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <b-card-text>
            <h2 class="text-default">{{ poll.title }}</h2>
          </b-card-text>
          <div v-if="!poll.expired && !poll.showResults">
            <h3 class="qst">{{ poll.description }}</h3>
            <div v-if="poll.allow_multiple == 'true'">
              <b-form-checkbox
                v-for="(option, k) in poll.options"
                :key="k"
                :name="index + 'pollCheckbox' + k"
                :value="option.hashid"
                class="custom-control-primary mt-1"
                v-model="poll.userChoise"
              >
                {{ option.option_title }}
              </b-form-checkbox>
            </div>
            <div v-else>
              <b-form-radio
                v-for="(option, k) in poll.options"
                :key="k"
                :name="index + 'pollCheckbox' + k"
                :value="option.hashid"
                v-model="poll.userChoise"
                class="custom-control-primary mt-1"
              >
                {{ option.option_title }}
              </b-form-radio>
            </div>

            <b-row class="mt-1 align-items-center">
              <b-col md="7" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addVote(poll)"
                >
                  Vote
                </b-button>
                <b-button
                  class="ml-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="viewStats(index)"
                >
                  View Stats
                </b-button>
                <b-button
                  class="ml-1"
                  v-if="poll.voter_identity_type == 'identified'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  v-b-modal="'modal-lg-' + poll.hashid"
                >
                  View Voters
                </b-button>
              </b-col>
              <!--Voting-->
              <b-col md="5" class="mt-1" v-if="poll.limited_time == 'true'">
                <b-badge variant="light-primary poll-timer">
                  <p class="mb-1">Voting Ends in :</p>
                  <Countdown :deadline="poll.expires_in"></Countdown>
                </b-badge>
              </b-col>
            </b-row>
            <b-badge variant="light-primary" class="mt-1 mb-1">
              Poll Status : In Progress
            </b-badge>
            <b-badge
              v-if="poll.voter_identity_type == 'identified'"
              variant="info"
              class="badge-glow ml-1"
            >
              Identified Vote
            </b-badge>
            <b-badge
              v-else
              variant="danger"
              class="badge-glow ml-0 ml-lg-1 mt-2"
            >
              Anonymous Vote
            </b-badge>
          </div>
          <div class="poll-description" v-else>
            <vue-poll v-bind="poll.poll_data" />
            <b-button
              class="mt-1 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="closeStats(index)"
              v-if="!poll.expired"
            >
              Close Stats
            </b-button>

            <b-button
              class="mt-1"
              v-if="poll.voter_identity_type == 'identified'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              v-b-modal="'modal-lg-' + poll.hashid"
            >
              View Voters
            </b-button>
            <b-row class="mt-1">
              <b-col>
                <b-badge variant="light-success" v-if="!poll.expired">
                  Poll Status : In Progress
                </b-badge>
                <b-badge variant="light-success" v-else>
                  Poll Status : Completed
                </b-badge>
                <b-badge
                  v-if="poll.voter_identity_type == 'identified'"
                  variant="info"
                  class="badge-glow ml-1"
                >
                  Identified Vote
                </b-badge>
                <b-badge
                  v-else
                  variant="danger"
                  class="badge-glow ml-0 ml-lg-1 mt-2"
                >
                  Anonymous Vote
                </b-badge>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-modal
          :id="'modal-lg-' + poll.hashid"
          ok-only
          ok-title="Accept"
          centered
          size="lg"
          :title="poll.title"
        >
          <b-card-text>
            {{ poll.description }}
          </b-card-text>
          <app-collapse accordion class="poll-popup">
            <app-collapse-item
              v-for="(option, k) in poll.options"
              :key="k"
              :title="calcPercentage(poll,option.option_title)+': ' + option.option_title"
              :class="option.is_highest ?'highest-vote mb-1': 'mb-1'"
            >
              <b-avatar-group size="32px">
                <b-avatar
                  v-for="(voter, k) in option.voters"
                  v-b-tooltip.hover
                  class="pull-up"
                  :title="voter.name"
                  :src="voter.avatar"
                />
              </b-avatar-group>
            </app-collapse-item>
          </app-collapse>
        </b-modal>
      </div>
      <b-col cols="12" order="4" v-if="busy">
        <div
          class="text-center mb-2 d-flex align-items-center justify-content-center"
        >
          <div :show="busy" class="d-flex align-items-center">
            Loading More
            <b-spinner
              variant="primary"
              class="m-1"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </b-col>
      <b-col cols="12" order="4" v-else>
        <div
          v-if="polls.length == 0"
          class="text-center my-5 empty-data"
        >
        <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="200" class="mr-50" style="background-color: transparent" />
        <h4> OH NO!</h4>
        <p class="d-block mb-1">No results found!</p>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
  BFormRadio,
  BAvatarGroup,
  BAvatar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import QuillInput from "@/components/QuillInput.vue";
import PollEdit from "@/views/feed/polls/PollEdit.vue";
import VuePoll from "@/components/Poll.vue";
import Countdown from "@/views/feed/polls/CountDown.vue";
import { bus } from "@/main.js";

import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

import $ from "jquery";
import JQ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
    BCardActions,
    BCardActionsContainer,
    PollEdit,
    BFormRadio,
    VuePoll,
    Countdown,
    AppCollapse,
    AppCollapseItem,
    BAvatarGroup,
    BAvatar,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  model: {
    prop: "sidebarPollAddActive",
    event: "update:sidebarPollAddActive",
  },
  props: {},
  data() {
    return {
      isBusy: false,
      userData: this.$cookies.get("userData"),
      zoom_access: this.checksAccessToken(),
      sidebarPollEditActive: false,
      pollId: "",
      data_local: {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
        poster: "",
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      poster: "",
      polls: [],
      ip: null,
      searchQuery: "",
      busy: false,
      lastPage: 1,
      nextPage: 1,
      currentPage: 1,
    };
  },
  computed: {},
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarPollAddActive(val) {
      this.data_local = {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
      };
      this.$refs.add_poll_sidebar.show();
      this.generate();
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
    searchQuery(val) {
      if (val.length > 3 || val.length == 0) {
        this.getPolls();
      }
    },
  },
  created() {
    bus.$on("loadPolls", (params = "") => {
      this.searchQuery = params;
      this.searchQuery = params.q;
      this.getPolls();
    });
    // this.generate();
    this.getPolls();
  },
  mounted() {
    this.getCustomFields();
  },
  methods: {
    calcPercentage(poll,option_title){
      if (poll.poll_data.totalVotes === 0)
          return '0%';                
      
      //Calculate percent

      for (let index = 0; index < poll.poll_data.answers.length; index++) {
        const a = poll.poll_data.answers[index];
        if(a.text == option_title){
          if (!isNaN(a.votes) && a.votes > 0)
            return ( Math.round( (parseInt(a.votes)/poll.poll_data.totalVotes ) * 100) ) + '%'
          else
            return'0%';
        }
        
      }
      return'0%';

  },
    visibilityChanged(isVisible, entry, customArgument) {
      if (isVisible) {
        this.isVisible = isVisible;
        // console.log(Math.round(entry.intersectionRatio * 100) + '%')
        // console.log(entry.target.id);
        // console.log(isVisible);
        // console.log(entry.target.id);
        // console.log(customArgument.hashid);
        this.getPollData(customArgument.hashid);
      }
    },
    getPollData(itemId) {
      if (!itemId) {
        console.log("no itemId");
        return true;
      }
      const self = this;
      let data = {};
      data.url = "/polls/" + itemId;
      self.isBusy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.event_data = res.data.data;
          self.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadMore() {
      if (this.currentPage < this.lastPage) {
        console.log("loadMore");
        this.busy = true;
        let params = {};
        params = {
          page: this.nextPage,
          q: this.searchQuery,
          createdBy: this.$route.params.id,
        };
        this.$http
          .get(`/polls`, { params: params })
          .then((res) => {
            this.polls = this.polls.concat(res.data.data);
            this.nextPage += 1;
            this.lastPage = res.data.meta.last_page;
            this.currentPage = res.data.meta.current_page;
            this.busy = false;
          })
          .catch((error) => {
            this.busy = false;
          });
      }
    },
    editRecord(poll) {
      this.pollId = poll.hashid;
    },
    getPolls() {
      const self = this;
      const urlParams = new URLSearchParams(window.location.search);
      const employeeId = urlParams.get('employee_hash_id');
      let data = {};
      data.url = "/polls";
      data.params = { q: self.searchQuery, createdBy: this.$route.params.id ? this.$route.params.id : employeeId };
      this.busy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.polls = res.data.data;
          self.busy = false;
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.currentPage = res.data.meta.current_page;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Polls list Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    generate(characters = "a-z,A-Z,0-9", length = 10) {
      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < length; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.data_local.password = password;
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete Poll ${data.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary rounded-pill",
          cancelButton: "btn btn-outline-danger rounded-pill ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      let data = {};
      data.url = "polls/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
            document.getElementById("poll-card-" + id).remove();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Poll Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Poll Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Poll Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          // self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Poll Deletion failed.1",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },

    setEndDate(e) {
      if (this.data_local.end_date_n_time == "") {
        this.data_local.end_date_n_time = e;
      }
    },
    checksAccessToken() {
      if (this.$cookies.get("userData").zoom_linked === false) {
        return false;
      }
      return true;
    },
    loginWithZoom() {
      const self = this;
      var url =
        "https://zoom.us/oauth/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_ZOOM_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_ZOOM_REDIRECT_URI +
        "&state=" +
        self.userData.hashid;

      if (!self.zoom_access || !self.userData.zoom_linked) {
        var win = window.open(url, "Google", "width=600,height=600");

        var timer = setInterval(function () {
          if (win.closed) {
            clearInterval(timer);
            useJwt.getUserInfo().then((res) => {
              this.$cookies.set(
                "userData",
                res.data.data,
                60 * process.env.VUE_APP_LOGIN_TIMEOUT
              );
              self.userData = this.$cookies.get("userData");
              if (self.userData.zoom_linked) {
                self.zoom_access = true;
              }
            });
          }
        }, 1000);
      } else {
        self.zoom_access = true;
      }
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          self.channel_type_select = res.data.channel_type_select;
          self.identity_type_select = res.data.identity_type_select;
          self.feedback_type_select = res.data.feedback_type_select;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    resetFormData() {
      console.log("reset");
      this.$refs.accountRules.reset();

      // this.data_local = {
      //   priority: "medium",
      //   status: "open",
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("poll-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      let files = self.$refs.pond.getFiles();
      let keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      formData.delete("poster"); // data is the name of the original input file; import to delete
      let poster = self.$refs.poster.getFiles();

      formData.append(`poster`, poster[0].file);

      formData.append("description", self.data_local.description);
      formData.append("start_date_n_time", self.data_local.start_date_n_time);
      formData.append("end_date_n_time", self.data_local.end_date_n_time);
      formData.append("video_conferencing", self.data_local.video_conferencing);

      let data = {};
      data.url = "polls";
      self.data_local.files = this.myFiles;
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this poll to NHR
          this.isBusy = false;
          self.$refs.add_poll_sidebar.hide();
          self.data_local = {
            channel_type: "community",
            post_anonymously: false,
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Poll Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Poll Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("poll-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "posts/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this poll to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Poll Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Poll Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-posts/fetchPost", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    viewStats(pollIndex) {
      this.polls[pollIndex].showResults = true;
    },
    closeStats(pollIndex) {
      this.polls[pollIndex].showResults = false;
    },
    addVote(poll, index) {
      // console.log('You voted ' + poll.selectedOption + '! for poll '+poll.hashid);
      if (poll.allow_multiple == "true") {
        poll.selectedOptions = poll.userChoise.join();
      } else {
        poll.selectedOptions = poll.userChoise;
      }
      const self = this;
      let ip;
      JQ.ajax({
        dataType: "json",
        url: "http://ip-api.com/json",
        async: false,
        success: function (data) {
          ip = data.query;
        },
      });
      let data = {};
      data.url = "/poll/vote";
      data.params = {
        poll: poll.hashid,
        option: poll.selectedOptions,
        voter_ip: ip,
      };

      self.$store
        .dispatch("app/store", data)
        .then((res) => {
          console.log(res.status);
          this.getPolls();
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unablt to Cast Your Vote",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
