<template>
  <!-- :visible="true" -->
  <b-sidebar
    id="edit-event-sidebar"
    ref="edit_event_sidebar"
    bg-variant="white"
    :sidebar-class="fullScreen ? 'modal-full' : 'modal-responsive-width'"
    class="sidebar-lg modal-responsive-width custom-popmodal"
    shadow
    backdrop
    no-header
    right
    @shown="resetFormData"
    @change="(val) => $emit('update:sidebarEventEditActive', val)"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom sticky-top"
      >
        <h3 class="mb-0 font-weight-bold blog-title">Edit Event</h3>
        <div class="d-flex align-items-center">
          <!-- <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
            pill
          >
            Cancel
          </b-button> -->
          <b-button
            pill
            variant="outline-primary"
            @click="formUpdate()"
            class="px-3 blog-btn mr-1"
            :disabled="isBusy"
          >
            Update
          </b-button>
          <b-link class="fullscreen-icon"  @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-default title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-default title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
          </b-link>
        </div>
      </div>
      <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="confirmCloseButton" />

      <!-- BODY -->
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="eventEditForm" class="p-2">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Event Name"
                  rules="required"
                >
                  <label class="required-l" for="name">Event Name</label>
                  <b-form-input
                    id="name"
                    v-model="data_local.name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Event Location"
                  rules=""
                >
                  <label class="" for="location">Event Location</label>
                  <b-form-input
                    id="location"
                    v-model="data_local.location"
                    name="location"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="start_date_n_time"
                  >Starts at</label
                >
                <flat-pickr
                  id="start_date_n_time"
                  v-model="data_local.start_date_n_time"
                  class="form-control"
                  @on-change="setEndDate"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    altFormat: 'M j, h:iK',
                    altInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="end_date_n_time">Ends at</label>
                <flat-pickr
                  id="end_date_n_time"
                  v-model="data_local.end_date_n_time"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    altFormat: 'M j, h:iK',
                    altInput: true,
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="description">
                  Description
                </label>
                <div class="post-content">
                  <QuillInput
                    :value.sync="data_local.description"
                    :toolbar="true"
                    :mentionList="employeeListOptions"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="false">
              <b-form-group>
                <label class="required-l" for="video-conferencing"
                  >Add video conference</label
                >
                <v-select
                  v-model="data_local.video_conferencing"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    { id: 1, label: 'No', value: 'no' },
                    { id: 2, label: 'Meet', value: 'meet' },
                    { id: 3, label: 'Zoom', value: 'zoom' },
                  ]"
                  :reduce="(label) => label.value"
                  label="label"
                  input-id="video-conferencing"
                  placeholder="Select Type.."
                  :clearable="false"
                  :searchable="false"
                >
                </v-select>
              </b-form-group>

              <b-form-group
                v-if="
                  data_local.video_conferencing == 'zoom' &&
                  zoom_access == false
                "
              >
                <button
                  type="button"
                  class="btn btn-info rounded-pill"
                  style="height: 40px"
                  @click="loginWithZoom"
                >
                  Login Zoom
                </button>
              </b-form-group>

              <b-form-group v-show="data_local.video_conferencing == 'zoom'">
                <label class="required-l" for="zoom-password"
                  >Zoom Password</label
                >

                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="zoom-password"
                    v-model="data_local.password"
                    name="password"
                    autofocus
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      icon="RefreshCwIcon"
                      @click="generate()"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12" class="">
              <b-form-group
                label="Poster"
                label-for="files"
                v-show="!data_local.poster_img"
              >
                <file-pond
                  ref="poster"
                  name="poster_img"
                  max-file-size="5MB"
                  allow-paste="false"
                  max-files="1"
                  v-bind:files="data_local.poster"
                  image-preview-height="120px"
                  accepted-file-types="image/*"
                  file-validate-type-label-expected-types="Expects Image file"
                />
              </b-form-group>
              <div class="border-top pt-1" v-show="data_local.poster_img">
                <b class="text-secondary">Uploaded Poster</b>

                <div
                  class="d-flex justify-content-between align-items-center mt-1"
                >
                  <img
                    v-if="data_local.poster_img"
                    :src="data_local.poster_img"
                    class="img-fluid rounded"
                    width="100px"
                    height="100px"
                    alt="poster_img"
                  />

                  <div class="d-flex align-items-center demo-inline-spacing">
                    <b-button
                      pill
                      variant="outline-primary"
                      @click.stop.prevent="openWindow(data_local.poster_img)"
                      v-b-tooltip.hover.top="'View Poster'"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      pill
                      variant="outline-danger"
                      @click.stop.prevent="removePoster"
                      v-b-tooltip.hover.top="'Remove Poster'"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group label="Attachments" label-for="files">
                <file-pond
                  ref="pond"
                  name="files[]"
                  max-file-size="50MB"
                  allow-paste="false"
                  max-files="10"
                  allow-multiple="true"
                  v-bind:files="myFiles"
                  image-preview-height="120px"
                  accepted-file-types="image/*,video/*,application/pdf"
                  file-validate-type-label-expected-types="Expects a PDF, Images, Video file"
                />
                <small class="text-warning flex items-center">
                  File size should be less than 50MB and allowed formats are
                </small>
                <p>( .mp4,.pdf, image)</p>
              </b-form-group>
              <div
                class="border-top pt-1"
                v-if="
                  data_local.attachments && data_local.attachments.length > 0
                "
              >
                <b class="text-secondary">Uploaded Attachments</b>

                <div class="d-flex flex-column">
                  <div
                    v-for="(file, index) in data_local.attachments"
                    :key="index"
                    class="d-flex align-items-center justify-content-between my-1"
                  >
                    <img
                      v-if="file.type.includes('image')"
                      :src="file.link"
                      class="img-fluid1 rounded"
                      width="100px"
                      height="100px"
                      alt="Attachment"
                    />
                    <span
                      v-else
                      class="text-primary"
                      v-b-tooltip.hover.v-default
                      :title="file.name"
                    >
                      {{ file.name | str_limit(40) }}
                    </span>
                    <div class="d-flex align-items-center demo-inline-spacing">
                      <b-button
                        pill
                        variant="outline-primary"
                        @click.stop.prevent="openWindow(file.link)"
                        v-b-tooltip.hover.top="'View Attachment'"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button
                        pill
                        variant="outline-danger"
                        @click.stop.prevent="confirmDeleteRecord(file.uuid)"
                        v-b-tooltip.hover.top="'Remove Attachment'"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import QuillInput from "@/components/QuillInput.vue";
import { bus } from "@/main.js";
import $ from "jquery";
import bomb from "@/libs/bomb/bomb";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarEventEditActive",
    event: "update:sidebarEventEditActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarEventEditActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      fullScreen: false,
      newUserData: this.$cookies.get("userData"),
      zoom_access: this.checksAccessToken(),
      data_local: {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
        poster_img: "",
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      poster_img: "",
      employeeListOptions: [],
    };
  },
  computed: {},
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-events";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, eventStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarEventEditActive(val) {
      return true;
      this.data_local = {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
      };
      // this.$refs.edit_event_sidebar.show();
      this.generate();
      this.myFiles = [];
      this.poster_img = "";
      if (this.itemId) {
        this.fetchItem(this.itemId);
      }
    },
  },
  created() {},
  mounted() {
    this.getCustomFields();
    this.fetchEmployeeList();
  },
  methods: {
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$refs.edit_event_sidebar.hide();
          this.data_local = {
            name: "",
            location: "",
            start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
            end_date_n_time: moment().format("YYYY-MM-DD ") + "09:30",
            description: "",
            video_conferencing: "no",
            password: "",
            attachments: [],
          };
        }
      });
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    generate(characters = "a-z,A-Z,0-9", length = 10) {
      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < length; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.data_local.password = password;
    },
    confirmDeleteRecord(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeAttachment(uuid);
        }
      });
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "events/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          this.fetchItem(this.data_local.hashid);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deleted",
              icon: "BellIcon",
              variant: "success",
              text: "Attachment Deleted Successfully.",
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    removePoster() {
      let data = {};
      data.url = "events/poster/destroy/" + this.data_local.hashid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          this.fetchItem(this.data_local.hashid);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deleted",
              icon: "BellIcon",
              variant: "success",
              text: "Attachment Deleted Successfully.",
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
              text: res.data.message,
            },
          });
        });
    },
    setEndDate(e) {
      if (this.data_local.end_date_n_time == "") {
        this.data_local.end_date_n_time = e;
      }
    },
    checksAccessToken() {
      if (this.$cookies.get("userData").zoom_linked === false) {
        return false;
      }
      return true;
    },
    loginWithZoom() {
      const self = this;
      var url =
        "https://zoom.us/oauth/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_ZOOM_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_ZOOM_REDIRECT_URI +
        "&state=" +
        self.newUserData.hashid;

      if (!self.zoom_access || !self.newUserData.zoom_linked) {
        var win = window.open(url, "Google", "width=600,height=600");

        var timer = setInterval(function () {
          if (win.closed) {
            clearInterval(timer);
            useJwt.getUserInfo().then((res) => {
              this.$cookies.set(
                "userData",
                res.data.data,
                60 * process.env.VUE_APP_LOGIN_TIMEOUT
              );
              self.newUserData = this.$cookies.get("userData");
              if (self.newUserData.zoom_linked) {
                self.zoom_access = true;
              }
            });
          }
        }, 1000);
      } else {
        self.zoom_access = true;
      }
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/events/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          self.channel_type_select = res.data.channel_type_select;
          self.identity_type_select = res.data.identity_type_select;
          self.feedback_type_select = res.data.feedback_type_select;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status != 403) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: error.toString(),
              },
            });
          }
        });
    },
    resetFormData() {
      console.log("reset");
      this.$refs.accountRules.reset();

      this.data_local = {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
        poster: "",
      };
      this.generate();
      this.myFiles = [];
      this.poster_img = "";
      this.poster = "";
      if (this.itemId) {
        this.fetchItem(this.itemId);
      }
    },
    openWindow(link) {
      window.open(link);
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("eventEditForm"));

      formData.append("description", self.data_local.description);
      formData.append("start_date_n_time", self.data_local.start_date_n_time);
      formData.append("end_date_n_time", self.data_local.end_date_n_time);
      formData.append("video_conferencing", self.data_local.video_conferencing);

      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      let files = self.$refs.pond.getFiles();
      let keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      formData.delete("poster_img"); // data is the name of the original input file; import to delete
      let poster_img = self.$refs.poster.getFiles();
      if (poster_img.length > 0) {
        formData.append("poster_img", poster_img[0].file);
      }

      let data = {};
      data.url = "events/" + self.data_local.hashid;
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this event to NHR
          this.isBusy = false;
          bus.$emit("loadEvents");
          self.$refs.edit_event_sidebar.hide();
          this.resetFormData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Event Updated",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          bomb.realistic();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Event Updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      const self = this;

      let data = {};
      data.url = "/events/" + hashid;

      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
