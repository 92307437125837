<template>
  <div>
    <EventEdit
      :item-id.sync="eventId"
      :sidebar-event-edit-active.sync="sidebarEventEditActive"
    />
    <EventView
      :item-id.sync="eventId"
      :sidebar-event-view-active.sync="sidebarEventViewActive"
    />
    <div
      ref="div-scroll"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isBusy"
      infinite-scroll-distance="10"
    >
      <b-input-group class="input-group-merge mb-1 round" v-if="false">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          placeholder="Search Title"
        />
      </b-input-group>
      <!-- <div class="d-flex justify-content-between align-items-center mb-2"> -->
      <!-- <b-button variant="primary" pill v-b-toggle.add-event-sidebar>
        <span class="text-nowrap">Create Event</span>
      </b-button> -->
      <!-- <b-link class="text-body" @click="getEvents()">
        <feather-icon icon="RefreshCcwIcon" size="18" class="mr-25" />
      </b-link> -->
      <!-- </div> -->
      <div v-for="(event, index) in events" :key="index">
        <b-card
          tag="article"
          class="mb-2"
          :id="`event-card-${event.hashid}`"
          :img-src="event.poster_img"
          img-height="300px"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              visibilityChanged(isVisible, entry, event),
            once: true,
            throttle: 0,
            throttleOptions: {
              leading: 'visible',
            },
            intersection: {
              threshold: 0,
            },
          }"
        >
          <div class="d-flex mb-1 justify-content-between">
            <div class="pr-1 mr-1 border-right text-center">
              <p class="text-uppercase mb-0">
                {{ event.start_date_n_time | getMonth }}
              </p>
              <h2>
                {{ event.start_date_n_time | getDate }}
              </h2>
            </div>

            <div
              class="w-100"
            >
              <b-card-title class="">{{
                event.name | str_limit(30)
              }}</b-card-title>
              <b-card-sub-title>
                Organized by {{ event.user.name | str_limit(30) }}
              </b-card-sub-title>
            </div>
            <b-dropdown
              variant="link"
              class="blog-dropdown__event"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
              v-if="
                $can('event_show') || $can('event_edit') || $can('event_delete')
              "
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                class="btn-sm p-0"
                v-if="
                  !$can('event_show') &&
                  !$can('event_edit') &&
                  !$can('event_delete')
                "
              >
                <feather-icon icon="XOctagonIcon" size="13" class="mr-50" />
                <span>Access Required</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                v-b-toggle.view_event_sidebar
                @click="editRecord(event)"
                v-if="$can('event_show')"
              >
                <feather-icon icon="EyeIcon" size="13" class="mr-50" />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                v-b-toggle.edit-event-sidebar
                @click="editRecord(event)"
                v-if="
                  ($can('event_edit') &&
                    event.user.hashid == newUserData.hashid) ||
                  $can('Zircly Admin')
                "
              >
                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(event)"
                v-if="
                  ($can('event_delete') &&
                    event.user.hashid == newUserData.hashid) ||
                  $can('Zircly Admin')
                "
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- Event Date time and Meeting Link Details -->
          <div class="event-fullinfo short-note" v-b-toggle.view_event_sidebar
              @click="editRecord(event)">
            <div class="media1 mb-75 d-flex align-items-center pt-25">
              <div class="media-aside mr-75 align-self-center">
                <span
                  class="b-avatar text-dark"
                  style="width: 20px; height: 20px"
                  ><span class="b-avatar-custom">
                    <feather-icon size="20" icon="CalendarIcon" />
                  </span>
                </span>
              </div>
              <div class="media-body">
                <!-- <h6 class="mb-0">Sat, May 25, 2020</h6> -->
                <h6 class="mb-0">{{ event.start_date_n_time | formatDate }}</h6>
              </div>
            </div>
            <div class="media1 mb-75 d-flex align-items-center">
              <div class="media-aside mr-75 align-self-center">
                <span
                  class="b-avatar text-dark"
                  style="width: 20px; height: 20px"
                  ><span class="b-avatar-custom">
                    <feather-icon size="20" icon="ClockIcon" />
                  </span>
                </span>
              </div>
              <div class="media-body">
                <h6 class="mb-0">{{ event.start_date_n_time | formatTimeFormDate }} to {{ event.end_date_n_time | formatTimeFormDate }}</h6>
              </div>
            </div>
            <div class="media1 mb-75 d-flex align-items-center" v-if="event.video_conferencing != 'no' && event.join_url">
              <div class="media-aside mr-75 align-self-center">
                <span
                  class="b-avatar text-dark"
                  style="width: 20px; height: 20px"
                  ><span class="b-avatar-custom">
                    <feather-icon icon="ExternalLinkIcon" size="20"/>
                  </span>
                </span>
              </div>
              <div class="media-body">
                <b-link
                variant="info"
                target="_blank"
                class="event-link"
                id="copy-link"
                v-if="event.video_conferencing == 'zoom' && event.join_url"
                @click="open_join_url(event.join_url, event.hashid)"
              >
                Zoom Call
              </b-link>

              <b-link
                variant="info"
                class="event-link"
                id="recent-event-copy-link"
                v-if="event.video_conferencing == 'meet' && event.join_url"
                @click="open_join_url(event.join_url, event.hashid)"
              >
                Google Meet
              </b-link>
              <b-tooltip target="copy-link" placement="top" class="copy-tooltip">
                <!-- Copy icon code is here -->
              <b-button
                type="button"
                v-clipboard:copy="joinUrl(event.join_url, event.hashid)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                variant="flat-secondary"
                class="bg-transparent"
              >
                <feather-icon icon="CopyIcon" /> <span class="before-copy">Click to Copy</span>
                <span class="after-copy d-none">Copied</span>
              </b-button>
              </b-tooltip>
              </div>
            </div>
            <div class="media1 mb-1 d-flex align-items-center"  v-if="event.video_conferencing == 'zoom'">
              <div class="media-aside mr-75 align-self-center">
                <span
                  class="b-avatar"
                  style="width: 20px; height: 20px"
                  ><span class="b-avatar-custom">
                    <b-img :src="require('@/assets/images/icons/security.svg')" width="20"/>
                  </span>
                </span>
              </div>
              <div class="media-body">
                <h6 class="mb-0">{{event.password}}</h6>
              </div>
            </div>
          </div>
          <!--End-->
          <div class="d-flex align-items-center">
            <b-avatar-group
              size="34"
              class="avatar-icon d-none d-lg-flex mobile-avatar"
            >
              <b-avatar
                v-for="(user, i) in event.accepted_attendees"
                v-if="i < 5"
                :key="i"
                v-b-tooltip.hover.bottom="user.name"
                class="pull-up m-0 mobile-avatar d-none d-lg-block"
                :src="user.avatar"
              />
            </b-avatar-group>
            <b-avatar-group
              size="34"
              class="avatar-icon d-lg-none mobile-avatar"
            >
              <b-avatar
                v-for="(user, i) in event.accepted_attendees"
                v-if="i < 5"
                :key="i"
                v-b-tooltip.hover.bottom="user.name"
                class="pull-up m-0 mobile-avatar d-none d-lg-block"
                :src="user.avatar"
              />
            </b-avatar-group>
            <b-link
              v-if="event.accepted_attendees.length > 0"
              class="text-primary text-nowrap ml-50"
              v-b-modal="'show-attendees-' + event.hashid"
            >
              {{ event.accepted_attendees.length }} member attending this event.
            </b-link>
            <b-modal
              :id="'show-attendees-' + event.hashid"
              hide-footer
              modal-class="modal-primary"
              scrollable
              title="Attendees"
              class="text-danger"
            >
              <b-list-group
                v-if="
                  event.accepted_attendees && event.accepted_attendees.length
                "
              >
                <b-list-group-item
                  v-for="user in event.accepted_attendees"
                  :key="user.hashid"
                >
                  <b-avatar :src="user.avatar" size="24" class="mr-1" />
                  {{ user.name }}
                </b-list-group-item>
              </b-list-group>
              <b-card-text
                v-else-if="
                  event.accepted_attendees &&
                  event.accepted_attendees.length == 0
                "
              >
              <div class="item error text-center my-2 empty-data">
                <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
                <h4> OH NO!</h4>
                <p class="d-block mb-1">No Records Found!</p>
              </div>
              </b-card-text>
              <b-card-text v-else-if="event.accepted_attendees == null">
                <div class="d-flex align-items-center">
                  <strong>Loading...</strong>
                  <b-spinner class="ml-auto" variant="primary" />
                </div>
              </b-card-text>
            </b-modal>
          </div>
          <!-- <div v-if="event.join_url" class="d-flex justify-content-around">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              target="_blank"
              @click="open_join_url(event.join_url, event.hashid)"
            >
              Join Meeting
            </b-button>
            <b-button
              type="button"
              v-clipboard:copy="joinUrl(event.join_url, event.hashid)"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              variant="flat-secondary"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </div> -->
        </b-card>
      </div>
      <b-col cols="12" order="4" v-if="busy">
        <div
          class="text-center mb-2 d-flex align-items-center justify-content-center"
        >
          <div
            :show="busy"
            class="d-flex align-items-center"
          >
            Loading More
            <b-spinner
              variant="primary"
              class="m-1"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </b-col>
      <b-col cols="12" order="4" v-else>
        <div
          v-if="events.length == 0"
          class="text-center my-5 empty-data"
        >
        <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="200" class="mr-50" style="background-color: transparent" />
        <h4> OH NO!</h4>
        <p class="d-block mb-1">No results found!</p>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import QuillInput from "@/components/QuillInput.vue";
import EventEdit from "@/views/feed/events/EventEdit.vue";
import EventView from "@/views/feed/events/EventView.vue";
import { bus } from "@/main.js";
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
    BCardActions,
    BCardActionsContainer,
    EventEdit,
    EventView,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  data() {
    return {
      isBusy: false,
      newUserData: this.$cookies.get("userData"),
      zoom_access: this.checksAccessToken(),
      sidebarEventEditActive: false,
      sidebarEventViewActive: false,
      eventId: "",
      data_local: {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
        poster: "",
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      poster: "",
      events: [],
      searchQuery: "",
      busy: false,
      lastPage: 1,
      nextPage: 1,
      currentPage: 1,
      type: "",
    };
  },
  computed: {},
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarEventAddActive(val) {
      this.data_local = {
        name: "",
        location: "",
        start_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        end_date_n_time: moment().format("YYYY-MM-DD ") + "09:00",
        description: "",
        video_conferencing: "meet",
        password: "",
        attachments: [],
      };
      this.$refs.add_event_sidebar.show();
      this.generate();
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
    searchQuery(val) {
      if (val.length > 3 || val.length == 0) {
        this.getEvents();
      }
    },

    '$route.query': {
      handler(newQuery) {
        // Update component state when URL query parameters change
        this.searchQuery = newQuery.q || ''; // Assign default value if null
        this.type = newQuery.type || ''; // Assign default value if null
        this.getEvents()
      },
      immediate: true // Executes the handler immediately after the component is created
    }
  },
  created() {
    //this.getParams();
    bus.$on("loadEvents", (params = null) => {
      if(params){
        if(params.q){
          this.searchQuery = params.q
        }
        if(params.type){
          this.type = params.type
        }
      }
      this.getEvents();

    });
    this.generate();
    this.getEvents();

    
  },
  mounted() {
    this.getCustomFields();
  },

  methods: {
    getParams(){
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams)
      const search = urlParams.get('q');
      const type = urlParams.get('type');
      bus.$emit('AddFilter',type);
    },
    joinUrl(url, hash_id) {
      let new_url = this.$router.resolve({
        path: "/join",
        query: { type: "event", hash_id: hash_id, url: url },
      });
      return window.location.origin + new_url.href;
    },
    visibilityChanged(isVisible, entry, customArgument) {
      if (isVisible) {
        this.isVisible = isVisible;
        // console.log(Math.round(entry.intersectionRatio * 100) + '%')
        // console.log(entry.target.id);
        // console.log(isVisible);
        // console.log(entry.target.id);
        // console.log(customArgument.hashid);
        this.getEventData(customArgument.hashid);
      }
    },
    getEventData(itemId) {
      if (!itemId) {
        console.log("no itemId");
        return true;
      }
      const self = this;
      let data = {};
      data.url = "/events/" + itemId;
      self.isBusy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.event_data = res.data.data;
          self.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    open_join_url(url, hash_id) {
      let data = {};
      data.url = url;
      console.log(url);
      let routeData = this.$router.resolve({
        path: "/join",
        query: { type: "event", hash_id: hash_id, url: url },
      });
      return window.open(routeData.href, "_blank");
    },
    loadMore() {
      if (this.currentPage < this.lastPage) {
        console.log("loadMore");
        this.busy = true;
        let params = {};
        params = {
          page: this.nextPage,
          q: this.searchQuery,
          createdBy: this.$route.params.id,
        };
        this.$http
          .get(`/events`, { params: params })
          .then((res) => {
            this.events = this.events.concat(res.data.data);
            this.nextPage += 1;
            this.lastPage = res.data.meta.last_page;
            this.currentPage = res.data.meta.current_page;
            this.busy = false;
          })
          .catch((error) => {
            this.busy = false;
          });
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    editRecord(event) {
      this.eventId = event.hashid;
    },
    getEvents() {
      const self = this;
      const urlParams = new URLSearchParams(window.location.search);
      const filterType = urlParams.get('type');
      const employeeId = urlParams.get('employee_hash_id');

      let data = {};
      data.url = "/events";
      data.params = { q: self.searchQuery, 
        type: self.type ? self.type : filterType,
        createdBy: this.$route.params.id ? this.$route.params.id : employeeId, };
      self.busy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.events = res.data.data;
          self.busy = false;
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.currentPage = res.data.meta.current_page;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Events list Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    generate(characters = "a-z,A-Z,0-9", length = 10) {
      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < length; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.data_local.password = password;
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary rounded-pill",
          cancelButton: "btn btn-outline-danger rounded-pill ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      let data = {};
      data.url = "events/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
            document.getElementById("event-card-" + id).remove();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Event Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Event Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Event Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          // self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Event Deletion failed.1",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },

    setEndDate(e) {
      if (this.data_local.end_date_n_time == "") {
        this.data_local.end_date_n_time = e;
      }
    },
    checksAccessToken() {
      if (this.$cookies.get("userData").zoom_linked === false) {
        return false;
      }
      return true;
    },
    loginWithZoom() {
      const self = this;
      var url =
        "https://zoom.us/oauth/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_ZOOM_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_ZOOM_REDIRECT_URI +
        "&state=" +
        self.newUserData.hashid;

      if (!self.zoom_access || !self.newUserData.zoom_linked) {
        var win = window.open(url, "Google", "width=600,height=600");

        var timer = setInterval(function () {
          if (win.closed) {
            clearInterval(timer);
            useJwt.getUserInfo().then((res) => {
              this.$cookies.set(
                "userData",
                res.data.data,
                60 * process.env.VUE_APP_LOGIN_TIMEOUT
              );
              self.newUserData = this.$cookies.get("userData");
              if (self.newUserData.zoom_linked) {
                self.zoom_access = true;
              }
            });
          }
        }, 1000);
      } else {
        self.zoom_access = true;
      }
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          self.channel_type_select = res.data.channel_type_select;
          self.identity_type_select = res.data.identity_type_select;
          self.feedback_type_select = res.data.feedback_type_select;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: error.toString(),
              },
            });
          }
        });
    },
    resetFormData() {
      console.log("reset");
      this.$refs.accountRules.reset();

      // this.data_local = {
      //   priority: "medium",
      //   status: "open",
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("event-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      let files = self.$refs.pond.getFiles();
      let keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      formData.delete("poster"); // data is the name of the original input file; import to delete
      let poster = self.$refs.poster.getFiles();

      formData.append(`poster`, poster[0].file);

      formData.append("description", self.data_local.description);
      formData.append("start_date_n_time", self.data_local.start_date_n_time);
      formData.append("end_date_n_time", self.data_local.end_date_n_time);
      formData.append("video_conferencing", self.data_local.video_conferencing);

      let data = {};
      data.url = "events";
      self.data_local.files = this.myFiles;
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this event to NHR
          this.isBusy = false;
          self.$refs.add_event_sidebar.hide();
          self.data_local = {
            channel_type: "community",
            post_anonymously: false,
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Event Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Event Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("event-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "posts/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this event to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Event Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Event Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-posts/fetchPost", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
.event-fullinfo.short-note .media-body h6 {
  font-size: 16px;
}
</style>
