<template>
  <div v-if="profileData && Object.keys(profileData).length" id="user-profile">
    <!-- Header Card -->
    <b-card class="profile-header mb-2" :img-src="coverImg" img-top img-style="max-height:200px" alt="cover photo"
      body-class="p-0">
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img :src="profileData.avatar" class="w-100" rounded fluid alt="profile photo" />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-2">
            <h2 class="text-white mb-2">
              {{ profileData.first_name }}
            </h2>
            <!--/ profile title -->
          </div>
        </div>
        <!--/ profile picture -->
      </div>
      <!-- profile navbar -->
      <div class="profile-header-nav">
        <b-navbar toggleable="md" type="light" class="mb-0">
          <!-- toggle button -->
          <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
            <feather-icon icon="AlignJustifyIcon" size="21" />
          </b-navbar-toggle>
          <!--/ toggle button -->

          <!-- collapse -->
          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <div class="d-flex align-items-center justify-content-between w-100">
                  <b-tabs class="community-tabs">
                    <b-tab :active="channel.value == filterByType" @click="AddFilter(channel.value)"
                      v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value">
                      <template #title>
                        <span>{{ channel.label }}
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge[channel.value]"
                            v-b-modal="'unseen-' + channel.value" v-b-tooltip.hover.v-default
                            :title="unseenBadge[channel.value] + ' unseen post(s)'">
                            {{ unseenBadge[channel.value] }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('post')" v-if="!$route.params.id && userData.application_mode == 'company'">
                      <template #title>
                        <span>Feeds
                          <b-badge variant="primary" v-b-modal="'unseen-feeds'"
                            v-if="!$route.params.id && unseenBadge.feeds" v-b-tooltip.hover.v-default
                            :title="unseenBadge.feeds + ' unseen post(s)'">
                            {{ unseenBadge.feeds }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('event')" :active="filterByType == 'event'">
                      <template #title>
                        <span>Events
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.events"
                            v-b-modal="'unseen-events'" v-b-tooltip.hover.v-default
                            :title="unseenBadge.events + ' unseen event(s)'">
                            {{ unseenBadge.events }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('poll')" :active="filterByType == 'poll'">
                      <template #title>
                        <span>Polls
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.polls"
                            v-b-tooltip.hover.v-default :title="unseenBadge.polls + ' unseen poll(s)'">
                            {{ unseenBadge.polls }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('saved_posts')"
                      v-if="$route.params.id && $route.params.id == userData.hashid">
                      <template #title>
                        <span>Saved Posts </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('review_posts')"
                      v-if="$can('Zircly Admin') && userData.need_approval == 'true'">
                      <template #title>
                        <span>Review Posts </span>
                      </template>
                    </b-tab>

                    <!-- <b-tab v-if="onGoingProgram.hashid" @click="AddFilter('secret_santa_post')">
                      <template #title>
                        <span>Secret Santa Game
                          <b-avatar square style="background-color: transparent" v-b-tooltip.hover.v-default
                            title="Secret Santa Program" :to="{ name: 'secret-santa' }" :src="require('@/assets/images/secret-santa/santa-claus.png')
                              " v-if="$can('secret_santa_access') || $can('Zircly Admin')" size="sm" />
                        </span>
                      </template>
                    </b-tab> -->

                  </b-tabs>
                  <div class="d-flex align-items-center ref-info">
                    <div class="mr-1">
                      <b-link class="text-body">
                        <feather-icon icon="InfoIcon" size="18" class="mr-1" v-b-tooltip.hover.v-default
                          title="Channels Descriptions" v-b-modal.channel-descriptions-modal />
                      </b-link>
                      <b-link class="text-body ml-auto">
                        <feather-icon @click="refreshListData()" icon="RefreshCcwIcon" size="18" class="mr-35"
                          v-b-tooltip.hover.v-default title="Refresh" />
                      </b-link>
                    </div>
                    <b-button v-b-modal.modal-1 pill v-show="!userData.last_swipe_type ||
                      userData.last_swipe_type === 'Out'
                      " variant="outline-primary" class="mr-1 login-btn"
                      v-if="$can('swipe_access') && userData.hashid == profileData.hashid">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#38B22D"
                            stroke="#38B22D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check In </span>
                    </b-button>
                    <b-button v-b-modal.modal-2 v-show="userData.last_swipe_type === 'In'
                      " variant="outline-danger" class="mr-1 logout-btn" pill
                      v-if="$can('swipe_access') && userData.hashid == profileData.hashid">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#E82E2E"
                            stroke="#E82E2E" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check Out </span>
                    </b-button>
                    <b-button
                      v-if="$route.params.id && userData.hashid == profileData.hashid"
                        variant="primary"
                        class="ml-auto d-flex"
                        :to="{ name: 'profile' }"
                        pill
                      >
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span class="font-weight-bold d-none d-md-flex align-middle"
                          >Edit</span
                        >
                    </b-button>
                  </div>
                </div>

                <b-modal id="modal-1" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk" :hide-header="true"
                  :hide-footer="true" centered>
                  <div v-if="'No' in userData.previous_day_attendance_status">
                    <p class="my-1 text-center mobile-text">
                      Regularization Required for last working day -
                      {{
                        userData.previous_day_attendance_status.No | formatDate
                      }}, Please Apply for Regularization for last working day
                    </p>
                  </div>
                  <form ref="form" @submit.stop.prevent="handleOk">
                    <b-form-group label="" label-for="name-input" class="">
                      <p class="h2 my-1 text-center text-dark">
                        How are you feeling today?
                      </p>
                      <br />
                      <b-form-radio-group buttons class="mx-n1 my-2 feeling-smiley" size="sm" name="user_feeling"
                        v-model="swipeData.user_feeling" button-variant="light">
                        <b-form-radio v-for="option in options" :value="option.value" :key="option.text"
                          :class="`border-0 rounded-pill btn-light ml-1 m-0 mood-btn`">
                          <!-- style="width: 50px" -->
                          <b-img fluid :src="option.img" alt="mood" class="mr-1" />
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox value="6" v-model="swipeData.user_feeling" class="d-flex align-items-center">
                        Prefer not to say
                      </b-form-checkbox>
                    </b-form-group>
                    <div class="text-center">
                      <b-button type="submit" variant="outline-primary" v-if="swipeData.user_feeling">
                        Submit
                      </b-button>
                    </div>
                  </form>
                </b-modal>
                <b-modal id="modal-2" size="lg" @show="resetModal" @hidden="resetModal" @ok="checkout" :hide-header="true"
                  :hide-footer="true" centered>
                  <form ref="form" @submit.stop.prevent="checkout">
                    <b-form-group label="" label-for="name-input" class="">
                      <p class="h2 my-1 text-center text-dark">
                        How you felt today?
                      </p>
                      <br />
                      <b-form-radio-group buttons class="mx-n1 my-2 feeling-smiley" size="sm" name="user_feeling"
                        v-model="swipeData.user_feeling" button-variant="light">
                        <b-form-radio v-for="option in options" :value="option.value" :key="option.text"
                          :class="`border-0 rounded-pill btn-light ml-1 m-0 mood-btn`">
                          <!-- style="width: 50px" -->
                          <b-img fluid :src="option.img" alt="mood" />
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox value="6" v-model="swipeData.user_feeling" class="d-flex align-items-center">
                        Prefer not to say
                      </b-form-checkbox>
                    </b-form-group>
                    <div class="text-center">
                      <b-button type="submit" variant="outline-primary" v-if="swipeData.user_feeling">
                        Submit
                      </b-button>
                    </div>
                  </form>
                </b-modal>
                <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              v-b-modal.recent-swipes
              class="mr-1"
              pill
              v-if="$can('swipe_access') && userData.hashid == profileData.hashid"
              @click="getRecentSwipes"
            >
              Recent Swipes
            </b-button> -->
              </template>
              <!-- Recent Swipe Modal-->
              <b-modal id="recent-swipes" hide-footer size="lg" modal-class="modal-swipes d-none" centered
                title="Recent Swipes">
                <b-list-group v-if="recentSwipes && recentSwipes.length" class="swipes-group">
                  <b-list-group-item class="d-flex align-items-center swipe-mobile" v-for="swipe in recentSwipes"
                    :key="swipe.hashid">
                    <b>Date:</b><span>{{ swipe.date | formatDate }}</span>, <b>Time:</b>
                    <span>{{ swipe.time | formatTime }}</span>,<b>Swipe type:</b>
                    <span>{{ swipe.type }}</span>
                    <b-img v-if="swipe.user_feeling != 6" v-bind="{ width: 40, height: 40, class: 'mr-0 ml-auto' }" fluid
                      :src="mood_options[swipe.user_feeling]" alt="mood" />
                  </b-list-group-item>
                </b-list-group>
                <b-card-text v-else-if="recentSwipes && recentSwipes.length == 0">
                  <div class="item error text-center my-2 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                      style="background-color: transparent" />
                    <h4> OH NO!</h4>
                    <p class="d-block mb-1">No Records Found!</p>
                  </div>
                </b-card-text>
                <b-card-text v-else="recentSwipes == null">
                  <div class="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <b-spinner class="ml-auto" variant="primary" />
                  </div>
                </b-card-text>
              </b-modal>
            </b-tabs>
          </b-collapse>
          <!--/ collapse -->
        </b-navbar>
      </div>
      <!--/ profile navbar -->
    </b-card>
    <!-- End Header Card -->


    <!-- Posts Listing -->
    <div class="row px-2 mobile-size" id="profile-info">
      <div class="col-lg-8 offset-0 bottom-body">
        <div class="card col-xl-12 p-0" v-if="!$route.params.id">
          <div class="card-body">
            <div class="row" v-show="filterByType != 'event' && filterByType != 'poll'">
              <!-- <div :class="filterByType != 'post' ? 'col-sm-9' : 'col-sm-12'"> -->
              <div class="col-sm-8 col-md-8">
                <div class="position-relative search-posthead">
                  <b-input-group class="input-group-merge mb-1 round">
                    <b-form-input v-b-modal.create-custompop class="d-inline-block" placeholder="Whats on your mind?" />
                  </b-input-group>
                </div>
              </div>
              <div class="col-sm-4 col-md-4 pl-0" v-if="!$route.params.id">
                <b-button variant="outline-secondary" v-b-modal.create-custompop  pill class="blog-btn-block w-100">
                  <span class="">{{ getTittleCase(typeChannel) }}</span>
                </b-button>

              </div>
            </div>

            <div class="row" v-show="filterByType == 'event'">
              <div class="col-lg-9">
                <div class="position-relative search-posthead">
                  <b-input-group class="input-group-merge mb-1 round">
                    <!-- <b-input-group-prepend is-text>
                            <feather-icon
                              icon="SearchIcon"
                              v-b-tooltip.hover.v-default
                              title="Search"
                            />
                          </b-input-group-prepend> -->
                    <b-form-input v-b-toggle.add-event-sidebar class="d-inline-block" placeholder="Whats on your mind?"
                      />
                    <!-- <b-input-group-append is-text v-if="searchQueryEvent.length > 0">
                                <feather-icon icon="XIcon" class="text-default mr-1 cursor-pointer" v-b-tooltip.hover.v-default
                                  title="Clear Search" @click="
                                    searchQueryEvent = '';
                                  bus.$emit('loadEvents', {
                                    q: searchQueryEvent,
                                    type: typeFilter,
                                  });
                                  " />
                              </b-input-group-append>
                              <b-input-group-append is-text>
                                <b-button variant="primary" pill class="blog-btn-block mr-1 search-post"
                                  v-b-tooltip.hover.v-default title="Search Event" @click="
                                    bus.$emit('loadEvents', {
                                      q: searchQueryEvent,
                                      type: typeFilter,
                                    })
                                    ">
                                  <feather-icon icon="SearchIcon" width="18" height="18" />
                                </b-button>
                              </b-input-group-append> -->
                  </b-input-group>
                </div>
              </div>
              <!-- <div class="col-lg-4">
                          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="typeFilter"
                            :options="typeOptions" class="w-100" :reduce="(val) => val.value" placeholder="Select type.."
                            :clearable="false" />
                        </div> -->
              <div class="col-lg-3 pl-0" v-if="!$route.params.id">
                <b-button variant="outline-secondary" v-b-toggle.add-event-sidebar pill class="blog-btn-block w-100">
                  <span class="text-nowrap">Create Event</span>
                </b-button>
              </div>
            </div>
            <div class="row" v-show="filterByType == 'poll'">
              <div class="col-sm-9">
                <div class="search-posthead">
                  <b-input-group v-show="filterByType == 'poll'" class="input-group-merge mb-1 round">
                    <!-- <b-input-group-prepend is-text>
                            <feather-icon
                              icon="SearchIcon"
                              v-b-tooltip.hover.v-default
                              title="Search"
                            />
                          </b-input-group-prepend> -->
                    <b-form-input v-b-modal.add-poll-sidebar class="d-inline-block" placeholder="Whats on your mind?"
                      />
                    
                  </b-input-group>
                </div>
              </div>
              <div class="col pl-0" v-if="!$route.params.id">
                <b-button class="blog-btn-block w-100" variant="outline-secondary" v-b-modal.add-poll-sidebar pill>
                  <span class="text-nowrap">Create Poll</span>
                </b-button>
              </div>
            </div>
            <!-- Select list options -->
            <ul class="d-flex align-items-center justify-content-center bottom-options mb-0" @click="page2 = false;">
              <li v-b-modal.create-custompop @click="post_form_data.other_details.post_type = 'text-post'">
                <div class="d-flex align-items-center">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M2.5 5.00001C2.5 4.55798 2.67559 4.13406 2.98816 3.8215C3.30072 3.50894 3.72464 3.33334 4.16667 3.33334H15.8333C16.2754 3.33334 16.6993 3.50894 17.0118 3.8215C17.3244 4.13406 17.5 4.55798 17.5 5.00001V15C17.5 15.442 17.3244 15.866 17.0118 16.1785C16.6993 16.4911 16.2754 16.6667 15.8333 16.6667H4.16667C3.72464 16.6667 3.30072 16.4911 2.98816 16.1785C2.67559 15.866 2.5 15.442 2.5 15V5.00001Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.83203 6.66669H14.1654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M5.83203 10H14.1654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M5.83203 13.3333H14.1654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Text Post</span>
                </div>
              </li>
              <li v-b-modal.create-custompop @click="post_form_data.other_details.post_type ='photo-post'">
                <div class="d-flex align-items-center">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path d="M12.5 6.66669H12.5083" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path
                          d="M2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.5 13.3333L6.66667 9.16665C7.44 8.42248 8.39333 8.42248 9.16667 9.16665L13.3333 13.3333"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M11.668 11.6667L12.5013 10.8333C13.2746 10.0892 14.228 10.0892 15.0013 10.8333L17.5013 13.3333"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Photo</span>
                </div>
              </li>
              <li v-b-modal.create-custompop @click="post_form_data.other_details.post_type ='video-post'">
                <div class="d-flex align-items-center">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M3.33203 5.00001C3.33203 4.55798 3.50763 4.13406 3.82019 3.8215C4.13275 3.50894 4.55667 3.33334 4.9987 3.33334H14.9987C15.4407 3.33334 15.8646 3.50894 16.1772 3.8215C16.4898 4.13406 16.6654 4.55798 16.6654 5.00001V15C16.6654 15.442 16.4898 15.866 16.1772 16.1785C15.8646 16.4911 15.4407 16.6667 14.9987 16.6667H4.9987C4.55667 16.6667 4.13275 16.4911 3.82019 16.1785C3.50763 15.866 3.33203 15.442 3.33203 15V5.00001Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.66797 3.33334V16.6667" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M13.332 3.33334V16.6667" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 6.66669H6.66536" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 13.3333H6.66536" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 10H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M13.332 6.66669H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M13.332 13.3333H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Video</span>
                </div>
              </li>
              <li v-b-modal.create-custompop @click="post_form_data.other_details.post_type ='quote-post'">
                <div class="d-flex align-items-center">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M11.6654 9.16667H14.9987C15.2197 9.16667 15.4317 9.07887 15.588 8.92259C15.7442 8.76631 15.832 8.55435 15.832 8.33333V5.83333C15.832 5.61232 15.7442 5.40036 15.588 5.24408C15.4317 5.0878 15.2197 5 14.9987 5H12.4987C12.2777 5 12.0657 5.0878 11.9094 5.24408C11.7532 5.40036 11.6654 5.61232 11.6654 5.83333V10.8333C11.6654 13.0558 12.7762 14.4442 14.9987 15"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <rect x="4" y="5" width="4" height="4" fill="#6E6B7B" />
                        <rect x="12" y="5" width="4" height="4" fill="#6E6B7B" />
                        <path
                          d="M4.16536 9.16667H7.4987C7.71971 9.16667 7.93167 9.07887 8.08795 8.92259C8.24423 8.76631 8.33203 8.55435 8.33203 8.33333V5.83333C8.33203 5.61232 8.24423 5.40036 8.08795 5.24408C7.93167 5.0878 7.71971 5 7.4987 5H4.9987C4.77768 5 4.56572 5.0878 4.40944 5.24408C4.25316 5.40036 4.16536 5.61232 4.16536 5.83333V10.8333C4.16536 13.0558 5.2762 14.4442 7.4987 15"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Quote</span>
                </div>
              </li>
              <li v-b-toggle.add-event-sidebar>
                <div class="d-flex align-items-center">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M3.33203 5.83332C3.33203 5.3913 3.50763 4.96737 3.82019 4.65481C4.13275 4.34225 4.55667 4.16666 4.9987 4.16666H14.9987C15.4407 4.16666 15.8646 4.34225 16.1772 4.65481C16.4898 4.96737 16.6654 5.3913 16.6654 5.83332V15.8333C16.6654 16.2754 16.4898 16.6993 16.1772 17.0118C15.8646 17.3244 15.4407 17.5 14.9987 17.5H4.9987C4.55667 17.5 4.13275 17.3244 3.82019 17.0118C3.50763 16.6993 3.33203 16.2754 3.33203 15.8333V5.83332Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.332 2.5V5.83333" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M6.66797 2.5V5.83333" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M10 2.5V5.83333" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 9.16669H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M6.66797 12.5H8.33464V14.1667H6.66797V12.5Z" stroke="#6E6B7B" stroke-width="1.25"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Event</span>
                </div>
              </li>
              <li v-b-modal.add-poll-sidebar>
                <div class="d-flex align-items-center">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M3.00228 5.99751L8.95061 3.40668C9.04877 3.36461 9.15435 3.34261 9.26114 3.34199C9.36793 3.34137 9.47376 3.36214 9.57239 3.40307C9.67103 3.444 9.76047 3.50426 9.83545 3.58031C9.91042 3.65635 9.96942 3.74664 10.0089 3.84584L14.1123 13.7642C14.1976 13.9653 14.2005 14.1919 14.1204 14.3951C14.0402 14.5984 13.8835 14.762 13.6839 14.8508L7.73645 17.4417C7.63825 17.4839 7.53259 17.506 7.42571 17.5067C7.31883 17.5073 7.21289 17.4866 7.11416 17.4457C7.01543 17.4047 6.9259 17.3444 6.85086 17.2683C6.77583 17.1922 6.7168 17.1018 6.67728 17.0025L2.57395 7.08334C2.48866 6.88222 2.48576 6.65565 2.56587 6.45241C2.64598 6.24917 2.8027 6.08634 3.00228 5.99751Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M12.5 3.33334H13.3333C13.5543 3.33334 13.7663 3.42114 13.9226 3.57742C14.0789 3.7337 14.1667 3.94566 14.1667 4.16668V7.08334"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M16.6654 5C16.8854 5.09333 17.0987 5.18083 17.3054 5.2625C17.5088 5.34882 17.6696 5.51241 17.7524 5.71729C17.8353 5.92217 17.8333 6.15155 17.747 6.355L15.832 10.8333"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>
                    Poll
                  </span>
                </div>
              </li>
            </ul>
            <!-- Custom Create Popup -->
            <b-modal id="create-custompop" size="lg" title="custom-community" hide-footer
              :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" header-class="sticky-top">
              <template #modal-header="{ close }">
                <!-- Option Select -->
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="post_form_data.other_details.post_type"
                  :options="postTypeOptions" class="w-auto create-options" :reduce="(val) => val.value"
                  placeholder="Select type.." :clearable="false" @input="handleTypeChange" 
                  />

                <div class="next-expand d-flex align-items-center">
                  <span v-if="!page2">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" v-b-modal.scheduled-postview class="schedule-icon">
                      <g clip-path="url(#clip0_8620_29108)">
                        <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 7V12L15 15" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_8620_29108">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg> -->
                    </span>
                  <b-button id="next-btn" pill variant="outline-primary" class="px-3 py-75" v-if="!page2"
                    @click="page2 = true;">
                    Preview
                  </b-button>
                  <b-button id="back-edit" pill variant="outline-primary" class="px-3 py-75" v-if="page2"
                    @click="page2 = false;">
                    <span class="d-md-flex d-none">Back To Editing</span>
                    <span class="d-md-none">Back</span>
                  </b-button>
                  <b-link class="fullscreen-icon"
                    @click="fullScreen = !fullScreen">
                    <!-- <feather-icon
              :icon="!fullScreen ? '<img :src="appMaxpopImg" />' : '<b-img :src="appMinpopImg" />'"
              size="18"
              class=""
            /> -->
                    <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 26px; height: 26px;" v-b-tooltip.hover.v-default
                            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')" width="24px" height="24px" />
                            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 26px; height: 26px;" v-b-tooltip.hover.v-default
                            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  width="24px" height="24px"/>
                    <!-- <feather-icon :icon="!fullScreen ? 'Maximize2Icon' : 'Minimize2Icon'" size="18" class="" /> -->
                  </b-link>
                </div>
                <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="confirmCloseButton" />
              </template>
              <!-- Text Post -->
              <b-form autocomplete="off" id="post-form">
                <b-row v-if="!page2 && post_form_data.other_details.post_type == 'text-post' " class="schedule-post">
                  <b-col md="12">
                    <b-form-group class="position-relative custom-title">
                      <div class="d-flex align-items-center justify-content-between edit-schedule">
                        <label for="title">Title</label>
                        <div class="d-flex align-items-center">
                          <p v-if="isSpinning" class="text-secondary cursor-pointer mr-1 mb-50" >
                            Saving...
                          </p>
                          <feather-icon v-if="post_form_data.hashid" v-b-tooltip.hover.top="'Auto Save'"  @click="saveDraft()" size="20" :class="{'fa-spinner': isSpinning, 'fa-spin': isSpinning}"  class=" text-secondary cursor-pointer mr-1 mb-50 fas fa-sync" icon="RefreshCcwIcon"
                          />                           
                        <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none"  v-b-tooltip.hover.v-default
                        title="Schedule" v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop>
                          <g clip-path="url(#clip0_9674_28132)">
                            <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_9674_28132">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                        post_form_data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin'))
                        "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                          </div>
                      </div>
                      <b-form-input id="title" v-model="post_form_data.title" name="title" placeholder="Start Typing To Add Title..." />
                      <div id="`toolbar-title`" class="ql-snow ql-toolbar emoji-icon border-0 p-0">
                        <span class="ql-formats">
                          <button @click="toogleDialogEmoji($event)">
                            <b-img style="width: 24px" :src="require('@/assets/images/pages/smile-icon.svg')"
                              alt="logo" />
                          </button>
                        </span>
                      </div>
                      <VEmojiPicker :pack="emojisNative" labelSearch="Search" @select="onSelectEmoji" class="emoji-picker"
                        :hidden="dialogHidden" />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="Description"> Description</label>
                      <div class="post-content">
                      
                        <QuillInput :toolbar="toolbarState" className="post-editor custom-textpost"
                          :value.sync="post_form_data.body" placeholder="Start Typing To Add Body..."
                          :mentionList="employeeListOptions" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Attachments" label-for="files">
                      <file-upload ref="upload" v-model="myFiles" post-action="/post.method" put-action="/put.method"
                        :multiple="true" class="attach-upload">
                        <template>
                          <!-- <div class="flex align-items-center justify-content-center upload-files">
                                            <p class="my-4 mb-0">Drag & Drop you file or <b><u>Browse</u></b></p>
                                        </div> -->
                          <b-avatar square style="background-color: transparent" v-b-tooltip.hover.v-default
                            title="Upload Files" :src="require('@/assets/images/icons/add-attachment.png')
                              " />
                        </template>
                      </file-upload>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <div class="attched-file">
                      <ul class="p-0">
                        <li v-for="file, key in myFiles" :value="key" @click="openFile(file)">
                          <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                              fill="none">
                              <g>
                                <path
                                  d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                  stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath>
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>{{ file.file.name }}</span>
                            <span class="close d-flex align-items-center justify-content-center">
                              <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                @click.stop="deleteFile(file, key)" />
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </b-col>

                  <b-col md="12" v-if="
                  post_form_data.attachments && post_form_data.attachments.length > 0
                  ">
                    <div
                      class="border-top pt-1"
                    >
                      <b class="text-secondary">Uploaded Attachments</b>

                      <div class="d-flex flex-column">
                        <div
                          v-for="(file, index) in post_form_data.attachments"
                          :key="index"
                          class="d-flex align-items-center justify-content-between my-1"
                        >
                          <img
                            v-if="file.type.includes('image')"
                            :src="file.link"
                            class="img-fluid"
                            width="100px"
                            height="100px"
                            alt="Attachment"
                          />
                          <span
                            v-else
                            class="text-primary"
                            v-b-tooltip.hover.v-default
                            :title="file.name"
                          >
                            {{ file.name | str_limit(40) }}
                          </span>
                          <div>
                            <feather-icon
                              v-b-tooltip.hover.top="'View Attachment'"
                              size="20"
                              class="btn-link cursor-pointer ml-1"
                              icon="EyeIcon"
                              @click.stop.prevent="openWindow(file.link)"
                            />

                            <feather-icon
                              v-b-tooltip.hover.top="'Remove Attachment'"
                              size="20"
                              class="btn-link cursor-pointer ml-1 text-danger"
                              icon="TrashIcon"
                              @click.stop.prevent="confirmDeleteFile(file.uuid)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <!-- <b-col md="6">
                    <b-row>
                      <b-col md="12">
                        <b-form-group class="mb-1">
                          <b-form-checkbox v-model="post_form_data.is_scheduled" :checked="post_form_data.is_scheduled"
                            switch>
                            Schedule Post
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                          <b-form-radio-group id="btn-radios-3" v-model="post_form_data.scheduled_time"
                            :options="schedule_options" :aria-describedby="ariaDescribedby" name="radio-btn-stacked"
                            buttons button-variant="outline" class="w-100" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="left-time mt-3">
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <validation-provider #default="{ errors }" name="Timezone" rules="required">
                          <b-form-group class="mb-1">
                            <label>Timezone
                              <button v-show="!post_form_data.scheduled_timezone.includes(
                                getUserTimezone()
                              )
                                " type="button" class="btn btn-sm btn-success" @click="
    post_form_data.scheduled_timezone = [
      ...post_form_data.scheduled_timezone,
      getUserTimezone(),
    ]
    ">
                                My Timezone({{ getUserTimezone() }})
                              </button>
                            </label>
                            <v-select v-model="post_form_data.scheduled_timezone"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="timezones" :clearable="false"
                              label="name" :reduce="(option) => option.name" :filter-by="myTimezoneFilter"
                              :searchable="true" input-id="timezone" :state="errors.length > 0 ? false : null"
                              placeholder="Select Timezone..">
                              <template slot="option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>

                              <template slot="selected-option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group class="mb-0">
                          <label class="1required-l" for="scheduled_time">Pick date & time</label>
                          <flat-pickr id="scheduled_time" v-model="post_form_data.scheduled_time" class="form-control"
                            :config="{
                              minDate: 'today',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              altFormat: 'M j, h:iK',
                              altInput: true,
                            }" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col> -->
                </b-row>

                <!-- End Text Post -->
                <!-- Video Post -->
                <b-row v-if="!page2 && post_form_data.other_details.post_type == 'video-post'" class="schedule-post">
                  <b-col md="12" v-if="post_form_data.attachments && post_form_data.attachments.length">
                    <b-embed type="iframe" aspect="16by9" :src="post_form_data.attachments[0] && post_form_data.attachments[0].link" allowfullscreen />
                  </b-col>
                  <b-col md="12">
                    <!-- <file-pond ref="video-pond" name="files[]" max-file-size="50MB" allow-paste="false" max-files="10"
                                allow-multiple="true" v-bind:files="myFiles" image-preview-height="120px"
                                accepted-file-types="video/*" label-idle="Or drop a file, Or paste video URL."
                                file-validate-type-label-expected-types="Expects a PDF, Images, Video file" /> -->
                    <div class="upload-files files-custom">
                      <div class="upload-file">
                        <file-upload ref="upload" accept="video/*"  :drop="true" v-model="myFiles" @input="getSrc('video')" post-action="/post.method" put-action="/put.method"
                          :multiple="false">
                          <template>
                            <div class="flex align-items-center flex-column justify-content-center">
                              <b-button class="upload-btn mb-0" variant="outline-primary" pill>Upload Video</b-button>
                              <p class="mb-0 pt-1" variant="outline-primary" pill><i class="fa-solid fa-upload"></i> Drag & Drop files to upload</p>
                              <!-- <h5>Or drop a file,</h5> -->
                            </div>
                          </template>
                        </file-upload>
                      </div>

                      <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                        {{fileError}}
                      </b-badge>

                      <!-- <div class="drap-past mt-2">
                        <file-upload ref="upload" v-model="myFiles" @input="getSrc" post-action="/post.method" put-action="/put.method"
                          :multiple="true">
                          <template>
                            <div class="d-flex flex-column">
                              <h5>Or drop a file,</h5>
                              <span>Or paste Video URL.</span>
                            </div>
                          </template>
                        </file-upload>
                      </div> -->
                      <!-- <div class="post-url my-1">
                        <b-form-group>
                          <b-form-input placeholder="Start Pasting Url..." v-model="remote_file" />
                          <b-button class="upload-btn mb-0" variant="outline-secondary"
                            @click="checkAccess(remote_file, 'video')">Check Access</b-button>
                        </b-form-group>
                      </div> -->
                    </div>

                  <div class="attched-file mt-1" v-if="myFiles && myFiles[0]">
                    <ul class="p-0">
                      <li>
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g>
                              <path
                                d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath>
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>{{ myFiles[0].file.name }}</span>
                          <span class="close d-flex align-items-center justify-content-center">
                            <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                              @click="deleteFile(myFiles[0].file, 0)" />
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  </b-col>
                  <b-col md="12">
                    <b-form-group class="mt-1">
                      <div class="d-flex align-items-center justify-content-between">
                        <label for="Description"> Description </label>
                        <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-default
                        title="Schedule" v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop>
                          <g clip-path="url(#clip0_9674_28132)">
                            <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_9674_28132">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                        post_form_data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin'))
                        "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                      </div>
                      <div class="post-content">
                        <QuillInput :toolbar="toolbarState" className="post-editor"
                          :value.sync="post_form_data.body" placeholder="Start Typing To Add Description..."
                          :mentionList="employeeListOptions" />
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- <b-col md="6">
                    <b-row>
                      <b-col md="12">
                        <b-form-group class="mb-1">
                          <b-form-checkbox v-model="post_form_data.is_scheduled" :checked="post_form_data.is_scheduled"
                            switch>
                            Schedule Post
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                          <b-form-radio-group id="btn-radios-3" v-model="post_form_data.scheduled_time"
                            :options="schedule_options" :aria-describedby="ariaDescribedby" name="radio-btn-stacked"
                            buttons button-variant="outline" class="w-100" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="left-time mt-3">
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <validation-provider #default="{ errors }" name="Timezone" rules="required">
                          <b-form-group class="mb-1">
                            <label>Timezone
                              <button v-show="!post_form_data.scheduled_timezone.includes(
                                getUserTimezone()
                              )
                                " type="button" class="btn btn-sm btn-success" @click="
    post_form_data.scheduled_timezone = [
      ...post_form_data.scheduled_timezone,
      getUserTimezone(),
    ]
    ">
                                My Timezone({{ getUserTimezone() }})
                              </button>
                            </label>
                            <v-select v-model="post_form_data.scheduled_timezone"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="timezones" :clearable="false"
                              label="name" :reduce="(option) => option.name" :filter-by="myTimezoneFilter"
                              :searchable="true" input-id="timezone" :state="errors.length > 0 ? false : null"
                              placeholder="Select Timezone..">
                              <template slot="option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>

                              <template slot="selected-option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group class="mb-0">
                          <label class="1required-l" for="scheduled_time">Pick date & time</label>
                          <flat-pickr id="scheduled_time" v-model="post_form_data.scheduled_time" class="form-control"
                            :config="{
                              minDate: 'today',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              altFormat: 'M j, h:iK',
                              altInput: true,
                            }" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col> -->
                </b-row>
                <!-- End Video Post -->
                <!-- Photo Post -->
                <b-row v-if="!page2 && post_form_data.other_details.post_type == 'photo-post'" class="schedule-post">
                  <b-col md="12" v-if="post_form_data.attachments && post_form_data.attachments.length">
                    <img
                      :src="post_form_data.attachments[0].link"
                      class="img-fluid"
                      alt="Attachment"
                    />
                  </b-col>
                  <b-col md="12">
                    <!-- <file-pond ref="photo-pond" name="files[]" max-file-size="50MB" allow-paste="false" max-files="10"
                                allow-multiple="true" v-bind:files="myFiles" image-preview-height="120px"
                                accepted-file-types="video/*" label-idle="Or drop a file, Or paste photo URL."
                                file-validate-type-label-expected-types="Expects a PDF, Images, Video file" /> -->
                    <div class="upload-files files-custom">
                      <div class="upload-file">
                        <file-upload ref="upload" accept="image/*" :drop="true" v-model="myFiles" @input="getSrc('image')" post-action="/post.method" put-action="/put.method">
                          <template>
                            <div class="flex align-items-center flex-column justify-content-center">
                              <b-button class="upload-btn mb-0" variant="outline-primary" pill>Upload Photo</b-button>
                              <p class="mb-0 pt-1" variant="outline-primary" pill><i class="fa-solid fa-upload"></i> Drag & Drop files to upload</p>
                              <!-- <h5>Or drop a file,</h5> -->
                            </div>
                          </template>
                        </file-upload>
                      </div>

                      <b-badge class="mt-1 mb-1 badge-glow" variant="danger" v-if="fileError">
                        {{fileError}}
                      </b-badge>
                      <!-- <div class="drap-past mt-2">
                        <file-upload ref="upload" v-model="myFiles" @input="getSrc" post-action="/post.method" put-action="/put.method">
                          <template>
                            <div class="d-flex flex-column">
                              <h5>Or drop a file,</h5>
                              <span>Or paste Photo URL.</span>
                            </div>
                          </template>
                        </file-upload>
                      </div> -->
                      <!-- <div class="post-url my-1">
                        <b-form-group>
                          <b-form-input placeholder="Start Pasting Url..." v-model="remote_file" />
                          <b-button class="upload-btn mb-0" variant="outline-secondary"
                            @click="checkAccess(remote_file, 'photo')">Check Access</b-button>
                        </b-form-group>
                      </div> -->
                    </div>

                  <div class="attched-file mt-1" v-if="myFiles && myFiles[0]">
                    <ul class="p-0">
                      <li>
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g>
                              <path
                                d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath>
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>{{ myFiles[0].file.name }}</span>
                          <span class="close d-flex align-items-center justify-content-center">
                            <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                              @click="deleteFile(myFiles[0].file, 0)" />
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  </b-col>
                  <b-col md="12">
                    <b-form-group class="mt-1">
                      <div class="d-flex align-items-center justify-content-between">
                        <label for="Description"> Description </label>
                        <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-default
                        title="Schedule" v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop>
                          <g clip-path="url(#clip0_9674_28132)">
                            <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_9674_28132">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                        post_form_data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin'))
                        "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                      </div>
                      <div class="post-content">
                        <QuillInput :toolbar="toolbarState" className="post-editor"
                          :value.sync="post_form_data.body" placeholder="Start Typing To Add Description..."
                          :mentionList="employeeListOptions" />
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- <b-col md="6">
                    <b-row>
                      <b-col md="12">
                        <b-form-group class="mb-1">
                          <b-form-checkbox v-model="post_form_data.is_scheduled" :checked="post_form_data.is_scheduled"
                            switch>
                            Schedule Post
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                          <b-form-radio-group id="btn-radios-3" v-model="post_form_data.scheduled_time"
                            :options="schedule_options" :aria-describedby="ariaDescribedby" name="radio-btn-stacked"
                            buttons button-variant="outline" class="w-100" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="left-time mt-3">
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <validation-provider #default="{ errors }" name="Timezone" rules="required">
                          <b-form-group class="mb-1">
                            <label>Timezone
                              <button v-show="!post_form_data.scheduled_timezone.includes(
                                getUserTimezone()
                              )
                                " type="button" class="btn btn-sm btn-success" @click="
    post_form_data.scheduled_timezone = [
      ...post_form_data.scheduled_timezone,
      getUserTimezone(),
    ]
    ">
                                My Timezone({{ getUserTimezone() }})
                              </button>
                            </label>
                            <v-select v-model="post_form_data.scheduled_timezone"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="timezones" :clearable="false"
                              label="name" :reduce="(option) => option.name" :filter-by="myTimezoneFilter"
                              :searchable="true" input-id="timezone" :state="errors.length > 0 ? false : null"
                              placeholder="Select Timezone..">
                              <template slot="option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>

                              <template slot="selected-option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group class="mb-0">
                          <label class="1required-l" for="scheduled_time">Pick date & time</label>
                          <flat-pickr id="scheduled_time" v-model="post_form_data.scheduled_time" class="form-control"
                            :config="{
                              minDate: 'today',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              altFormat: 'M j, h:iK',
                              altInput: true,
                            }" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col> -->
                </b-row>
                <!-- End Photp Post -->
                <!-- Quote Post -->
                <b-row v-if="!page2 && post_form_data.other_details.post_type == 'quote-post'" class="schedule-post">
                  <b-col md="12">
                    <b-form-group class="post-content">
                      <div class="d-flex align-items-center justify-content-between">
                        <label for="description">Description</label>
                        <svg xmlns="http://www.w3.org/2000/svg" class="mb-50 timer-svg" width="22" height="22" viewBox="0 0 24 24" fill="none"v-b-tooltip.hover.v-default
                        title="Schedule" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.is_scheduled != true  " v-b-modal.schedule-timepop>
                          <g clip-path="url(#clip0_9674_28132)">
                            <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_9674_28132">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <feather-icon v-b-tooltip.hover.top="'Delete Post'"  @click="confirmDeleteRecord(post_form_data)" v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.hashid && (($can('post_delete') &&
                        post_form_data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin'))
                        "  size="20" class="btn-link cursor-pointer ml-50 mb-50 text-danger" icon="TrashIcon"/>
                      </div>
                      <b-form-textarea v-model="post_form_data.body" placeholder="Start Typing To Add Quote..." rows="15">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="description">About Author</label>
                      <b-form-input v-model="post_form_data.other_details.author" id="title" name="title"
                        placeholder="Start Typing To Add Author..." />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="6">
                    <b-row>
                      <b-col md="12">
                        <b-form-group class="mb-1">
                          <b-form-checkbox v-model="post_form_data.is_scheduled" :checked="post_form_data.is_scheduled"
                            switch>
                            Schedule Post
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                          <b-form-radio-group id="btn-radios-3" v-model="post_form_data.scheduled_time"
                            :options="schedule_options" :aria-describedby="ariaDescribedby" name="radio-btn-stacked"
                            buttons button-variant="outline" class="w-100" stacked></b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row class="left-time mt-3">
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <validation-provider #default="{ errors }" name="Timezone" rules="required">
                          <b-form-group class="mb-1">
                            <label>Timezone
                              <button v-show="!post_form_data.scheduled_timezone.includes(
                                getUserTimezone()
                              )
                                " type="button" class="btn btn-sm btn-success" @click="
    post_form_data.scheduled_timezone = [
      ...post_form_data.scheduled_timezone,
      getUserTimezone(),
    ]
    ">
                                My Timezone({{ getUserTimezone() }})
                              </button>
                            </label>
                            <v-select v-model="post_form_data.scheduled_timezone"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="timezones" :clearable="false"
                              label="name" :reduce="(option) => option.name" :filter-by="myTimezoneFilter"
                              :searchable="true" input-id="timezone" :state="errors.length > 0 ? false : null"
                              placeholder="Select Timezone..">
                              <template slot="option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
  
                              <template slot="selected-option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="post_form_data.is_scheduled == true" md="12">
                        <b-form-group class="mb-0">
                          <label class="1required-l" for="scheduled_time">Pick date & time</label>
                          <flat-pickr id="scheduled_time" v-model="post_form_data.scheduled_time" class="form-control"
                            :config="{
                              minDate: 'today',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              altFormat: 'M j, h:iK',
                              altInput: true,
                            }" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col> -->
                </b-row>
                <b-row  v-if="!page2" class="bottom-post mt-2">
                  <b-col md="4" sm="12" class="select-channel">
                    <v-select v-model="typeChannel" :reduce="(val) => val.value" :options="communityChannels"
                      class="w-auto create-options" placeholder="Select A Channel" :clearable="false" />
                  </b-col>
                  <b-col md="8" sm="12" class="select-post d-flex align-items-center justify-content-end">
                    <!-- Post now button -->
                    <div class="d-flex align-items-center">
                      <b-button variant="outline-secondary" pill v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" class="mr-1 text-default">Post Now</b-button>
                      <div class="demo-inline-spacing justify-content-start">
                        <b-button-group class="mr-0">
                          <b-dropdown
                            v-if="!post_form_data.hashid || post_form_data.is_draft == 'true'" 
                            variant="outline-primary"
                            :text="post_form_data.is_scheduled ? 'Schedule Post' : 'Publish'"
                            @click="formSubmitted()"
                            split
                            :disabled="postSubmittedClicked"
                          >
                            <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Publish Now</b-dropdown-item>
                            <b-dropdown-item v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop
                              >Schedule</b-dropdown-item
                            >
                            <b-dropdown-item @click="formSubmitted(true)" 
                            :disabled="postSubmittedClicked"
                              >Save as Draft</b-dropdown-item
                            >
                          </b-dropdown>
                          <b-dropdown
                            v-if="post_form_data.hashid && post_form_data.is_draft == 'false'"
                            variant="outline-primary"
                            :text="post_form_data.is_scheduled && post_form_data.is_published == 'false' ? 'Update Schedule Post' : 'Update'"
                            @click="formUpdate()"
                            split
                            :disabled="postSubmittedClicked"
                          >
                            
                            <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Post Now</b-dropdown-item>
                            <b-dropdown-item v-if="!post_form_data.is_published || post_form_data.is_published == 'false'" v-b-modal.schedule-timepop
                              >Schedule</b-dropdown-item
                            >
                            <b-dropdown-item @click="formUpdate(true)" 
                            :disabled="postSubmittedClicked"
                              >Save as Draft</b-dropdown-item
                            >
                          </b-dropdown>
                        </b-button-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
              <!-- End Quote Post -->

              <!-- Back To Editing  -->
              <div class="back-editform community-preview" v-if="page2">
                <b-card no-body class="my-1">
                  <b-card-body class="px-0">
                    <div class="d-flex justify-content-between align-items-center post-header">
                      <div class="d-flex align-items-center">
                        <!-- avatar -->
                        <b-avatar :src="userData.avatar" target="_blank" :href="`/profile/${userData.hashid}`" style="background: transparent" size="40" class="mr-75" />
                        <!--/ avatar -->
                        <div class="profile-user-info">
                          <h6 class="mb-0">
                            {{ userData.name }}
                          </h6>
                          <small style="font-size: 12px">
                            {{ userData.designation }}
                          </small>
                        </div>
                      </div>
                      <!-- <div class="d-flex align-items-center">
                        
                        <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                          no-caret>
                          <template v-slot:button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16"
                              class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                              title="More Options" />
                          </template>
                          <b-dropdown-item class="btn-sm p-0 text-muted">
                            <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                            <span class="">Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item class="btn-sm p-0 text-muted">
                            <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                            <span class="">Copy</span>
                          </b-dropdown-item>
                          <b-dropdown-item class="btn-sm p-0">
                            <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                            <span class="">Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div> -->
                    </div>

                    <!-- Text Post -->
        <div class="post-body" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'text-post'">
          <div  class="" style="position: relative">
            <div>
              <b-card-text >
                <h3 class="card-header pb-0 mt-lg-0 px-0">{{ post_form_data.title }}</h3>
              </b-card-text>
              <b-card-text v-html="post_form_data.body"></b-card-text>
            </div>
          </div>
        </div>
        <!-- End Text Post -->

        <!-- Photo Post -->
        <div class="post-body" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'photo-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" v-if="myFiles && myFiles[0] && previewUrl">
                <b-img fluid rounded :src="previewUrl" class="post-img" />
              </div>
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" v-if="!myFiles[0] && post_form_data.attachments && post_form_data.attachments[0]">
                <b-img fluid rounded :src="post_form_data.attachments[0].link" class="post-img" />
              </div>
              <b-card-text v-html="post_form_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Photo Post -->
        <!-- Video Post -->
        <div class="post-body" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'video-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" v-if="myFiles && myFiles[0] && previewUrl" >
                <b-embed type="iframe" aspect="16by9" :src="previewUrl" allowfullscreen/>
              </div>
              <div class="mt-1 mb-1 d-flex" v-if="!myFiles[0] && post_form_data.attachments && post_form_data.attachments[0]">
                <b-embed type="iframe" aspect="16by9" :src="post_form_data.attachments[0].link" allowfullscreen/>
              </div>
              <b-card-text v-html="post_form_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Video Post -->

        <!-- Quote Post -->
        <div class="post-body mt-2" v-if="post_form_data.other_details && post_form_data.other_details.post_type == 'quote-post'">
          <div  class="quote-post" style="position: relative ">
            <div>
              <b-card-text>
                "{{ post_form_data.body }}"
              </b-card-text>
              <p class="author-name my-1 pt-1" v-if="post_form_data.other_details.author"><strong>Author Name : {{post_form_data.other_details.author}}</strong></span></p>
            </div>
          </div>
        </div>
        <!-- End Quote Post -->
                  </b-card-body>
                </b-card>
                <div class="attched-file" v-if="post_form_data.other_details.post_type == 'text-post'">
                  <ul class="p-0">
                    <li v-for="file, key in myFiles" :value="key" @click="openFile(file)">
                      <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g>
                            <path
                              d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                              stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>{{ file.file.name }}</span>
                        <span class="close d-flex align-items-center justify-content-center">
                          <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                            @click.stop="deleteFile(file.file, key)" />
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                
                <b-row class="bottom-post">
                  <b-col md="4" sm="12" class="select-channel">
                    <v-select v-model="typeChannel" :reduce="(val) => val.value" :options="communityChannels"
                      class="w-auto create-options" placeholder="Select A Channel" :clearable="false" />
                  </b-col>
                  <b-col md="8" sm="12" class="select-post d-flex align-items-center justify-content-end">
                    <div class="demo-inline-spacing justify-content-start">
                      <b-button-group class="mr-0">
                        <b-dropdown
                          v-if="!post_form_data.hashid || post_form_data.is_draft == 'true'" 
                          variant="outline-primary"
                          :text="post_form_data.is_scheduled ? 'Schedule Post' : 'Publish'"
                          @click="formSubmitted()"
                          split
                          :disabled="postSubmittedClicked"
                        >
                          <b-dropdown-item @click="formSubmitted()" :disabled="postSubmittedClicked">{{
                          post_form_data.is_scheduled == true ? 'Schedule Post' : 'Publish' }}</b-dropdown-item>
                          <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Publish Now</b-dropdown-item>
                          <b-dropdown-item v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.is_scheduled != true  " v-b-modal.schedule-timepop
                            >Schedule</b-dropdown-item
                          >
                          <b-dropdown-item @click="formSubmitted(true)" 
                          :disabled="postSubmittedClicked"
                            >Save as Draft</b-dropdown-item
                          >
                        </b-dropdown>
                        <b-dropdown
                          v-if="post_form_data.hashid && post_form_data.is_draft == 'false'"
                          variant="outline-primary"
                          :text="post_form_data.is_scheduled && post_form_data.is_published == 'false' ? 'Update Schedule Post' : 'Update'"
                          @click="formUpdate()"
                          split
                          :disabled="postSubmittedClicked"
                        >
                          <b-dropdown-item @click="formUpdate()" :disabled="postSubmittedClicked">{{
                          post_form_data.is_scheduled == true  && post_form_data.is_published == 'false' ? 'Update Schedule Post' : 'Update' }}</b-dropdown-item>
                          <b-dropdown-item v-if="post_form_data.is_scheduled == true  && post_form_data.is_published == 'false'" @click="postNow()" :disabled="postSubmittedClicked">Publish Now</b-dropdown-item>
                          <b-dropdown-item v-if="(!post_form_data.is_published || post_form_data.is_published == 'false') && post_form_data.is_scheduled != true  " v-b-modal.schedule-timepop
                            >Schedule</b-dropdown-item
                          >
                          <b-dropdown-item @click="formUpdate(true)" 
                          :disabled="postSubmittedClicked"
                            >Save as Draft</b-dropdown-item
                          >
                        </b-dropdown>
                      </b-button-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <!-- End Back To Editing -->
            </b-modal>
          </div>
        </div>
        <div class="">
          <div class="row justify-content-end event-select" v-show="filterByType == 'event'">
            <div class="col-lg-3">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="typeFilter" :options="typeOptions"
                class="w-100" :reduce="(val) => val.value" placeholder="Select type.." :clearable="false" />
            </div>
          </div>
          <div v-show="filterByType != 'event' && filterByType != 'poll'" ref="div-scroll" v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="isBusy" infinite-scroll-distance="10">
            <!-- Start Pinned Post -->
            <b-card no-body class="post-card pinned-post" v-if="pinnedPosts.length && pinnedPosts[0].length > 1">
              <b-card-body>
                <b-carousel id="post_pin" :interval="4000" :controls="pinnedPosts.length > 1">
                  <b-carousel-slide v-for="(chunk, ind) in pinnedPosts">
                    <template #img>
                      <b-card no-body v-for="(data, index) in chunk" :key="'post-' + index"
                        :id="'post-card-' + data.hashid" :class="data.user.hashid == userData.hashid ? 'post-card mainuser' : 'post-card'">
                        <!-- @click="viewPost(data)" -->
                        <b-card-body :id="data.hashid" @click="viewPost(data)" v-observe-visibility="{
                          callback: (isVisible, entry) =>
                            visibilityChanged(isVisible, entry, data),
                          once: true,
                          throttle: 0,
                          throttleOptions: {
                            leading: 'visible',
                          },
                          intersection: {
                            threshold: 0,
                          },
                        }">
                          <div class="d-flex justify-content-between align-items-center mb-2 post-header">
                            <div class="d-flex align-items-start">
                              <!-- avatar -->
                              <b-avatar v-if="data.user && data.user.hashid" :src="data.user.avatar" target="_blank" :href="`/profile/${data.user.hashid}`" style="background: transparent" size="40" class="mr-75" />
                              <b-avatar v-else :src="data.user.avatar" style="background: transparent" size="40" class="mr-75" />
                              <!--/ avatar -->
                              <div class="profile-user-info">
                                <h6 class="mb-0">
                                  {{ data.user.name }}
                                </h6>
                                <small style="font-size: 12px">
                                  {{ data.user.designation }}
                                </small>
                                <p class="d-block d-md-none"  v-if="data.is_published == 'true' ">
                                  {{ data.created_at | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="d-flex align-items-center">
                              <b-badge class="d-none d-md-flex schedule-badge" v-if="data.is_scheduled == true &&
                                data.is_published == 'false'" variant="light-info">Scheduled : {{ data.created_at}}</b-badge>
                              <b-badge class="m-50" v-if="data.is_approved == 'false' &&
                                data.is_published == 'true'
                                " pill variant="light-danger">Need Approval</b-badge>

                              <b-badge v-if="data.is_draft == 'true'" pill class="ml-50"
                                variant="light-secondary">Draft</b-badge>
                              <b-img v-if="data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin-24px.png')"
                                size="24" class="m-1 pin-icon" style="background-color: transparent"
                                v-b-tooltip.hover.v-default title="Post is pinned by admin" />
                              <p class="d-none d-md-block" v-if="data.is_published == 'true' ">
                                {{ data.created_at | formatDate }}
                              </p>
                              <b-dropdown class="blog-dropdown" variant="link" size="sm"
                                toggle-class="text-decoration-none" no-caret v-if="data.is_published == 'true'">
                                <template v-slot:button-content>
                                  <feather-icon icon="MoreVerticalIcon" size="16"
                                    class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                                    title="More Options" />
                                </template>

                                <b-dropdown-item class="btn-sm p-0 text-muted"
                                  v-if="$can('Zircly Admin') && data.is_pinned == 'false'" @click="pinPost(data)">
                                  <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                                    style="background-color: transparent" />
                                  <span class="">Pin Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted"
                                  v-if="$can('Zircly Admin') && data.is_pinned == 'true'" @click="unpinPost(data)">
                                  <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                                    style="background-color: transparent" />
                                  <span class="">Un-Pin Post</span>
                                </b-dropdown-item>
                                
                                <b-dropdown-item class="btn-sm p-0 text-muted" v-b-modal.create-custompop
                                  @click="post_form_data = data;" v-if="($can('post_edit') &&
                                    data.user.hashid == userData.hashid) ||
                                    $can('Zircly Admin')
                                    ">
                                  <!-- @click="editRecord(data)" -->
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                                  data.is_approved == 'false' && userData.need_approval == 'true'
                                  " @click="approvePost(data)">
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Approve Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                                  data.is_approved == 'true' && userData.need_approval == 'true'
                                  " @click="rejectPost(data)">
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Reject Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-clipboard:copy="shareUrl(data.hashid)" v-clipboard:success="onCopy"
                                  v-clipboard:error="onError" class="btn-sm p-0 text-muted">
                                  <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Copy</span>
                                </b-dropdown-item>

                                <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                                  data.user.hashid == userData.hashid) ||
                                  $can('Zircly Admin')
                                  ">
                                  <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                          <!-- Text Post -->
                          <div class="d-flex d-md-none mb-1"><b-badge  v-if="data.is_scheduled == true &&
                      data.is_published == 'false'
                      " pill variant="light-info">Scheduled : {{ data.created_at}} </b-badge>
                      </div>
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'text-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                      " style="max-height: 19em;overflow: hidden;text-overflow: ellipsis;">
                      <b-card-text>
                        <h2 class="card-header pb-1 mt-lg-0">{{ data.title }}</h2>
                      </b-card-text>
                      <b-card-text v-html="data.snippetbody"></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                  <div class="d-flex justify-content-center justify-content-lg-start" v-if="data.collage_img.length == 1">
                    <b-img v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " @click.stop="viewAttachments(data)" fluid rounded class="mb-25"
                      :src="data.collage_img[0].link" style="max-height: 400px; max-width: 100%" />
                  </div>
                  <div v-if="data.collage_img.length == 2" class="mb-1 row mx-0" 
                    @click.stop="viewAttachments(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
    data.collage_img[0].link +
    ');min-height:400px;border-radius:3px;background-size: cover;'
    " class="col-6"></div>
                    <div :style="'min-height:400px;'" class="col-6 mb-0 b-r-0 post-collage">
                      <img v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :src="data.collage_img[1].link" class="card-img h-100 w-100" alt="..." />
                      <!-- <div class="card-img-overlay w-100">
                        <h5 class="card-title text-white"><u>More</u></h5>
                      </div> -->
                    </div>
                  </div>
                  <div v-if="data.collage_img.length >= 3" class="mb-1 row post-collage" 
                    @click.stop="viewAttachments(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
                    data.collage_img[0].link +
                    ');min-height:400px; border-radius:5px;background-size: cover;'
                    " class="col-6 collage-img"></div>
                                    <div class="col-6 collage-img">
                                      <div v-if="data.collage_img[1].type == 'image/png' ||
                                        data.collage_img[1].type == 'image/jpeg'
                                        " :style="'background-image:url(' +
                    data.collage_img[1].link +
                    ');height:50%;border-radius:5px;background-size: cover;'
                    " class="row"></div>
                      <div class="card bg-dark mb-0 row b-r-0 text-white">
                        <img v-if="data.collage_img[2].type == 'image/png' ||
                          data.collage_img[2].type == 'image/jpeg'
                          " :src="data.collage_img[2].link" class="card-img" alt="..." />
                        <div class="card-img-overlay">
                          <h5 class="card-title text-white">
                            <u>+ {{ data.collage_img.length - 2 }} More</u>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-row class="mt-3">
                    <b-col  md="6" v-if="data.collage_img.length"  v-for="pdfFile in data.collage_img">
                      <div class="d-flex justify-content-center justify-content-lg-start" >

                        <b-button v-if="pdfFile.type != 'image/jpeg' || pdfFile.type != 'video/mp4' || pdfFile.type != 'video/webm'"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-primary"
                          :href="pdfFile.link"
                          target="_blank"
                          class="w-100"
                        >
                        {{pdfFile.name}}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <!-- End Text Post -->

                <!-- Photo Post -->
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'photo-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                      ">

                      <!-- post img -->
                      <div class="mt-1 mb-1 d-flex" v-if="data.attachments && data.attachments[0]">
                        <b-img fluid rounded :src="data.attachments[0].link" class="post-img"  @click.stop="openWindow(data.attachments[0].link)" />
                      </div>
                      <b-card-text v-html="data.snippetbody" style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;" ></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                </div>
                <!-- End Photo Post -->
                <!-- Video Post -->
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'video-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div :class="data.snippetbody.length > 200 ? 'post-card-summary' : '' " >

                      <!-- post img -->
                      <div class="mt-1 mb-1 d-flex" v-if="data.attachments && data.attachments[0]" >
                        <b-embed type="iframe" aspect="16by9" :src="data.attachments[0] && data.attachments[0].link" allowfullscreen  />
                      </div>
                      <b-card-text v-html="data.snippetbody" style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;"></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                </div>
                <!-- End Video Post -->

                <!-- Quote Post -->
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'quote-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="quote-post click-view" style="position: relative">
                    <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''" style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;">
                      <b-card-text>
                        "{{ data.snippetbody }}"
                      </b-card-text>
                      <p class="author-name my-1 pb-1" v-if="data.other_details.author"><strong>Author Name : <span>{{data.other_details.author}}</span></strong></p>
                    </div>
                  </div>

                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                </div>
                <!-- End Quote Post -->

                          <!-- likes comments  share-->
                          <b-row class="mt-50 like-button top-cmd" @click.stop v-if="data.is_published == 'true'">
                            <b-col cols="8" class="d-flex justify-content-start mb-0 pl-0">
                              <b-link class="d-flex align-items-center text-muted text-nowrap">
                                <div class="likes" v-if="!data.youLiked">

                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"
                                    fill="none" title="Like" class="active-like"
                                    @click="likePost(data, index, 'like')">
                                    <g>
                                      <path
                                        d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                                        stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath>
                                        <rect width="20" height="20" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <div class="emoji-reactions">
                                    <div class="emoji-item" @click="likePost(data, index, 'like')">
                                      <b-img :src="require('@/assets/images/icons/like/like.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'clap')">
                                      <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'love')">
                                      <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'support')">
                                      <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'idea')">
                                      <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'smile')">
                                      <b-img :src="require('@/assets/images/icons/like/smile.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                                    </div>
                                  </div>
                                </div>
                                <div class="likes" v-if="data.youLiked && data.likeType">
                                  <div v-if="data.likeType == 'like'" @click="likePost(data, index, 'like')">
                                    <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"
                                      class="active-like"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'clap'" @click="likePost(data, index, 'clap')">
                                    <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                                      " width="24" title="Celebrate" class=""></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'love'" @click="likePost(data, index, 'love')">
                                    <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                                      " width="24" title="Love" class=""></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'support'" @click="likePost(data, index, 'support')">
                                    <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                                      " width="24" title="Support" class=""></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'idea'" @click="likePost(data, index, 'idea')">
                                    <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                                      " width="24" title="Insightful" class="active-idea"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'smile'" @click="likePost(data, index, 'smile')">
                                    <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="24" title="Funny"
                                      class=""></b-img>
                                  </div>

                                  <div class="emoji-reactions">
                                    <div class="emoji-item" @click="likePost(data, index, 'like')">
                                      <b-img :src="require('@/assets/images/icons/like/like.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'clap')">
                                      <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'love')">
                                      <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'support')">
                                      <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'idea')">
                                      <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'smile')">
                                      <b-img :src="require('@/assets/images/icons/like/smile.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                                    </div>
                                  </div>
                                </div>
                                <span class="text-primary ml-25" v-if="data.likes_count"
                                  v-b-modal="'show-likes-' + data.hashid">{{
                                    kFormatter(data.likes_count) }}</span>
                              </b-link>

                            </b-col>
                            <b-modal :id="'show-likes-' + data.hashid" hide-footer modal-class="modal-likes"
                              scrollable title="Reactions" class="text-danger" @show="getPostReactions(data.hashid)">
                              <b-list-group v-if="postLikes && postLikes.length">
                                <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                                  class="d-flex align-items-center">
                                  <b-avatar target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" size="24" class="mr-1" />
                                  <b-link
                                    :to="{
                                      name: 'employee-profile',
                                      params: { id: like.user.hashid },
                                    }"
                                    class="font-weight-bold d-inline-block text-nowrap"
                                    target="_blank"
                                  >
                                    {{ like.user.name }}
                                  </b-link>
                                  <div class="ml-auto" v-if="like.type == 'like'">
                                    <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24"
                                      title="Like"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'clap'">
                                    <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                                      " width="24" title="Celebrate"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'love'">
                                    <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                                      " width="24" title="Love"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'support'">
                                    <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                                      " width="24" title="Support"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'idea'">
                                    <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                                      " width="24" title="Insightful"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'smile'">
                                    <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="24"
                                      title="Funny"></b-img>
                                  </div>
                                </b-list-group-item>
                              </b-list-group>
                              <b-card-text v-else-if="postLikes && postLikes.length == 0">
                                <div class="item error text-center my-2 empty-data">
                                  <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200"
                                    class="mr-50" style="background-color: transparent" />
                                  <h4> OH NO!</h4>
                                  <p class="d-block mb-1">No Records Found!</p>
                                </div>
                              </b-card-text>
                              <b-card-text v-else-if="postLikes == null">
                                <div class="d-flex align-items-center">
                                  <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150"
                                    class="mr-50" style="background-color: transparent" />
                                  <h4> Loading </h4>
                                </div>
                              </b-card-text>
                            </b-modal>
                            <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0 right-icons">
                              <!-- <b-link v-b-toggle.add-sidebar-view @click="viewPost(data)"
                                class="text-body text-nowrap d-flex align-items-center">
                                <feather-icon icon="MessageCircleIcon" size="20" class="profile-icon mr-50 text-primary"
                                  v-b-tooltip.hover.v-default title="Comments" />
                                <span v-if="data.comments_count" class="text-muted">{{
                                  kFormatter(data.comments_count)
                                }}</span>
                              </b-link>
                              <b-link v-if="data.visit_count_total"
                                class="text-body text-nowrap d-flex align-items-center mx-2" @click="showVisits(data.hashid)">
                                <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                                  v-b-tooltip.hover.v-default title="Views" />
                                <span class="text-muted">{{
                                  kFormatter(data.visit_count_total)
                                }}</span>
                              </b-link> -->
                  <b-link
                  v-if="data.comments_count > 0"
                  v-b-toggle.add-sidebar-view
                  @click="viewPost(data)"
                  class="text-body text-nowrap d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="20"
                    class="profile-icon mr-25 text-primary"
                    v-b-tooltip.hover.v-default
                    title="Comments"
                  />
                  <span class="text-muted">{{ kFormatter(data.comments_count) }}</span>
                </b-link>
                <feather-icon
                  v-else
                  icon="MessageCircleIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Comments"
                  style="cursor: pointer;"
                />
                <b-link v-if="data.visit_count_total > 0" class="text-body text-nowrap d-flex align-items-center mx-1"
                      @click="showVisits(data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                </b-link>
                    <feather-icon
                  v-else
                  icon="EyeIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Views"
                  style="cursor: pointer;"
                />

                              <b-modal :id="'show-visits-' + data.hashid" hide-footer modal-class="show-visits"
                                scrollable title="Views" class="text-danger">
                                <b-list-group v-if="postViews && postViews.length">
                                  <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid"
                                    class="d-flex align-items-center">
                                    <b-avatar target="_blank" :href="`/profile/${user.hashid}`" :src="user.avatar" size="24" class="mr-75"/>
                                    {{ user.name }}
                                  </b-list-group-item>
                                </b-list-group>
                                <b-card-text v-else-if="isBusy">
                                  <div class="d-flex align-items-center">
                                    <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150"
                                      class="mr-50" style="background-color: transparent" />
                                    <h4> Loading </h4>
                                  </div>
                                </b-card-text>
                                <b-card-text v-else>
                                  <div class="item error text-center my-2 empty-data">
                                    <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200"
                                      class="mr-50" style="background-color: transparent" />
                                    <p class="d-block mb-1">No Records Found!</p>
                                  </div>
                                </b-card-text>
                              </b-modal>
                              <b-link class="text-body text-nowrap d-flex align-items-center"
                                @click="savePost(data, index)">
                                <feather-icon icon="BookmarkIcon" class="mr-25 text-primary"
                                  :class="data.youSaved ? 'saved-post' : 'profile-icon'" v-b-tooltip.hover.v-default
                                  :title="data.youSaved ? 'Remove from Saved' : 'Save Post'" size="20" />
                                
                              </b-link>
                            </b-col>
                          </b-row>
                          <b-row class="mt-4 like-button top-cmd footer-option" @click.stop v-else>
                            <b-col sm="12" class="d-flex justify-content-end my-50 align-items-center">
                              <div v-if="data.is_scheduled == false">
                                <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Delete</b-button>
                                <b-button variant="primary" class="edit-btn opacity-100 ml-1" pill v-b-modal.create-custompop @click="post_form_data = data" v-if="($can('post_edit') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Edit</b-button>
                              </div>
                              <div v-if="data.is_scheduled == true">
                                <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Delete</b-button>
                                <b-button variant="outline-secondary" pill class="edit-btn ml-1" v-b-modal.create-custompop @click="post_form_data = data" v-if="($can('post_edit') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Edit</b-button>
                                <b-button variant="primary" class="post-btn opacity-100 ml-1" pill v-b-modal.create-custompop @click="post_form_data = data;postNow()" v-if="($can('post_edit') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                ">Post Now</b-button>
                              </div>
                            </b-col>
                          </b-row>
                          <!--/ likes comments  share-->
                        </b-card-body>
                      </b-card>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </b-card-body>
            </b-card>
            <!-- End Pinned Post -->
            <b-card :class="data.user.hashid == userData.hashid ? 'post-card mainuser' : 'post-card'" no-body v-for="(data, index) in posts" v-if="(pinnedPosts.length && pinnedPosts[0].length < 2) ||
              data.is_pinned == 'false'
              " :key="'post-' + index" :id="'post-card-' + data.hashid" >
              <!-- @click="viewPost(data)" -->
              <b-card-body :id="data.hashid" @click="viewPost(data)" v-observe-visibility="{
                callback: (isVisible, entry) =>
                  visibilityChanged(isVisible, entry, data),
                once: true,
                throttle: 0,
                throttleOptions: {
                  leading: 'visible',
                },
                intersection: {
                  threshold: 0,
                },
              }">
                <div class="d-flex justify-content-between align-items-center mb-2 post-header">
                  <div class="d-flex align-items-center">
                    <!-- avatar -->
                    <b-avatar v-if="data.user && data.user.hashid" :src="data.user.avatar" target="_blank" :href="`/profile/${data.user.hashid}`" style="background: transparent" size="40" class="mr-75" />
                    <b-avatar v-else :src="data.user.avatar" style="background: transparent" size="40" class="mr-75" />                    <!--/ avatar -->
                    <div class="profile-user-info mt-25">
                      <h6 class="mb-0">
                      
                        {{ data.user.name }}
                      </h6>
                      <small style="font-size: 12px">
                        {{ data.user.designation }}
                      </small>
                      <p class="d-block d-md-none"  v-if="data.is_published == 'true' ">
                        {{ data.created_at | formatDate }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-badge class="d-none d-md-flex schedule-badge" v-if="data.is_scheduled == true &&
                      data.is_published == 'false'
                      " pill variant="light-info">Scheduled : {{ data.created_at}} </b-badge>
                    <b-badge class="mr-50" v-if="data.is_approved == 'false' &&
                      data.is_published == 'true'
                      " pill variant="light-danger">Need Approval</b-badge>

                    <b-badge v-if="data.is_draft == 'true'" pill class="ml-50" variant="light-secondary">Draft</b-badge>
                    <b-img v-if="data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin-24px.png')" size="24"
                      class="m-1 pin-icon" style="background-color: transparent" v-b-tooltip.hover.v-default
                      title="Post is pinned by admin" />
                    <p class="d-none d-md-block" v-if="data.is_published == 'true' ">
                      {{ data.created_at | formatDate }}
                    </p>
                    <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none"
                      no-caret  v-if="data.is_published == 'true'">
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted"
                          v-b-tooltip.hover.v-default title="More Options" />
                      </template>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_pinned == 'false'" @click="pinPost(data)">
                        <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                          style="background-color: transparent" />
                        <span class="">Pin Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_pinned == 'true'" @click="unpinPost(data)">
                        <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                          style="background-color: transparent" />
                        <span class="">Un-Pin Post</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_public == false" @click="postPublicURL(data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Generate Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="($can('Zircly Admin') || data.user.hashid == userData.hashid)  && data.is_public == true" @click="postRevokePublicURL(data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/revoke-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Revoke Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="($can('Zircly Admin') || data.user.hashid == userData.hashid)  && data.is_public == true" @click="copyPublicURL(data.public_post.public_post_id)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Copy Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"  v-b-modal.create-custompop @click="post_form_data = data" v-if="($can('post_edit') &&
                        data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin')
                        ">
                        <!-- @click="editRecord(data)" -->
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                        data.is_approved == 'false' && userData.need_approval == 'true'
                        " @click="approvePost(data)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Approve Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                        data.is_approved == 'true' && userData.need_approval == 'true'
                        " @click="rejectPost(data)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Reject Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-clipboard:copy="shareUrl(data.hashid)" v-clipboard:success="onCopy"
                        v-clipboard:error="onError" class="btn-sm p-0 text-muted">
                        <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Copy</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                        data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin')
                        ">
                        <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <!-- Text Post -->
                <div class="d-flex d-md-none mb-1"><b-badge v-if="data.is_scheduled == true &&
                      data.is_published == 'false'
                      " pill variant="light-info">Scheduled : {{ data.created_at}} </b-badge>
                      </div>
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'text-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div class="mb-1" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                      " style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;">
                      <b-card-text>
                        <h2 class="card-header pb-1 mt-lg-0">{{ data.title }}</h2>
                      </b-card-text>
                      <b-card-text v-html="data.snippetbody"></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                  <div class="d-flex justify-content-center justify-content-lg-start" v-if="data.collage_img.length == 1">
                    <b-img v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " @click.stop="viewAttachments(data)" fluid rounded class="mb-25"
                      :src="data.collage_img[0].link" style="max-height: 400px; max-width: 100%" />
                  </div>
                  <div v-if="data.collage_img.length == 2" class="mb-1 row mx-0"
                    @click.stop="viewAttachments(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
    data.collage_img[0].link +
    ');min-height:400px;border-radius:3px;background-size: cover;'
    " class="col-6"></div>
                    <div :style="'min-height:400px;'" class="col-6 mb-0 b-r-0 post-collage">
                      <img v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :src="data.collage_img[1].link" class="card-img h-100 w-100" alt="..." />
                      <!-- <div class="card-img-overlay w-100">
                        <h5 class="card-title text-white"><u>More</u></h5>
                      </div> -->
                    </div>
                  </div>
                  <div v-if="data.collage_img.length >= 3" class="mb-1 row post-collage" 
                    @click.stop="viewAttachments(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
    data.collage_img[0].link +
    ');min-height:400px; border-radius:5px;background-size: cover;'
    " class="col-6 collage-img"></div>
                    <div class="col-6 collage-img">
                      <div v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :style="'background-image:url(' +
    data.collage_img[1].link +
    ');height:50%;border-radius:5px;background-size: cover;'
    " class="row"></div>
                      <div class="card bg-dark mb-0 row b-r-0 text-white">
                        <img v-if="data.collage_img[2].type == 'image/png' ||
                          data.collage_img[2].type == 'image/jpeg'
                          " :src="data.collage_img[2].link" class="card-img" alt="..." />
                        <div class="card-img-overlay"
                         @click.stop="viewAttachments(data)"
                         v-if="data.collage_img[0].type == 'image/png' ||
                          data.collage_img[0].type == 'image/jpeg' || data.collage_img[1].type == 'image/jpeg' || data.collage_img[1].type == 'image/png' || data.collage_img[2].type == 'image/jpeg' || data.collage_img[2].type == 'image/png'  "
                         >
                          <h5 class="card-title text-white">
                            <u>+ {{ data.collage_img.length - 2 }} More</u>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <b-row class="mt-3">
                    <b-col  md="6" v-if="data.collage_img.length"  v-for="pdfFile in data.collage_img.slice(0, 2)">
                      <div class="d-flex justify-content-center justify-content-lg-start" >

                        <b-button v-if="pdfFile.type == 'application/pdf'"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-primary"
                          :href="pdfFile.link"
                          target="_blank"
                          class="w-100"
                        >
                        {{pdfFile.name}}
                        </b-button>
        
                        <div @click.stop="viewAttachments(data)">
                          <video 
                            v-if="pdfFile.type == 'video/mp4' || pdfFile.type == 'video/webm'" 
                           controls
                            style="max-height: 100%; max-width: 100%; pointer-events: none;"
                          >
                            <source :src="pdfFile.link" :type="pdfFile.type">
                            Your browser does not support the video tag.
                          </video>
                        </div>
                       
                      </div>
                    </b-col>
                  </b-row> -->

                  <div>
    <b-row class="mt-3">
      <b-col md="6" v-for="(pdfFile, index) in data.collage_img.slice(0,2)" :key="index">
        <div class="d-flex justify-content-center justify-content-lg-start">
          <b-button v-if="pdfFile.type === 'application/pdf'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            :href="pdfFile.link"
            target="_blank"
            class="w-100"
          >
            {{ pdfFile.name }}
          </b-button>

          <div v-if="pdfFile.type === 'video/mp4' || pdfFile.type === 'video/webm'"
           @click.stop="viewAttachments(data)"
          >
            <video 
              controls
              style="max-height: 100%; max-width: 100%; pointer-events: none;"
            >
              <source :src="pdfFile.link" :type="pdfFile.type">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </b-col>

      <!-- +X more label -->
      <b-col md="6" v-if="data.collage_img.length > 2" style = "cursor: pointer;"  @click.stop="viewAttachments(data)">
        <div class="d-flex justify-content-center justify-content-lg-start align-items-center" v-if="data.collage_img[0].type != 'image/png' ||
                          data.collage_img[0].type != 'image/jpeg' || data.collage_img[1].type != 'image/jpeg' || data.collage_img[1].type != 'image/png' || data.collage_img[2].type != 'image/jpeg' || data.collage_img[2].type != 'image/png'  " >
          <span class="font-weight-bold text-primary" style="font-size: 16px; font-weight: bold;">+{{ data.collage_img.length - 2 }} more</span>
        </div>
      </b-col>
    </b-row>

  </div>
                  
                </div>
                <!-- End Text Post -->

                <!-- Photo Post -->
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'photo-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div class="" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''
                      ">

                      <!-- post img -->
                      <div class="mt-1 mb-1 d-flex" v-if="data.attachments && data.attachments[0]" >
                        <b-img fluid rounded :src="data.attachments[0].link" class="post-img" @click.stop="openWindow(data.attachments[0].link)" />
                      </div>
                      <b-card-text v-html="data.snippetbody" style="max-height: 8em;overflow: hidden;text-overflow: ellipsis;" ></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                </div>
                <!-- End Photo Post -->
                <!-- Video Post -->
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'video-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="click-view" style="position: relative">
                    <div :class="data.snippetbody.length > 200 ? 'post-card-summary' : '' " >

                      <!-- post img -->
                      <div class="mt-1 mb-1 d-flex" v-if="data.attachments.length">
                        <b-embed type="iframe" aspect="16by9" :src="data.attachments[0] && data.attachments[0].link" allowfullscreen />
                      </div>
                      <b-card-text v-html="data.snippetbody" style="max-height: 8em;overflow: hidden;text-overflow: ellipsis;"></b-card-text>
                    </div>
                  </div>
                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                </div>
                <!-- End Video Post -->

                <!-- Quote Post -->
                <div class="post-body" v-if="data.other_details && data.other_details.post_type == 'quote-post'">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="quote-post mt-1 click-view" style="position: relative">
                    <div class="mb-1" :class="data.snippetbody.length > 200 ? 'post-card-summary' : ''" style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;">
                      <b-card-text>
                        "{{ data.snippetbody }}"
                      </b-card-text>
                      <p class="author-name my-1 pt-50" v-if="data.other_details.author"><strong>Author Name : <span>{{data.other_details.author}}</span></strong></p>
                    </div>
                  </div>

                  <p v-if="data.snippetbody.length > 200" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>
                </div>
                <!-- End Quote Post -->

                <!-- likes comments  share-->
                <b-row class="mt-50 like-button top-cmd" @click.stop v-if="data.is_published == 'true'">
                  <b-col cols="8" class="d-flex justify-content-start mb-0 pl-0">
                    <b-link class="d-flex align-items-center text-muted text-nowrap">
                      <div class="likes" v-if="!data.youLiked">

                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none"
                          title="Like" class="active-like" @click="likePost(data, index, 'like')">
                          <g>
                            <path
                              d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                              stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(data, index, 'like')">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'clap')">
                            <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'love')">
                            <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'support')">
                            <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'idea')">
                            <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'smile')">
                            <b-img :src="require('@/assets/images/icons/like/smile.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                          </div>
                        </div>
                      </div>
                      <div class="likes" v-if="data.youLiked && data.likeType">
                        <div v-if="data.likeType == 'like'" @click="likePost(data, index, 'like')">
                          <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"
                            class="active-like"></b-img>
                        </div>
                        <div v-if="data.likeType == 'clap'" @click="likePost(data, index, 'clap')">
                          <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                            " width="24" title="Celebrate" class=""></b-img>
                        </div>
                        <div v-if="data.likeType == 'love'" @click="likePost(data, index, 'love')">
                          <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                            " width="24" title="Love" class=""></b-img>
                        </div>
                        <div v-if="data.likeType == 'support'" @click="likePost(data, index, 'support')">
                          <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                            " width="24" title="Support" class=""></b-img>
                        </div>
                        <div v-if="data.likeType == 'idea'" @click="likePost(data, index, 'idea')">
                          <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                            " width="24" title="Insightful" class="active-idea"></b-img>
                        </div>
                        <div v-if="data.likeType == 'smile'" @click="likePost(data, index, 'smile')">
                          <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="24" title="Funny"
                            class=""></b-img>
                        </div>

                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(data, index, 'like')">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'clap')">
                            <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'love')">
                            <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'support')">
                            <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'idea')">
                            <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'smile')">
                            <b-img :src="require('@/assets/images/icons/like/smile.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                          </div>
                        </div>
                      </div>
                      <span class="text-primary ml-25" v-if="data.likes_count" v-b-modal="'show-likes-' + data.hashid">{{
                        kFormatter(data.likes_count) }}</span>
                    </b-link>

                  </b-col>
                  <b-modal :id="'show-likes-' + data.hashid" hide-footer modal-class="modal-likes"
                    scrollable title="Reactions" class="text-danger" @show="getPostReactions(data.hashid)">
                    <b-list-group v-if="postLikes && postLikes.length">
                      <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                        class="d-flex align-items-center">
                        <b-avatar target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" size="24" class="mr-1" />
                        <b-link
                          :to="{
                            name: 'employee-profile',
                            params: { id: like.user.hashid },
                          }"
                          class="font-weight-bold d-inline-block text-nowrap"
                          target="_blank"
                        >
                          {{ like.user.name }}
                        </b-link>
                        <div class="ml-auto" v-if="like.type == 'like'">
                          <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'clap'">
                          <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                            " width="24" title="Celebrate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'love'">
                          <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                            " width="24" title="Love"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'support'">
                          <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                            " width="24" title="Support"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'idea'">
                          <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                            " width="24" title="Insightful"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'smile'">
                          <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="24" title="Funny"></b-img>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <b-card-text v-else-if="postLikes && postLikes.length == 0">
                      <div class="item error text-center my-2 empty-data">
                        <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                          style="background-color: transparent" />
                        <h4> OH NO!</h4>
                        <p class="d-block mb-1">No Records Found!</p>
                      </div>
                    </b-card-text>
                    <b-card-text v-else-if="postLikes == null">
                      <div class="d-flex align-items-center">
                        <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                          style="background-color: transparent" />
                        <h4> Loading </h4>
                      </div>
                    </b-card-text>
                  </b-modal>
                  <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0 right-icons">
                  <b-link
                  v-if="data.comments_count > 0"
                  v-b-toggle.add-sidebar-view
                  @click="viewPost(data)"
                  class="text-body text-nowrap d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="20"
                    class="profile-icon mr-25 text-primary"
                    v-b-tooltip.hover.v-default
                    title="Comments"
                  />
                  <span class="text-muted">{{ kFormatter(data.comments_count) }}</span>
                </b-link>
                <feather-icon
                  v-else
                  icon="MessageCircleIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Comments"
                  style="cursor: pointer;"
                />
                <b-link v-if="data.visit_count_total > 0" class="text-body text-nowrap d-flex align-items-center mx-1"
                      @click="showVisits(data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                </b-link>
                    <feather-icon
                  v-else
                  icon="EyeIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Views"
                  style="cursor: pointer;"
                />
                    

                    <b-modal :id="'show-visits-' + data.hashid" hide-footer modal-class="show-visits" scrollable
                      title="Views" class="text-danger">
                      <b-list-group v-if="postViews && postViews.length">
                        <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid"
                          class="d-flex align-items-center">
                          <b-avatar target="_blank" :href="`/profile/${user.hashid}`" :src="user.avatar" size="24" class="mr-75"/>
                          {{ user.name }}
                        </b-list-group-item>
                      </b-list-group>
                      <b-card-text v-else-if="isBusy">
                        <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                            style="background-color: transparent" />
                          <h4> Loading </h4>
                        </div>
                      </b-card-text>
                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                            style="background-color: transparent" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-modal>
                    <b-link class="text-body text-nowrap d-flex align-items-center" @click="savePost(data, index)">
                      <feather-icon icon="BookmarkIcon" class="mr-25 text-primary"
                        :class="data.youSaved ? 'saved-post' : 'profile-icon'" v-b-tooltip.hover.v-default
                        :title="data.youSaved ? 'Remove from Saved' : 'Save Post'" size="20" />
                      
                    </b-link>
                  </b-col>
                </b-row>
                <b-row class="mt-50 like-button top-cmd footer-option" @click.stop v-else>
                  <b-col sm="12" class="d-flex justify-content-end my-50 align-items-center">
                    <div v-if="data.is_scheduled == false">
                      <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      " >Delete</b-button>
                      <b-button variant="primary" class="edit-btn opacity-100 ml-1" pill v-b-modal.create-custompop @click="post_form_data = data" v-if="($can('post_edit') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      " >Edit</b-button>
                    </div>
                    <div v-if="data.is_scheduled == true">
                      <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                                data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Delete</b-button>
                      <b-button variant="outline-secondary" pill class="edit-btn ml-1" v-b-modal.create-custompop @click="post_form_data = data" v-if="($can('post_edit') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      " >Edit</b-button>
                      <b-button variant="primary" class="post-btn opacity-100 ml-1" pill v-b-modal.create-custompop @click="post_form_data = data;postNow()" v-if="($can('post_edit') &&
                      data.user.hashid == userData.hashid) ||
                      $can('Zircly Admin')
                      ">Post Now</b-button>
                    </div>
                  </b-col>
                </b-row>
                <!--/ likes comments  share-->
              </b-card-body>
              <b-card-footer class="p-0" v-if="data.is_published == 'true'">
                <div class="comment-details">
                  <comments :post="data" :index="index" :employeeListOptions.sync="employeeListOptions" />

                  <input type="text" v-show="false" v-model="userSearch" />
                  <div id="editor-container"></div>
                  <div class="list-group" style="max-height: 200px; overflow-y: scroll">
                    <div class="item fruit list-group-item list-group-item-action br-0" v-for="user in filteredList()"
                      :key="user.code">
                      <p class="mb-0" @click="addTag(user.label, 'comment-field-' + index)">
                        {{ user.label }}
                      </p>
                    </div>
                    <div class="item error text-center my-2 empty-data" v-if="userSearch && !filteredList().length">
                      <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="150" class="mr-50"
                        style="background-color: transparent" />
                      <h4> OH NO!</h4>
                      <p class="d-block mb-1">No results found!</p>
                    </div>
                  </div>
                </div>
              </b-card-footer>
            </b-card>

            <b-col cols="12" order="4">
              <div class="text-center mb-2 d-flex align-items-center justify-content-center">
                <div v-if="isPostLoading" class="text-center my-5 empty-data">
                  <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                    style="background-color: transparent" />
                  <h4> Loading </h4>
                  <p class="d-block mb-1">Please Wait</p>
                </div>
                <div v-else>
                  <div v-if="posts.length == 0" class="text-center my-5 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="100" class="mr-50"
                      style="background-color: transparent" />
                    <h4> OH NO!</h4>
                    <p class="d-block mb-1">No results found!</p>
                  </div>
                  <div v-if="posts.length > 0 && reachedLastPost" class="text-center my-5 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="100" class="mr-50"
                      style="background-color: transparent" />
                    <h4> Reached Last Post!</h4>
                    <p class="d-block mb-1">No more Posts to Show!</p>
                  </div>
                </div>
              </div>
            </b-col>
          </div>

          <EventsList v-if="filterByType == 'event'" />
          <PollsList v-if="filterByType == 'poll'" />

        </div>
      </div>
      <div class="col-lg-4 col-xl-4" v-if="!$route.params.id">
        <div class="eventpoll-highlight">
          <div class="event-data" v-if="filterByType != 'event'">
            <h4 class="mb-0"><feather-icon icon="CalendarIcon" size="18" />Upcoming Events</h4>
            <b-card class="p-0 shadow-none">
              <EventsShort :recent-events="recentEvents" />
            </b-card>
          </div>
          <div class="poll-data" v-if="filterByType != 'poll'">
            <h4 class="mb-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <rect x="1.55469" y="8.58594" width="3.64286" height="7.28571" rx="1.82143" stroke="black" stroke-width="1.25"/>
              <rect x="7.44922" y="3.125" width="3.64286" height="12.75" rx="1.82143" stroke="black" stroke-width="1.25"/>
              <rect x="13.3438" y="5.85938" width="3.64286" height="10.0179" rx="1.82143" stroke="black" stroke-width="1.25"/>
            </svg> Recent Polls</h4>
            <b-card>
              <PollsShort :recent-polls="recentPolls" />
            </b-card>
          </div>
        </div>
        <!-- events and polls goes here -->
        <Timeline v-if="userData.company_twitter" :id="userData.company_twitter" source-type="profile" :options="{
          tweetLimit: '5',
          theme: $store.state.appConfig.layout.skin,
        }" class="mb-2 tweets">
        </Timeline>
        <YouTubeWidget v-if="userData.company_youtube == 'true'" :apiKey="userData.company_youtube_apikey"
          :channelId="userData.company_youtube_channel_id" :resultsPerRequest="3"></YouTubeWidget>
      </div>
      <div v-if="$route.params.id" class="col-lg-4 col-xl-4">
        <b-overlay :show="isLoading" rounded="sm" :variant="skin">
          <profile-about :about-data="profileData" />
        </b-overlay>
        <b-overlay :show="isLoading" rounded="sm" :variant="skin">
          <profile-upcoming-events v-if="profileData.events && profileData.events.length" :events="profileData.events" />
        </b-overlay>
        <b-overlay v-if="$route.params.id && userData.application_mode == 'company'" :show="isLoading" rounded="sm" :variant="skin">
          <profile-suggested-pages v-if="profileData.teamMates && profileData.teamMates.length" :pages-data="profileData.teamMates" />
        </b-overlay>
      </div>
    </div>
    <!-- End Post Listing -->
    <!-- Modals/Componenets -->
    <EventAdd :item-id.sync="postId" :sidebar-event-add-active.sync="sidebarEventAddActive"
      :employeeListOptions.sync="employeeListOptions" :customFields.sync="customFields" />
    <PostView :item-id.sync="postId" :sidebar-view-active.sync="sidebarViewActive"
      :employeeListOptions.sync="employeeListOptions" :itemData.sync="postData"  @triggerParentRefresh="getPosts()" @triggerEditPost="OpenEdit" @triggerPostNow="OpenPostNow"/>
    <PollAdd :item-id.sync="postId" :sidebar-poll-add-active.sync="sidebarPollAddActive"
      :employeeListOptions.sync="employeeListOptions" />
    
      <b-modal v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value"
      :id="'unseen-' + channel.value" modal-class="modal-likes" scrollable size="lg"
      :title="'Unseen ' + channel.label + ' Posts'" ok-title="Mark all as Read" ok-only @ok="markAsRead(channel.value)"
      class="text-danger">
      <b-list-group v-if="unseenBadge['unseen_' + channel.value] &&
        unseenBadge['unseen_' + channel.value].length
        ">
        <b-list-group-item :key="post.hashid" v-for="post in unseenBadge['unseen_' + channel.value]">
          <b-row>
            <b-col md="1" sm="2" class="unseen-col">
              <b-avatar :src="post.avatar" style="background: transparent" size="24" />
            </b-col>
            <b-col md="7" sm="8" class="unseen-col"> "{{ post.title }}" </b-col>
            <b-col cols="3" class="d-none d-md-block">
              -by {{ post.author }}
            </b-col>
            <b-col cols="1">
              <b-link class="text-body" @click="OpenPost(post)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="mr-25 text-primary" v-b-tooltip.hover.v-default
                  v-b-tooltip.left title="Open Post" />
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="unseenBadge['unseen_' + channel.value] &&
        unseenBadge['unseen_' + channel.value].length == 0
        ">
        <div class="item error text-center my-2 empty-data">
          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
            style="background-color: transparent" />
          <h4> OH NO!</h4>
          <p class="d-block mb-1">No Records Found!</p>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal id="unseen-feeds" modal-class="modal-likes" scrollable size="lg" title="Unseen Feeds"
      class="text-danger" ok-title="Mark all as Read" ok-only @ok="markAsRead('feeds')">
      <b-list-group v-if="unseenBadge.unseen_feeds && unseenBadge.unseen_feeds.length">
        <b-list-group-item v-for="post in unseenBadge.unseen_feeds" :key="post.hashid">
          <b-row>
            <b-col md="1" sm="2" class="unseen-col">
              <b-avatar :src="post.avatar" style="background: transparent" size="24" />
            </b-col>
            <b-col md="7" sm="8" class="unseen-col"> "{{ post.title }}" </b-col>
            <b-col cols="3" class="d-none d-md-block">
              -by {{ post.author }}
            </b-col>
            <b-col cols="1">
              <b-link class="text-body" @click="OpenPost(post)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="mr-25 text-primary" v-b-tooltip.hover.v-default
                  v-b-tooltip.left title="Open Post" />
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="unseenBadge.unseen_feeds && unseenBadge.unseen_feeds.length == 0
        ">
        <div class="item error text-center my-2 empty-data">
          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
            style="background-color: transparent" />
          <h4> OH NO!</h4>
          <p class="d-block mb-1">No Records Found!</p>
        </div>
      </b-card-text>
    </b-modal>

    <!-- channel descriptions modal -->
    <b-modal id="channel-descriptions-modal" ok-only ok-title="Accept" modal-class="modal-primary" centered
      size="lg" title="Channel Descriptions" hide-footer>
      <b-tabs class="mb-1">
        <b-tab v-if="communityChannels.length" v-for="channel, index in communityChannels" :title="channel.label"
          :active="index == 0">
          <b-card-text v-if="channel.description" class="ml-1">
            {{ channel.description }}
          </b-card-text>
          <b-card-text v-else class="ml-1">
            This channel has no description.
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- END Modals/Componenets -->
    <b-modal id="scheduled-postview" size="lg" title="schedule-postpop" hide-footer :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="">
          <h5 class="schedule-header mb-0 d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" @click="closeScheduledPopUP">
            <g clip-path="url(#clip0_9678_29091)">
            <path d="M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_9678_29091">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            <span>Scheduled & Draft Posts </span>
          </h5>
        </div>
        <div class="next-expand d-flex align-items-center">
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 26px; height: 26px;" v-b-tooltip.hover.v-default
            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')" width="24px" height="24px" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 26px; height: 26px;" v-b-tooltip.hover.v-default
            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  width="24px" height="24px"/>
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="closeScheduledPopUP" />
      </template>
      <div class="schedule-list">
        <ul class="m-0 p-0">
          <li class="list-unstyled mb-1" v-for="post in nonPublishedPosts" v-b-modal.create-custompop
          @click="post_form_data = post;closeScheduledPopUP();" v-if="($can('post_edit') &&
            post.user.hashid == userData.hashid) ||
            $can('Zircly Admin')
            ">
            <div class="d-flex align-items-center justify-content-between mb-50">
              <h6 class="d-flex align-items-center mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 16" fill="none" v-if="post.is_scheduled">
                <path d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C14.9979 6.14413 14.2597 4.36489 12.9474 3.05259C11.6351 1.7403 9.85587 1.00212 8 1ZM2.01 8.197L2.679 8.42L3.5 9.6515V10.293C3.50003 10.4256 3.55273 10.5528 3.6465 10.6465L5 12V13.1885C4.11895 12.6786 3.38218 11.9529 2.85909 11.0797C2.33599 10.2064 2.04379 9.21438 2.01 8.197ZM8 14C7.56745 13.9991 7.13626 13.9513 6.714 13.8575L7 13L7.9025 10.744C7.93258 10.6687 7.94387 10.5872 7.93543 10.5065C7.92698 10.4258 7.89903 10.3485 7.854 10.281L7.1485 9.2225C7.10283 9.15404 7.04096 9.09792 6.96839 9.0591C6.89583 9.02029 6.8148 8.99999 6.7325 9H4.2675L3.6435 8.0635L4.707 7H5.5V8H6.5V6.633L8.434 3.248L7.566 2.752L7.1385 3.5H5.7675L5.225 2.6855C5.95392 2.30085 6.755 2.07235 7.57715 2.01459C8.3993 1.95682 9.22447 2.07106 10 2.35V4C10 4.13261 10.0527 4.25979 10.1464 4.35355C10.2402 4.44732 10.3674 4.5 10.5 4.5H11.2325C11.3148 4.50001 11.3958 4.47971 11.4684 4.4409C11.541 4.40208 11.6028 4.34596 11.6485 4.2775L12.087 3.6195C12.6581 4.15213 13.12 4.79085 13.447 5.5H11.41C11.2945 5.50007 11.1825 5.54016 11.0932 5.61344C11.0038 5.68673 10.9426 5.78869 10.92 5.902L10.5585 8.137C10.5414 8.24222 10.5584 8.35016 10.6069 8.44506C10.6555 8.53995 10.7331 8.61684 10.8285 8.6645L12.5 9.5L12.8425 11.528C12.2875 12.2928 11.5595 12.9154 10.7179 13.3451C9.87626 13.7747 8.94494 13.9991 8 14Z" fill="#6E6B7B"/>
                </svg>
                <small v-if="post.is_scheduled" ><span>Posting </span> <span>{{ post.created_at | formatDate }}</span> at <span>{{post.scheduled_time}}</span> </small> <b-badge pill variant="light-info"  >Draft</b-badge>
              </h6>
              <div class="right-action d-flex align-items-center gap-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" v-b-tooltip.hover.v-defult
                title="Schedule" v-if="!post.is_published || post.is_published == 'false'" v-b-modal.schedule-timepop>
                  <g clip-path="url(#clip0_9674_28132)">
                    <path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 12L15 14" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 7V12" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_9674_28132">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" @click.stop.prevent="confirmDeleteRecord(post)" v-if="($can('post_delete') &&
                post.user.hashid == userData.hashid) ||
                $can('Zircly Admin')
                ">
                  <g clip-path="url(#clip0_9674_28137)">
                    <path d="M4 7H20" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 11V17" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 11V17" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="#FF4B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_9674_28137">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="body-content">
              <h5>{{post.title}}</h5>
              <p class="mb-0" v-html="post.snippetbody"></p>
            </div>
          </li>
        </ul>
      </div>
    </b-modal>
    <!-- Scedule Time -->
    <b-modal id="schedule-timepop" size="lg" title="schedule-time" hide-footer :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" class="custom-popmodal">
      <template #modal-header="{ close }">
        <div class="">
          <h5 class="schedule-header mb-0">
            <span>Scheduled Posts</span>
          </h5>
        </div>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click.prevent.stop="confirmScheduleCloseButton" />
      </template>
      <b-row :class="optionsSelected== 0 ? 'schedule-pick' : 'schedule-pick deactive'">
        <!-- Select custom date time -->
      <!-- <b-row class="schedule-pick deactive"> -->
        <!-- End Select custom date time -->

        <b-col sm="12" lg="7">
          <!-- <flatPickr v-model="post_form_data.scheduled_time"/> -->
          <div class="custom-calender">
            <div class="container-calendar">
                <h3 id="monthAndYear"></h3>
                <div class="button-container-calendar">
                    <button id="previous" @click="previous()" class="btn-arrow">
                      <b-img :src="require('@/assets/images/icons/dashboard/right-arrow.svg')" width="20" class="mr-50"/>
                    </button>
                    <div class="month-year">
                      <!-- <select v-model="selectedMonth">
                        <option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
                      </select> -->
                      <b-badge variant="light-primary">
                        {{months[selectedMonth]}}
                      </b-badge>
                      <b-badge variant="light-primary">
                        {{selectedYear}}
                      </b-badge>
                      <!-- <select v-model="selectedYear">
                        <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
                      </select> -->
                      <!-- <div class="select-year">
                        <input type="text" id="year">
                      </div> -->
                    </div>
                    <button id="next" @click="next()" class="btn-arrow">
                      <b-img :src="require('@/assets/images/icons/dashboard/left-arrow.svg')" width="30" class="ml-50"/>
                    </button>
                </div>
                <table id="calendar" class="table-calendar">
                  <thead>
                    <tr>
                      <th v-for="day in days" :key="day">{{ day }}</th>
                    </tr>
                  </thead>
                  <tbody id="calendar-body">
                    <tr v-for="(week, weekIndex) in calendarDays" :key="weekIndex">
                      <td v-for="(day, dayIndex) in week" :key="dayIndex" :class="dayClass(day)" @click="scheduleDateChange(day)">
                        <span>{{ day }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
        </b-col>
        <b-col sm="12" lg="5" class="select-scheduletype">
          <div class="pick-timedate" v-if="optionsSelected == 0">
            <b-row>
              <b-col>
                <label>Pick Date & Time</label>
                <b-form-group class="mb-0">
                  <!-- <flat-pickr id="scheduled_time" v-model="post_form_data.scheduled_time" class="form-control"
                    :config="{
                    minDate: 'today',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    altFormat: 'M j, h:iK',
                    altInput: true,
                    }" /> -->
                    <v-select v-model="post_form_data.scheduled_timezone"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="timezones" :clearable="false"
                              label="name" :reduce="(option) => option.name" :filter-by="myTimezoneFilter"
                              :searchable="true" input-id="timezone"
                              placeholder="Select Timezone..">
                              <template slot="option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>

                              <template slot="selected-option" slot-scope="option">
                                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                                {{ option.name }} ({{ option.offset }})
                              </template>
                            </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col sm="12" md="6">
                <div class="selected-date">
                  <input type="text" id="datepicker" v-model="post_form_data.scheduled_date">
                  <!-- <span style="color:red">select a valid date</span> -->
                </div>
              </b-col>
              <b-col sm="12" md="6">
                <div class="selected-timer">
                  <div class="select-timer d-flex align-items-center">
                      <b-dropdown :text="post_form_data.scheduled_hour" class="select-hours custom-multiselect">
                        <b-dropdown-item v-for="hour in hours" @click="post_form_data.scheduled_hour = hour">{{hour}}</b-dropdown-item>
                      </b-dropdown>
                      <span>:</span>
                      <b-dropdown :text="post_form_data.scheduled_minute" class="select-mins" >
                        <b-dropdown-item v-for="minute in minutes" @click="post_form_data.scheduled_minute = minute">{{minute}}</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  <span class="center-line"> | </span>
                  <b-dropdown :text="post_form_data.scheduled_meridiem"  class="select-hours">
                    <b-dropdown-item @click="post_form_data.scheduled_meridiem = 'am'">AM</b-dropdown-item>
                    <b-dropdown-item @click="post_form_data.scheduled_meridiem = 'pm'">PM</b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </div>
          <hr />
          <div class="pre-scedule">
            <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
              <b-form-radio-group id="btn-radios-3" v-model="post_form_data.scheduled_time"
              :options="schedule_options" @change="optionsSelectedEvent() " :aria-describedby="ariaDescribedby" name="radio-btn-stacked"
              buttons button-variant="outline" class="w-100 select-period" stacked></b-form-radio-group>
            </b-form-group>
          </div>
          <u v-if="optionsSelected == 1" @click="optionsSelected = 0; post_form_data.scheduled_time = defaultDateTime" class="pick-timedate"><label>Pick Date & Time</label></u>
          <b-button pill variant="primary" class="unselect" @click="post_form_data.is_scheduled = true;formSubmitted()">Schedule</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-model="isPopupVisible" id="modal-imagecarousel"  hide-footer centered>
  <b-carousel
    id="carousel-interval"
    controls
    v-if="popupImages.length"
  >

    <b-carousel-slide
      v-for="(media, index) in popupImages"
      :key="index"
    >
      <template #img>
        <div v-if="media.type.startsWith('image/')">
          <img :src="media.link" class="d-block w-100" alt="Image slide">
        </div>
        <div v-else-if="media.type.startsWith('video/')">
          <video 
            controls
            class="d-block w-100"
            style="max-width: 100%;"
          >
            <source :src="media.link" :type="media.type">
            Your browser does not support the video tag.
          </video>
        </div>
        <!-- <div v-else-if="media.type == 'application/pdf'">
          <iframe  :src="media.link" class="w-100" style="height: 500px; border: 1px solid #ccc;"></iframe>
        </div> -->
      </template>
    </b-carousel-slide>
  </b-carousel>
  <div class="w-100">
          <b-button
            size="sm"
            class="float-right"
            v-b-toggle.add-sidebar-view
            @click="isPopupVisible = false"
            variant="white"
          >
            View Full Post
          </b-button>
        </div>
</b-modal>

  </div>
</template>
  
<script>
import { BEmbed, BRow, BCol, BOverlay, BButton, BImg } from "bootstrap-vue";
import { $themeConfig } from '@themeConfig'
import bomb from "@/libs/bomb/bomb";
import moment from "moment-timezone";
import Omoment from "moment";
import { Timeline } from "vue-tweet-embed";
import store from "@/store";

import PostAdd from "./PostAdd.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import $ from "jquery";
Vue.use(VueCookies);
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);
import axios from "@axios";
import { kFormatter } from "@core/utils/filter";
import EventsList from "@/views/feed/events/EventsList.vue";
import PollsList from "@/views/feed/polls/PollsList.vue";
import { bus } from "@/main.js";
import EventsShort from "@/views/feed/events/EventsShort.vue";
import PollsShort from "@/views/feed/polls/PollsShort.vue";
import Replies from "./Replies.vue";
import Comments from "./Comments.vue";
import PostView from "./PostView.vue";

import PollAdd from "@/views/feed/polls/PollAdd.vue";

import QuillInput from "@/components/QuillInput.vue";
import EventAdd from "@/views/feed/events/EventAdd.vue";
import { onMounted, ref, watch, onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
/* eslint-disable global-require */
const VueUploadComponent = require('vue-upload-component')
const { appMaxpopImg, appMinpopImg } = $themeConfig.app
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
let timezones = require("/src/libs/timezone.json");
import flatPickr from "vue-flatpickr-component";
import VEmojiPicker from "v-emoji-picker";
import packEmoji from "v-emoji-picker/data/emojis.js";
import { required } from "@validations";
import ProfileSuggestedPages from "./ProfileSuggestedPages.vue";
import ProfileAbout from "./ProfileAbout.vue";
import ProfileUpcomingEvents from "./ProfileUpcomingEvents.vue";
Vue.component('file-upload', VueUploadComponent)
export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BButton,
    BImg,
    Timeline,
    PostAdd,
    EventsList,
    PollsList,
    EventsShort,
    PollsShort,
    Replies,
    Comments,
    QuillInput,
    EventAdd,
    PostView,
    PollAdd,
    FileUpload: VueUploadComponent,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BEmbed,
    VEmojiPicker,
    ProfileSuggestedPages,
    ProfileAbout,
    ProfileUpcomingEvents,
  },

  data() {
    return {
      isSpinning: false,
      today: new Date(),
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      optionsSelected:0,
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      hours : Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')),
      minutes : Array.from({ length: 12 }, (_, i) => String(i*5).padStart(2, '0')),
      defaultDateTime:moment().add(1, "days").format("YYYY-MM-DD"),
      createYear : [],
      watchEnabled: true,
      nonPublishedPosts:[],
      boxOne: '',
      postType:"text-post",
      toolbarState: {
      textPostToolbar: true,
      imagePostToolbar: false,
      videoPostToolbar: false,
      },

      dialogHidden: true,
      appMaxpopImg,
      appMinpopImg,
      coverImg: require("@/assets/images/profile/user-uploads/timeline.svg"),
      userData: this.$cookies.get("userData"),
      options: [
        {
          text: "Energetic",
          value: "5",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/cute.svg"),
        },
        {
          text: "Focused",
          value: "4",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/shy.svg"),
        },
        {
          text: "Neutral",
          value: "3",
          color: "btn-outline-primary",
          icon: "PauseIcon",
          img: require("@/assets/images/moods/neutral.svg"),
        },
        {
          text: "Stressed",
          value: "2",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/thinking.svg"),
        },
        {
          text: "Frustrated",
          value: "1",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/angry.svg"),
        },
      ],
      swipeData: {
        type: "",
        user_feeling: 6,
      },
      recentSwipes: null,
      mood_options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
      profileData: {
        header: {
          avatar: "",
          username: "",
          designation: "",
          coverImg: require("@/assets/images/profile/user-uploads/timeline.svg"),
        },
        userAbout: {
          employee: "",
          email: "",
          joined: "",
        },
        twitterFeeds: [
          {
            imgUrl: require("@/assets/images/avatars/5-small.png"),
            title: "Gertrude Stevens",
            id: "tiana59 ",
            tags: "#design #fasion",
            desc: "I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.",
            favorite: false,
          },
          {
            imgUrl: require("@/assets/images/avatars/12-small.png"),
            title: "Lura Jones",
            id: "tiana59 ",
            tags: "#vuejs #code #coffeez",
            desc: "Halvah I love powder jelly I love cheesecake cotton candy. 😍",
            favorite: true,
          },
          {
            imgUrl: require("@/assets/images/avatars/1-small.png"),
            title: "Norman Gross",
            id: "tiana59 ",
            tags: "#sketch #uiux #figma",
            desc: "Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.",
            favorite: false,
          },
        ],
      },
      employeeListOptions: [],
      customFields: {},
      communityChannels: [
        { label: "Community", value: "community" },
      ],
      unseenBadge: {
        community: 0,
        feeds: 0,
        events: 0,
        polls: 0,
        feedback: 0,
      },
      isPopupVisible: false,
      popupImages: [],
      myFiles: [],
      Selected: '',
      skin: store.state.appConfig.layout.skin,
      posts: [],
      filterByType: "community",
      comment_id: null,
      comment_post_index: null,
      reply_to: null,
      skin: store.state.appConfig.layout.skin,
      sidebarViewActive: false,
      sidebarEditActive: false,
      sidebarEventAddActive: false,
      sidebarPollAddActive: false,
      postId: null,
      postData: null,
      userData: this.$cookies.get("userData"),
      search: "",
      userSearch: "",
      userSearchActive: false,
      inputId: null,
      isBusy: true,
      isPostLoading: true,
      reachedLastPost: false,
      tempFlag: true,
      lastPage: 1,
      nextPage: 1,
      currentPage: 1,
      postLikes: null,
      searchQuery: "",
      searchQueryEvent: "",
      searchQueryPoll: "",
      editComments: [],
      onGoingProgram: {},
      typeFilter: "upcoming",
      typeChannel: "community",
      fullScreen: false,
      typeOptions: [
        { value: "upcoming", label: "Upcoming" },
        { value: "past", label: "Past" },
      ],
      timeOptions: [
        { value: "Limited", label: "Limited" },
        { value: "UnLimited", label: "UnLimited" },
      ],
      postTypeOptions: [
        { value: "text-post", label: "New Text Post" },
        { value: "video-post", label: "New Video Post" },
        { value: "photo-post", label: "New Photo Post" },
        { value: "quote-post", label: "New Quote Post" },
      ],
      page2: false,
      originalOption: null,
      QuoteSourceTypes: [
        { value: "Book", label: "Book" },
        { value: "Blog/Article", label: "Blog/Article" },
        { value: "Video", label: "Video" },
        { value: "Podcast", label: "Podcast" },
      ],
      unseenBadge: {
        community: 0,
        feeds: 0,
        events: 0,
        polls: 0,
        feedback: 0,
      },
      postViews: [],
      datasuggest: {
        data: [
          {
            developers: [
              {
                name: "guna",
                time: "",
                img: "",
              },
            ],
            designers: [
              {
                name: "guna",
                time: "",
                img: "",
              },
            ],
          },
        ],
      },
      filteredOptions: [],
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "Type 'e'",
      },
      limit: 10,
      profileData: {
        header: [],
        userAbout: [],
        teamMates: [],
        events: [],
      },
      isLoading: false,
      postsData: [],
      recentPolls: [],
      recentEvents: [],
      pollData: {
        title: "",
        description: "",
        options: ["", ""],
        allow_multiple: false,
        voter_identity_type: "identified",
        limited_time: false,
      },
      post_form_data: {
        title: '',
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        body: "",
        scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
        scheduled_hour:"09",
        scheduled_minute:"30",
        scheduled_meridiem:"am",
        scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
        scheduled_timezone: this.getUserTimezone(),
        is_scheduled: false,
        other_details:{
          post_type:'text-post',
          author:''
        }
      },
      isEdited: false,
      postSubmittedClicked: false,
      schedule_options: [],
      schedule_newoptions: [
        {
          text:
            "Today at " +
            "5 PM",
            value: "5",
        },
        {
          text:
            "Tomorrow at " +
            "9 AM",
            value: "9",
        },
        {
          text:
            "Next Monday at " + "9 AM",
          value: "9:00",
        },
      ],
      timezones: timezones,
      myTimezoneFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.offset.toLowerCase().indexOf(temp) > -1
        );
      },
      remote_file: 'https://fileinfo.com/img/ss/xl/url_181.png',

      previewUrl: null,
      isImage: false,
      fileError:null,
    };
  },
  watch: {
    myFiles(value){
      var temp = [];
      var tempArray = [];
      console.log(value);
      for (let index = 0; index < value.length; index++) {
        const element = value[index];

        if(!temp.includes(element.file.name)){
          temp.push(element.file.name);
          tempArray.push(element);
        }
      }
      if(tempArray.length != value.length){
        this.myFiles = tempArray;
      }
    },
    filterByType(val) {
      bus.$emit("search_on",val);
      if (val != 'event' && val != 'poll') {
        this.getPosts();
        if (val != 'post') {
          this.typeChannel = val;
        }
      }
    },

    'post_form_data.title': {
      handler(newValue, oldValue) {
        this.checkForChanges();
      },
      deep: true 
    },
    'post_form_data.body': {
      handler(newValue, oldValue) {
        this.checkForChanges();
      },
      deep: true
    },
    'post_form_data.scheduled_date': {
      handler(newValue, oldValue) {
        if(this.isValidDate(newValue)){
        var date = moment(newValue);
        var month = date.format('M')-1;
        var day   = date.format('D');
        var year  = date.format('YYYY');
        this.selectedMonth = month;
        this.selectedYear = year;

      }
      },
      deep: true
    },
    'post_form_data.scheduled_timezone': {
      handler(newValue, oldValue) {
        this.schedule_options_update();
      },
      deep: true
    },
    
    
    post_form_data:{
      
      handler(newValue, oldValue) {
        if (this.watchEnabled) {
          // this.saveDraft();
          if(!newValue.is_published || newValue.is_published == 'false'){
            this.startSaveDraftTimer();
          }
        }
      },
      deep: true // Watch for changes in nested properties
    },

    postType(newVal) {
      this.updateToolbarState();
    },

    'post_form_data.other_details.post_type' : function(newValue, oldValue) {
      // This function will be called whenever myData.key1 changes
      this.originalOption = oldValue;
    },

    typeFilter(val) {
      bus.$emit("loadEvents", { type: val });
    },
    '$route.params.id' : function(val){
      this.refreshListData();
    },
    '$route.query': function(val){
      const urlParams = new URLSearchParams(window.location.search);
      const filter = urlParams.get('filter');

      if(filter){
        if(filter == 'draft-posts'){
          this.getPosts('my-draft-posts');
        }
        if(filter == 'scheduled-posts'){
          this.getPosts('my-scheduled-posts');
        }
        if(filter == 'saved-posts'){
          this.getPosts('my-saved-posts');
        }
      }else{
        if(urlParams.get('q')){
          this.searchQuery = urlParams.get('q');
        }else{
          this.searchQuery = "";
        }
        this.refreshListData();
      }
      
    }
    // '$route.query' : function(val){
    //   console.log(this.$route);
    //   if(this.$route.query.filter == 'draft-posts'){
    //     this.getPosts('my-draft-posts');
    //   }
    //   if(this.$route.query.filter == 'scheduled-posts'){
    //     this.getPosts('my-scheduled-posts');
    //   }
    //   if(this.$route.query.filter == 'saved-posts'){
    //     this.getPosts('my-saved-posts');
    //   }
    // }
  },
  beforeCreate() {
    axios.get("communityCollection").then((response) => {
      this.recentPolls = response.data.data.recentPolls;
      this.recentEvents = response.data.data.recentEvents;
    });
  },
  created() {
    bus.$emit("search_on",'post');
    if (!this.userData) {
      this.$router.push({ name: "auth-login" });
    }
    // get id from url    
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.$http
        .get(`/user/${id}`)
        .then((res) => {
          this.profileData = res.data.data;
        });
    } else {
      this.profileData = this.userData;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const filter = urlParams.get('filter');
    const type = urlParams.get('type');
    const search = urlParams.get('q');

    if(filter){
      if(filter == 'draft-posts'){
        this.getPosts('my-draft-posts');
      }
      if(filter == 'scheduled-posts'){
        this.getPosts('my-scheduled-posts');
      }
      if(filter == 'saved-posts'){
        this.getPosts('my-saved-posts');
      }
    }

      if(search){
        this.searchQuery = search
        this.getPosts()
      }
      else{
        this.getPosts();
      }
    this.getPosts('non-published-posts',true);
    this.fetchEmployeeList();
    this.getCustomFields();
    this.fetchChannelList();
    if(type || search){
    this.filterByType = type
    }
    else{
    this.filterByType = this.communityChannels[0].value;
    }

    bus.$on("loadPosts", (params = "") => {
      this.searchQuery = params;
      this.posts = [];
      this.getPosts();
    });

    bus.$on('AddFilter', this.AddFilter);
    
    this.getOnGoingProgram();
    if (this.$route.params.id) {
      this.fetch_user_data(this.$route.params.id);
    }
  },
  computed: {
    yearRange() {
      return Array.from({ length: 81 }, (_, i) => 1970 + i);
    },
    calendarDays() {
      const days = [];
      const firstDay = new Date(this.selectedYear, this.selectedMonth).getDay();
      const daysInMonth = this.getDaysInMonth(this.selectedMonth, this.selectedYear);
      let date = 1;

      for (let i = 0; i < 6; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < firstDay) {
            week.push("");
          } else if (date > daysInMonth) {
            week.push("");
          } else {
            week.push(date);
            date++;
          }
        }
        days.push(week);
      }
      return days;
    },
    emojisNative() {
      return packEmoji;
    },
    pinnedPosts() {
      if (this.posts && this.posts.length) {
        var pinPosts = [];
        this.posts.forEach((post) => {
          if (post.is_pinned == "true") {
            pinPosts.push(post);
          }
        });
        const chunkSize = 2;
        var data = [];
        for (let i = 0; i < pinPosts.length; i += chunkSize) {
          const chunk = pinPosts.slice(i, i + chunkSize);
          data.push(chunk);
        }
        return data;
      }
      return [];
    },

  },
  methods: {
    viewAttachments(data){
      this.popupImages = data.collage_img;
      this.postId = data.hashid;
      this.postData = data;
      this.isPopupVisible = true;
    },
    schedule_options_update() {
      // console.log(this.post_form_data.scheduled_timezone);
      // console.log(this.getUserTimezone());
      // console.log(moment().tz(this.post_form_data.scheduled_timezone));
      // console.log(moment().tz(this.getUserTimezone()));
      // console.log(Omoment(moment().tz(this.post_form_data.scheduled_timezone)).add(1, "days").format());
      // console.log(Omoment(moment().tz(this.getUserTimezone())).add(1, "days").format());
      this.schedule_options =  [
        {
          text:
            "Tomorrow at 8 AM",
          value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).add(1, "days").format("YYYY-MM-DD ") + "08:00",
        },
        {
          text:
            "Tomorrow at 1 PM",
          value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).add(1, "days").format("YYYY-MM-DD ") + "13:00",
        },
        {
          text:
            "Next Monday at 8 AM",
          value: Omoment(moment().tz(this.post_form_data.scheduled_timezone)).day(8).format("YYYY-MM-DD ") + "08:00",
        },
      ];
    },
    optionsSelectedEvent(){
      this.optionsSelected = 1;
      const result = this.extractTimeComponents(this.post_form_data.scheduled_time);
      this.post_form_data.scheduled_hour = ""+result.hour;
      this.post_form_data.scheduled_minute = ""+result.minute;
      this.post_form_data.scheduled_meridiem =""+result.meridiem;
      this.post_form_data.scheduled_date=moment(this.post_form_data.scheduled_time).format("YYYY-MM-DD");
    },
    extractTimeComponents(datetime) {
      // Create a Date object from the given datetime string
      const date = new Date(datetime);

      // Extract the hour and minute
      let hours = date.getHours();
      const minutes = date.getMinutes();

      // Determine the meridiem (AM/PM)
      const meridiem = hours >= 12 ? 'PM' : 'AM';

      // Convert hour from 24-hour format to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      // Pad minutes with leading zeros if necessary
      const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;

      return {
          hour: hours,
          minute: paddedMinutes,
          meridiem: meridiem
      };
    },
    scheduleDateChange(day){
      if (!day) return '';
      var formedDate = this.selectedYear+"-"+(this.selectedMonth+1)+"-"+day
      const result = this.isCurrentOrFutureDate(formedDate, this.post_form_data.scheduled_timezone);
      if (!result){
        this.$toast({
              component: ToastificationContent,
              props: {
                title: "Can't Schedule with Past Date",
                icon: "BellIcon",
                variant: "danger",
                text: "You can only Schedule with Current or Future Date",
              },
            });
      }else{
        this.optionsSelected = 0;
        this.post_form_data.scheduled_time = '';
        this.post_form_data.scheduled_date = this.selectedYear+"-"+(this.selectedMonth+1)+"-"+day;
      }
    },
    next() {
      if (this.selectedMonth === 11) {
        this.selectedYear++;
        this.selectedMonth = 0;
      } else {
        this.selectedMonth++;
      }
    },
    previous() {
      if (this.selectedMonth === 0) {
        this.selectedYear--;
        this.selectedMonth = 11;
      } else {
        this.selectedMonth--;
      }
    },
    jump() {
      // This method is triggered when year or month changes
      alert('handle changes');
    },
    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    isCurrentOrFutureDate(dateString, timezone) {
      // Get the current date in the given timezone
      const currentDate = new Date();
      const currentLocaleDate = new Intl.DateTimeFormat('en-US', { timeZone: timezone }).format(currentDate);
      const currentLocaleDateTime = new Date(currentLocaleDate);

      // Parse the given date in the given timezone
      const givenDate = new Date(dateString);
      const givenLocaleDate = new Intl.DateTimeFormat('en-US', { timeZone: timezone }).format(givenDate);
      const givenLocaleDateTime = new Date(givenLocaleDate);

      // Compare the given date with the current date
      return givenLocaleDateTime >= currentLocaleDateTime;
    },
    dayClass(day) {
      if (!day) return '';
      const selectDate = new Date(this.post_form_data.scheduled_date);
      // console.log(selectDate);
      // console.log(day);
      // console.log(selectDate.getDate());
      // console.log(this.selectedMonth);
      // console.log(selectDate.getMonth());
      // console.log(this.selectedYear);
      // console.log(selectDate.getFullYear());
      return (day == selectDate.getDate() &&
        this.selectedMonth == selectDate.getMonth() &&
        this.selectedYear == selectDate.getFullYear())
        ? 'date-picker selected'
        : 'date-picker';
    },
    startSaveDraftTimer() {
      // Clear previous timer
      clearTimeout(this.saveDraftTimer);
      // Start a new timer to save draft after 5 seconds
      this.saveDraftTimer = setTimeout(() => {
        this.saveDraft();
      }, 4000);
    },
    // saveDraft() {
    //   // Call Vuex action or any other logic to save draft
    //   console.log('Draft saved:', this.post_form_data);
    // },
    // wait(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // },
    saveDraft(){
      if(this.post_form_data.body){
        // await this.wait(5000); // Wait for 5 seconds
        this.formSubmitted(true,true);
        this.isSpinning = true;
        setTimeout(() => {
          this.isSpinning = false;
        }, 1500); // Spin for 1 second
        
        // alert('Called');
      }
    },
    isMoreThanOneYear(dateString) {
      // Parse the input date string to a Date object
      const inputDate = new Date(dateString);
      
      // Get the current date
      const currentDate = new Date();
      
      // Calculate the date one year After from today
      const oneYearAfter = new Date();
      oneYearAfter.setFullYear(currentDate.getFullYear() +1);
      console.log(oneYearAfter);
      // Compare the input date with the date one year after
      return inputDate > oneYearAfter;
    },
    postNow(){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to publish this post now",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.post_form_data.is_scheduled = false;
            this.formSubmitted();
        }
      });
    },
    updateToolbarState(value) {
      if (value === 'text-post') {
        this.toolbarState = {
          textPostToolbar: true,
          imagePostToolbar: false,
          videoPostToolbar: false,
        };
      }
    },

    checkForChanges() {
      const title = this.post_form_data.title.trim();
      const body = this.post_form_data.body.trim();
      this.isEdited = title !== '' || body !== '';
    },
    OpenEdit(data){
      this.post_form_data = data;
      this.$bvModal.show("create-custompop")
    },
    OpenPostNow(data){
      this.post_form_data = data;
      this.$bvModal.show("create-custompop");
      this.postNow();
    },

    handleTypeChange(value) {

     this.updateToolbarState(value)

    if(this.isEdited){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to change the post type , it will be reset the form data",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.post_form_data = {
            title: '',
            channel_type: "community",
            post_anonymously: false,
            feedback_type: "",
            body: "",
            scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
            scheduled_timezone: this.getUserTimezone(),
            scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
            scheduled_hour:"09",
            scheduled_minute:"30",
            scheduled_meridiem:"am",
                is_scheduled: false,
            other_details:{
              post_type:value,
              author:''
            }
          };
          this.myFiles = [];
          this.page2 = false;
        }else{
          this.post_form_data.other_details.post_type = this.originalOption;
        }
      });
    }
    },

    confirmTypeChange() {
  
      // Proceed with changing the dropdown value
      this.showConfirmation = false;
      // Perform any other necessary actions
    },
    cancelTypeChange() {
      // Revert to the original option
      this.post_form_data.other_details.post_type = this.originalOption;
      this.showConfirmation = false;
    },
    closeScheduledPopUP(){
      this.$bvModal.hide("scheduled-postview");
    },
    confirmScheduleCloseButton() {
      this.$bvModal.hide("schedule-timepop");
    },
    confirmCloseButton() {
    
    if(this.isEdited ){
      if(!this.post_form_data.hashid){
        this.$swal({
          title: "Are you sure?",
          text: "You are about to exit this form , if you proceed the form data will be lost.",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn yes-btn",
            cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            
            this.$bvModal.hide("create-custompop");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"09",
              scheduled_minute:"30",
              scheduled_meridiem:"am",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
          }
        });
      }else{
        this.$bvModal.hide("create-custompop");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"09",
              scheduled_minute:"30",
              scheduled_meridiem:"am",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
      }
    }
    else{
      this.$bvModal.hide("create-custompop");
    }
    },
    updateChannel(channel) {
      this.$refs.postadd.changeChannel(channel);
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          this.customFields = res.data;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: error.toString(),
              },
            });
          }
        });
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
          this.$cookies.set("employeeListOptions", this.employeeListOptions);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    checkUser() {
      if (!this.$cookies.get("userData")) {
        this.$router.push("/login");
      }
    },
    fetchChannelList() {

      let data = {};
      data.url = "list/community-channels";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.communityChannels = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    checkin() {
      this.swipeData.type = "In";
      this.swipeData.browser_agent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      this.swipeData.device_type = deviceType;
      axios.post("/swipe/add", this.swipeData).then((response) => {
        this.userData.last_swipe_type = "In";
        this.$cookies.set(
          "userData",
          this.userData,
          60 * process.env.VUE_APP_LOGIN_TIMEOUT
        );
        this.$nextTick(() => {
          this.$bvModal.hide("modal-1");
        });
        this.$toast.success(response.data.message);
      });
    },
    checkout() {
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';

      axios
        .post("/swipe/add", {
          type: "Out",
          user_feeling: this.swipeData.user_feeling,
          browser_agent: navigator.userAgent,
          device_type: deviceType,
        })
        .then((response) => {
          this.userData.last_swipe_type = "Out";
          this.$cookies.set(
            "userData",
            this.userData,
            60 * process.env.VUE_APP_LOGIN_TIMEOUT
          );
          this.$nextTick(() => {
            this.$bvModal.hide("modal-2");
          });
          this.$toast.success(response.data.message);
        });
    },
    resetModal() {
      this.swipeData.type = "";
      this.swipeData.user_feeling = 6;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.checkin();
    },
    getRecentSwipes() {
      axios.get("/swipes").then((response) => {
        if (response.data.success) {
          this.recentSwipes = response.data.data;
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    visibilityChanged(isVisible, entry, customArgument) {
      if (isVisible) {
        this.isVisible = isVisible;
        this.getPostData(entry.target.id);
      }
    },
    getPostData(itemId) {
      if (!itemId) {
        return true;
      }

      let data = {};
      data.url = "/posts/" + itemId;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.post_data = res.data.data;
          this.isBusy = false;
          if (res.data.channels) {
            this.unseenBadge = res.data.channels;
          }
          for (let index = 0; index < this.posts.length; index++) {
            var post = this.posts[index];
            if (post.hashid == this.post_data.hashid) {
              this.posts[index] = this.post_data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOnGoingProgram() {

      let data = {};
      data.url = "on-going-secret-santa";
      this.$store.dispatch("app/get", data).then((response) => {
        if (response.data.success) {
          this.onGoingProgram = response.data.data;
        }
      });
    },
    shareUrl(hash_id) {
      return window.location.origin + "?post_hash_id=" + hash_id;
    },
    onCopy: function (e) {
      this.$toast.success("You just copied URL to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the URL to the clipboard");
      console.log(e);
    },
    OpenPost(post) {
      return window.open("?post_hash_id=" + post.id, "_blank");
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    refreshListData() {
      if (this.filterByType == "event") {
        bus.$emit("loadEvents");
      } else if (this.filterByType == "poll") {
        bus.$emit("loadPolls", "");
      } else {
        this.getPosts();
      }
    },
    loadMore() {
      this.tempFlag = false;
      if (this.currentPage < this.lastPage) {
        const urlParams = new URLSearchParams(window.location.search);
      const filterType = urlParams.get('filter');
      var filter = null;
      if(filterType){
        if(filterType == 'draft-posts'){
          filter = 'my-draft-posts';
        }
        if(filterType == 'scheduled-posts'){
          filter = 'my-scheduled-posts';
        }
        if(filterType == 'saved-posts'){
          filter = 'my-saved-posts';
        }
      }
        this.isPostLoading = true;
        let params = {};
        
        params = {
          page: this.nextPage,
          channel_type: this.filterByType,
          q: this.searchQuery,
          createdBy: this.$route.params.id,
          filter:filter
        };
        this.$http
          // .get(`/posts?page=${this.nextPage}&channel_type=${this.filterByType}`)
          .get(`/posts`, { params: params })
          .then((res) => {
            this.posts = this.posts.concat(res.data.data);

            const ids = this.posts.map((o) => o.hashid);
            const filtered = this.posts.filter(
              ({ hashid }, index) => !ids.includes(hashid, index + 1)
            );
            this.posts = filtered;

            if (res.data.meta.current_page < res.data.meta.last_page) {
              this.nextPage = res.data.meta.current_page + 1;
            }
            this.lastPage = res.data.meta.last_page;
            this.currentPage = res.data.meta.current_page;
            this.isPostLoading = true;
            if(res.data.meta.total > 0 && this.lastPage  == this.currentPage){
              this.isPostLoading = false;
              this.reachedLastPost = true;
            }
            else if(res.data.meta.total == 0){
              this.isPostLoading = false;
              this.reachedLastPost = false;
            }
            else{
              this.isPostLoading = true;
              this.reachedLastPost = false;

            }
          })
          .catch((error) => {
            this.isPostLoading = true;
          });
      }

    },
    AddFilter(type) {
      if(type !== null){
      this.filterByType = type;
      var nonChannels = ['poll', 'event', 'post']
      if (!nonChannels.includes(type)) {
        this.$emit('updateChannel', type);
      }
    }
    },
    editComment(comment, post_id, index) {

      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.updatePostArray(res.data.data, index);
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    getPosts(filter = null,notForList = false){

      this.isPostLoading = true;
      let data = {};
      data.url = "posts";
      const urlParams = new URLSearchParams(window.location.search);
      const filterType = urlParams.get('filter');
      const employeeId = urlParams.get('employee_hash_id');
      if(filterType){
        if(filterType == 'draft-posts'){
          filter = 'my-draft-posts';
        }
        if(filterType == 'scheduled-posts'){
          filter = 'my-scheduled-posts';
        }
        if(filterType == 'saved-posts'){
          filter = 'my-saved-posts';
        }
      }

      data.params = {
        channel_type: this.filterByType,
        q: this.searchQuery,
        createdBy: this.$route.params.id ? this.$route.params.id : employeeId ,
        filter:filter,
      };
      this.$store.dispatch("app/get", data).then((res) => {
        if(notForList == false){
          this.posts = res.data.data;
          this.isPostLoading = false;
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.currentPage = res.data.meta.current_page;
          this.unseenBadge = res.data.unseen_post_count;
          if(res.data.meta.total > 0 && this.lastPage  == this.currentPage){
              this.isPostLoading = false;
              this.reachedLastPost = true;
            }
            else if(res.data.meta.total == 0){
              this.isPostLoading = false;
              this.reachedLastPost = false;
            }else{
              this.isPostLoading = true;
              this.reachedLastPost = false;

            }

          bus.$emit('postsLoaded', this.posts);
        }else{
          if(filter && filter == 'non-published-posts'){
            this.nonPublishedPosts = res.data.data;
          }
        }
      });
    },
    filteredList() {
      if (!this.userSearch.length) {
        return [];
      }
      return this.employeeListOptions.filter((user) =>
        user.label.toLowerCase().includes(this.userSearch.toLowerCase())
      );
    },

    fetchChannelList() {

      let data = {};
      data.url = "list/community-channels";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.communityChannels = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    getPostReactions(postID) {
      this.postLikes = null;
      this.$http.get(`/posts/${postID}/likes`).then((res) => {
        this.postLikes = res.data.data;
      });
    },
    append(emoji) {
      var data = document.getElementById(this.inputId).value;
      var lastTag = data.substring(data.length - 4, data.length);
      if (lastTag == "</p>") {
        data = data.substring(0, data.length - 4) + emoji + lastTag;
        document.getElementById(this.inputId).value = data;
      } else {
        document.getElementById(this.inputId).value += emoji;
      }
    },
    setInputId(inputId) {
      this.inputId = inputId;
    },
    
    kFormatter,

    viewPost(postData) {
      this.postId = postData.hashid;
      this.postData = postData;
      this.isPopupVisible = false;
    },
    addReply(data, index) {

      this.comment_id = data.hashid;
      this.reply_to = data.user.name;
      this.comment_post_index = index;
      document.getElementById("comment-field-" + index).focus();
    },
    removeReply() {

      this.comment_id = null;
      this.reply_to = null;
    },
    like(comment_id, index) {

      let data = {};
      data.url = "comments/" + comment_id + "/like";
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.updatePostArray(res.data.data, index);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "liking failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          this.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment deleted successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        });
    },
    addComment(data, index) {

      const comment = document.getElementById(`comment-field-${index}`).value;

      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: this.comment_id,
      };
      this.$http
        .post(`/posts/${data.hashid}/comments`, params)
        .then((res) => {
          this.reply_to = null;
          this.comment_id = null;
          this.updatePostArray(res.data.data, index);
          document.getElementById(`comment-field-${index}`).value = "";
          $(".ql-editor").html("");
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    likePost(data, index, type) {
      if (data.youLiked && data.likeType == type) {
        this.$http.delete(`/posts/${data.hashid}/like`).then((res) => {
          this.updatePostArray(res.data.data, index);
        });
      } else {
        this.$http.post(`/posts/${data.hashid}/like/${type}`).then((res) => {
          this.updatePostArray(res.data.data, index);
        });
      }
    },
    savePost(data, index) {
      if (data.youSaved) {
        this.$http.delete(`/saved-models/${data.hashid}/unsave`).then((res) => {
          this.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post removed from saved posts",
              icon: "XCircleIcon",
              variant: "danger",
            },
          });
        });
      } else {
        this.$http.post(`/saved-models/${data.hashid}/save`).then((res) => {
          this.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post added to saved posts",
              icon: "CheckCircleIcon",
              variant: "primary",
            },
          });
        });
      }
    },
    updatePostArray(data, index) {
      this.$set(this.posts, index, data);
    },
    confirmDeleteRecord(data) {
      this.$swal({
        // title: "Are you sure?",
        // text: `You want to delete this post ${data.title }.`,
        text: `Deleting this Post can not be undone. Are you sure you want to continue?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },

    deleteRecord(id) {
      let data = {};
      data.url = "posts/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
          const element = document.getElementById("post-card-" + id);
            if (element) {
              document.getElementById("post-card-" + id).remove();
            }
            
            this.$bvModal.hide("create-custompop");
            this.$bvModal.hide("schedule-timepop");
            bus.$emit("loadPosts");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"09",
              scheduled_minute:"30",
              scheduled_meridiem:"am",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
            this.postSubmittedClicked = false;
            this.getPosts('non-published-posts',true);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Post Deleted Successfully.",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          // this.refetchData();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Deletion failed.1",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    onSelected(option) {
      this.selected = option.item;
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.data[0].developers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredDesigner = this.datasuggest.data[0].designers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredData = filteredDevelopers.concat(filteredDesigner);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },


  postPublicURL(data) {
    let payLoad = {
      post_id: data.hashid,
    };
  this.$http
  .post(`/posts/post_public_url`, payLoad)
  .then((res) => {

    this.boxOne = '';
    const modalContent = this.$createElement('div', [
  this.$createElement('p', 'Ready to share? Copy the link to this post. Your organization and personal details will remain confidential.'),
  this.$createElement('div', { style: 'border: 1px solid #E6E6E6;display:flex; border-radius: 48px; align-items: center; padding: 3px 3px 3px 15px; margin-bottom: 20px;' }, [
  this.$createElement('strong', `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`),,
    this.$createElement('button', {
      class: 'btn btn-primary copy-btn',
      on: {
        click: () => {
          const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
          navigator.clipboard.writeText(copyText); // Copy text to clipboard
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: 'Link copied to clipboard!',
            },
          });
        },
      },
    }, 'Copy Link'),
  ]),
]);

    this.$bvModal.msgBoxConfirm(
      modalContent,
  {
    title: "Generate Public URL",
    cancelVariant: 'outline-secondary',
    centered: true, // Center the popup
    buttonSize: 'md',      
    hideHeaderClose: false,
    hideFooter: true,
    modalClass: 'public-modal',
    footerClass: 'd-none mb-1',
    hide: true 
  }
).then((value) => {
      // if (value) {
      //   // Perform copy action here
      //   const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
      //   navigator.clipboard.writeText(copyText); // Copy text to clipboard
      //   this.getPosts();
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Success',
      //       icon: 'BellIcon',
      //       variant: 'success',
      //       text: 'link copied to clipboard!',
      //     },
      //   });
      // }
    });
  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
          text: err.toString(),
        },
      });
    }
  });

},
postRevokePublicURL(data){
  this.$bvModal.msgBoxConfirm(
    'you want to revoke the public link for this post? By confirming, the link will be revoked from all places you shared it.',
    {
      title: 'Are you sure ? ',
      size: 'md',
      okVariant: 'danger',
      okTitle: 'revoke',
      cancelTitle: 'cancel',
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      hideFooter: true,
      centered: true,
      headerClass: 'bg-transparent',
      modalClass: 'public-modal',
      footerClass: 'modal-footer',
    }
  ).then((value) => {
      if (value) {
        this.$http
   .delete(`/posts/revoke_public_url/${data.hashid}`)
   .then((res) => {
    this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: res.data.message,
          },
        });
      this.getPosts();

  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
          text: err.toString(),
        },
      });
    }
  });
      }
    });
 
},
copyPublicURL(data){
  const copyText = `${window.location.href.split('?')[0]}public-post/${data}`;
        navigator.clipboard.writeText(copyText); // Copy text to clipboard
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: 'link copied to clipboard!',
          },
        });
},
  pinPost(data) {
      axios.get("/pin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Pinned",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to pin post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },

    unpinPost(data) {
      axios.get("/unpin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Un-Pined",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to unpin post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    getMosReactions(likes) {
      var reactions = {
        like: 0,
        love: 0,
        support: 0,
        clap: 0,
        smile: 0,
        idea: 0,
      };

      likes.forEach((like) => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter((key) => reactions[key] !== 0);

      return top3;
    },
    approvePost(data) {
      axios.get("post/approve/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Approved",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to Approve post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },

    rejectPost(data) {
      axios.get("post/reject/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Reject",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to Reject post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    markAsRead(type) {
      axios.get("/markAsRead/" + type).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Posts marked as read",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to mark posts as read",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    fetch_user_data(userId) {

      this.isLoading = true;
      let formData = {};
      formData.url = "profile/" + userId;
      this.$store
        .dispatch("app/get", formData)
        .then((res) => {
          this.profileData.header = res.data.data.header;
          this.profileData.userAbout = res.data.data.userAbout;
          this.profileData.teamMates = res.data.data.teamMates;
          this.profileData.events = res.data.data.events;
          this.profileData = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee data failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    addOption() {
      this.pollData.options.push("");
    },
    removeOption(index) {
      this.pollData.options.splice(index, 1);
    },
    deleteFile(file, key) {
      this.myFiles.splice(key, 1);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "File Removed",
          icon: "BellIcon",
          variant: "success",
          text: file.name + " File Removed from the List",
        },
      });
    },
    getTittleCase(string) {
      if(!string){
        return;
      }
      var final = string.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

      return final;
    },
    getScheduledTimeFormated(date, hour, minute, meridiem) {
      // Parse the input date string to a Date object
      const parsedDate = new Date(date);
      var final = moment(date).format("YYYY-MM-DD")
      // Convert hour to 24-hour format
      let adjustedHour = parseInt(hour, 10);
      if (meridiem.toLowerCase() === 'pm' && adjustedHour !== 12) {
          adjustedHour += 12;
      } else if (meridiem.toLowerCase() === 'am' && adjustedHour === 12) {
          adjustedHour = 0;
      }

      // Set the parsed date with the given hour and minute
      parsedDate.setHours(adjustedHour, parseInt(minute, 10), 0, 0);

      // Format the date to YYYY-MM-DD HH:mm
      const formattedDate = parsedDate.toISOString().slice(0, 10); // Get YYYY-MM-DD
      const formattedTime = parsedDate.toTimeString().slice(0, 5); // Get HH:mm
      
      return `${final} ${formattedTime}`;
    },
    isPast(dateTimeStr, timeZone) {
      // Create the date-time object from the given date-time string
      const givenDateTime = new Date(dateTimeStr);

      // Get the current date-time in the specified timezone
      const now = new Date();
      const currentDateTimeStr = now.toLocaleString('en-US', { timeZone, hour12: false });
      const [date, time] = currentDateTimeStr.split(', ');
      const [month, day, year] = date.split('/');
      const [hour, minute, second] = time.split(':');

      // Create the current date-time object in the specified timezone
      const currentDateTime = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`);

      // Compare the given date-time with the current date-time
      return givenDateTime < currentDateTime;
    },
    isValidDate(dateString) {
      try {
        const valid = moment(dateString).isValid();
        return valid;
      } catch (error) {
        return false;
      }
    },
    Validate(){
      var invalid = false;
      if(this.post_form_data.other_details.post_type == 'text-post'){
        if(!this.post_form_data.body){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Dcscription is required",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
          invalid = true;
        }
      }
      if(this.post_form_data.other_details.post_type == 'photo-post' || this.post_form_data.other_details.post_type == 'video-post'){
        if(!this.myFiles.length && this.post_form_data.attachments && this.post_form_data.attachments.length == 0){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "File is required",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
          invalid = true;
        }
      }
      if(this.post_form_data.other_details.post_type == 'quote-post'){
        if(!this.post_form_data.body){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Quote is required",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
          invalid = true;
        }
      }
      if((!this.post_form_data.is_published || this.post_form_data.is_published == 'false') && (this.post_form_data.is_scheduled == 'true' || this.post_form_data.is_scheduled === true)){
        if(this.isValidDate(this.post_form_data.scheduled_date)){
          var dateTime = this.getScheduledTimeFormated(this.post_form_data.scheduled_date,this.post_form_data.scheduled_hour,this.post_form_data.scheduled_minute,this.post_form_data.scheduled_meridiem);
          if(this.isPast(dateTime,this.post_form_data.scheduled_timezone)){
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "The given date-time is in the past.",
                  icon: "ErrorIcon",
                  variant: "danger",
                },
              });
              invalid = true;
          }
          if(this.isMoreThanOneYear(this.post_form_data.scheduled_date)){
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "You cannot schedule the post more than one year in advance.",
                  icon: "ErrorIcon",
                  variant: "danger",
                },
              });
              invalid = true;
          }
        }
        if(!this.isValidDate(this.post_form_data.scheduled_date)){
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "The given date is invalid.",
                icon: "ErrorIcon",
                variant: "danger",
              },
            });
            invalid = true;
        }
      }

      return invalid;
    },
    formSubmitted(draft = false,auto = false) {
      var inValid = this.Validate();
      if(inValid && inValid == true){
        return;
      }
      if(this.isValidDate(this.post_form_data.scheduled_date) && this.post_form_data.scheduled_date != moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD")){
        this.post_form_data.scheduled_date = moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD");
      }
      if(draft == false){
        this.postSubmittedClicked = true;
      }
      const formData = new FormData();

      // return;
      // const files = this.$refs.pond.getFiles();
      // const keys = Object.keys(this.$refs.pond.getFiles());

      for (let i = 0; i < this.myFiles.length; i += 1) {
        formData.append(`files[${i}]`, this.myFiles[i].file);
      }

      // const files = self.$refs.pond.files;
      // const keys = Object.keys(self.$refs.pond.files);
      // for (let i = 0; i < keys.length; i += 1) {
      //   formData.append(`files[${i}]`, files[i]);
      // }
      
      
      
      formData.append("channel_type", this.typeChannel);
      formData.append("feedback_type", this.post_form_data.feedback_type);
      formData.append("body", this.post_form_data.body);
      if(this.optionsSelected == 0){
        formData.append("scheduled_time", this.getScheduledTimeFormated(this.post_form_data.scheduled_date,this.post_form_data.scheduled_hour,this.post_form_data.scheduled_minute,this.post_form_data.scheduled_meridiem));
      }else{
        formData.append("scheduled_time", this.post_form_data.scheduled_time);
      
      }
      formData.append("scheduled_timezone", this.post_form_data.scheduled_timezone);
      formData.append("is_scheduled", this.post_form_data.is_scheduled);
      formData.append("files", this.myFiles);
      formData.append("author", this.post_form_data.other_details.author);
      formData.append("post_type", this.post_form_data.other_details.post_type);
      if(this.post_form_data.other_details.post_type == 'photo-post' || this.post_form_data.other_details.post_type == 'video-post'){
        if(this.myFiles.length == 0 && (!this.post_form_data.attachments || this.post_form_data.attachments.length == 0)){
          var type = (this.post_form_data.other_details.post_type == 'photo-post')?'Photo':'Video';
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "File Missing",
                icon: "BellIcon",
                variant: "danger",
                text: "Please attach the "+type+" file.",
              },
            });
          this.postSubmittedClicked = false;
          return;
        }
      }
      if(this.post_form_data.hashid){
          formData.append("hashid", this.post_form_data.hashid);
        }
      if (draft) {
        formData.append("is_draft", true);
        formData.append("is_published", false);
      } else if (this.post_form_data.is_scheduled) {
        formData.append("is_scheduled", true);
        formData.append("is_published", false);
      } else {
        formData.append("is_draft", false);
        formData.append("is_scheduled", false);
        formData.append("is_published", true);
      }
      if(this.post_form_data.is_scheduled && !this.post_form_data.scheduled_timezone){
        
      formData.append("scheduled_timezone", this.getUserTimezone());
      }

      if(!this.post_form_data.title && this.post_form_data.other_details.post_type == 'text-post'){
        if(!draft && (this.post_form_data.is_scheduled ||  this.post_form_data.is_published)){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post title is required",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
          return ;
        }
        formData.append("title", "(--No Title--)");
      }else{
        formData.append("title", this.post_form_data.title);
      }

      let data = {};
      data.url = "posts";
      this.post_form_data.files = this.myFiles;
      data.params = formData;
      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          if(!auto){
            this.isBusy = false;
            this.$bvModal.hide("create-custompop");
            this.$bvModal.hide("schedule-timepop");
            bus.$emit("loadPosts");
            this.post_form_data = {
              title: '',
              channel_type: "community",
              post_anonymously: false,
              feedback_type: "",
              body: "",
              scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
              scheduled_timezone: this.getUserTimezone(),
              scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
              scheduled_hour:"09",
              scheduled_minute:"30",
              scheduled_meridiem:"am",
              is_scheduled: false,
              other_details:{
                post_type:'text-post',
                author:''
              }
            };
            this.myFiles = [];
            this.page2 = false;
            this.postSubmittedClicked = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Added",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            bomb.realistic();
          }else{
            this.postSubmittedClicked = false;
            this.watchEnabled = false;
            //this.post_form_data = res.data.data;
            if(!this.post_form_data.hashid || this.post_form_data.hashid == null ){
              // Update json1 values with json2 values, excluding the specified key
              for (let key in res.data.data) {
                  if (res.data.data.hasOwnProperty(key) && key !== 'body') {
                    this.post_form_data[key] = res.data.data[key];
                  }
              }
            }
            setTimeout(() => {
              this.watchEnabled = true;
            }, 0);
            this.getPosts('non-published-posts',true);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Changes Saved",
            //     icon: "BellIcon",
            //     variant: "primary",
            //     text: "Post Auto Saved as Draft",
            //   },
            // });
          }       
        })
        .catch((error) => {
          this.isBusy = false;
          this.postSubmittedClicked = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Post Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },

    openWindow(link) {
      window.open(link);
    },
    formUpdate(draft = false) {
      var inValid = this.Validate();
      if(inValid && inValid == true){
        return;
      }
      if(this.isValidDate(this.post_form_data.scheduled_date) && this.post_form_data.scheduled_date != moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD")){
        this.post_form_data.scheduled_date = moment(this.post_form_data.scheduled_date).format("YYYY-MM-DD");
      }
      this.postSubmittedClicked = true;
      const formData = new FormData(document.getElementById("post-form"));
      formData.append("channel_type", this.post_form_data.channel_type);
      formData.append("title", this.post_form_data.title);
      formData.append("feedback_type", this.post_form_data.feedback_type);
      formData.append("body", this.post_form_data.body);;
      formData.append("is_scheduled", this.post_form_data.is_scheduled);
      if(this.post_form_data.is_scheduled == true){
        formData.append("scheduled_time", this.getScheduledTimeFormated(this.post_form_data.scheduled_date,this.post_form_data.scheduled_hour,this.post_form_data.scheduled_minute,this.post_form_data.scheduled_meridiem));
        formData.append("scheduled_timezone", this.post_form_data.scheduled_timezone);
      }
      formData.append("files", this.myFiles);
      formData.append("author", this.post_form_data.other_details.author);
      formData.append("post_type", this.post_form_data.other_details.post_type);
      formData.append("_method","PATCH");

      formData.delete("files[]");
      for (let i = 0; i < this.myFiles.length; i += 1) {
        formData.append(`files[${i}]`, this.myFiles[i].file);
      }

      if(this.post_form_data.is_scheduled && !this.post_form_data.scheduled_timezone){
        
        formData.append("scheduled_timezone", this.getUserTimezone());
        }
      if (draft == true) {
        formData.append("is_draft", true);
        formData.append("is_published", false);
      } else if (this.post_form_data.is_scheduled == true && draft == true) {
        formData.append("is_scheduled", true);
        formData.append("is_draft", true);
        formData.append("is_published", false);
      } else {
        formData.append("is_draft", false);
        formData.append("is_published", true);
      }
      if (!this.post_form_data.is_scheduled) {
        this.$swal({
          title: "Would you like to send update emails to Users?",
          // text: `You want to delete.`,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Send",
          cancelButtonText: "Don't send",
          customClass: {
            confirmButton: "btn yes-btn",
            cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            formData.append("sendNotifications", true);
            this.updatePostAPI(formData);
          } else {
            formData.append("sendNotifications", false);
            this.updatePostAPI(formData);
          }
        });
      } else {
        formData.append("sendNotifications", false);
        this.updatePostAPI(formData);
      }
    },
    updatePostAPI(formData){
      const self = this;
      let data = {};
      data.url = "posts/" + this.post_form_data.hashid;
      this.post_form_data._method = "PATCH";
      data.params = formData;
      this.isBusy = true;
      this.$store
      .dispatch("app/update", data)
      .then((res) => {
        this.isBusy = false;
        this.$bvModal.hide("create-custompop");
        bus.$emit("loadPosts");
        this.post_form_data = {
          title: '',
          channel_type: "community",
          post_anonymously: false,
          feedback_type: "",
          body: "",
          scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
          scheduled_timezone: this.getUserTimezone(),
          scheduled_date:moment().add(1, "days").format("YYYY-MM-DD"),
          scheduled_hour:"09",
          scheduled_minute:"30",
          scheduled_meridiem:"am",
          is_scheduled: false,
          other_details:{
            post_type:'text-post',
            author:''
          }
        };
        this.myFiles = [];
        this.page2 = false;
        this.postSubmittedClicked = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Post Updated",
            icon: "BellIcon",
            variant: "success",
            text: res.data.message,
          },
        });
        bomb.realistic();
      })
      .catch((error) => {
        this.isBusy = false;
        this.postSubmittedClicked = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Oops! Post Updating Failed",
            icon: "BellIcon",
            variant: "danger",
            text: error,
          },
        });
      });
    },
    confirmDeleteFile(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeAttachment(uuid);
        }
      });
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "posts/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          if(res.data.data){
            this.watchEnabled = false;
            this.post_form_data = res.data.data;
            setTimeout(() => {
              this.watchEnabled = true;
            }, 0);
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deleted",
              icon: "BellIcon",
              variant: "success",
              text: "Attachment Deleted Successfully.",
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getUserTimezone() {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
      return timezone;
    },
    async checkAccess(url, type) {
      try {
        const response = await fetch(url);
        if (type == 'photo') {
          validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
        } else if (type == 'video') {
          validTypes = [
            'video/mp4',
            'video/quicktime',
            'video/avi',
            'video/mov',
            'video/mkv',
            'video/wmv',
            'video/flv',
            'video/webm',
            'video/3gpp',
            'video/mpeg',
            'video/ts',
            'video/vob',
            'video/rm',
            'video/rmvb',
            'video/ogv',
            'video/m4v',
            'video/m2ts',
            'video/asf',
            'video/mxf'
          ];
        }

        // Check if the response status is OK (2xx status codes)
        if (response.ok) {
          const contentType = response.headers.get('Content-Type');

          // Check if the Content-Type indicates a valid file type (e.g., image, application/pdf, etc.)
          if (validTypes.includes(contentType)) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Valid File URL",
                icon: "BellIcon",
                variant: "success",
                text: "File is Accessable and Valid Type",
              },
            });
          } else {
            console.error('Invalid Content-Type: ' + contentType);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Invalid File URL",
                icon: "BellIcon",
                variant: "danger",
                text: "Invalid Content-Type , it should be " + type,
              },
            });
          }
        }
        else {
          console.error('Request failed with status: ' + response.status);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Invalid File URL",
              icon: "BellIcon",
              variant: "danger",
              text: "Request failed with status: " + response.status,
            },
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    showVisits(postId) {

    this.postViews = null;
    var modal = "show-visits-" + postId;
    this.$bvModal.show(modal);
    let data = {};
    data.url = "/post-visits/" + postId;
    this.isBusy = true;
    this.$store
      .dispatch("app/get", data)
      .then((res) => {
        this.postViews = res.data.data;
        this.isBusy = false;
      })
      .catch((error) => {
        this.$bvModal.hide(modal);
      });
    },
    getSrc(type) {
      if(this.myFiles && this.myFiles[0] ){
        if(this.myFiles[0].type.includes(type)){
          this.fileError = null;
          // Create a URL for the file object
          this.previewUrl = window.URL.createObjectURL(this.myFiles[0].file);
        }else{
          this.myFiles = [];
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Invalid File Type",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: "Please Upload "+type+ " type file",
          //   },
            
          // });        
          
          this.fileError = "Please Upload "+type+ " type file";  
        }
      }
    },
    openFile(file){
      
      this.openWindow(window.URL.createObjectURL(file.file));
    },
    onSelectEmoji(dataEmoji) {
      // this.data_local.title = this.data_local.title + dataEmoji.emoji;
      const input = document.getElementById('title');
      const cursorPosition = input.selectionStart;
      const currentValue = input.value;
      const smiley = dataEmoji.emoji;  // You can replace this with any smiley you want

      const newValue = currentValue.slice(0, cursorPosition) + smiley + currentValue.slice(cursorPosition);
      input.value = newValue;
      this.data_local.title = newValue;

      // Set the cursor position after the inserted smiley
      input.selectionStart = cursorPosition + 2;  // 2 is the length of the smiley
      input.selectionEnd = cursorPosition + 2;
      this.toogleDialogEmoji();
    },
    toogleDialogEmoji($event = null) {
      if ($event) {
        $event.preventDefault();
      }
      this.dialogHidden = !this.dialogHidden;
    },
    generate_year_range(start, end) {
      var years = "";
      for (var year = start; year <= end; year++) {
          years += year;
      }
      return years;
    },

  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  mounted() {
    this.schedule_options_update();
    axios.get("/user").then((response) => {
      const userData = response.data.data;
      this.$cookies.set(
        "userData",
        userData,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      );
    });

    if (this.userData.previous_day_attendance_status.NO &&
      (!this.$cookies.get("lastday_status_shown") ||
        this.$cookies.get("lastday_status_shown") != 1)
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "You have not fulfilled min hours required for " +
              this.userData.previous_day_attendance_status.No,
            icon: "AlertTriangleIcon",
            variant: "warning",
            text: "Regularization Required for last working day, Please Apply for Regularization for last working day",
          },
        },
        {
          timeout: 6000,
        }
      );
      this.$cookies.set("lastday_status_shown", 1, 60 * process.env.VUE_APP_LOGIN_TIMEOUT);
    }
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > 3000) {
        $(".tweets").addClass("active-sticky");
      } else {
        $(".tweets").removeClass("active-sticky");
      }
    });
    /* Navbar Header hide when page scroll */
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 280) {
        document.getElementById("navbar").style.display = "none";
        $('#app').addClass('nav-active');
      } else {
        document.getElementById("navbar").style.display = "initial";
        $('#app').removeClass('nav-active')
      }
    }
    /* Page scroll Community Nav Sticky */
        $(document).ready(function() {
       if($("#user-profile") && $(".profile-header")){
         var stickyTop = $('.profile-header').offset().top;
         $(window).scroll(function() {
           var windowTop = $(window).scrollTop();
           if (stickyTop < windowTop && $("#user-profile").height() + $("#user-profile").offset().top - $(".profile-header").height() > windowTop) {
             $('.profile-header').css('position', 'sticky');
             $('.profile-header').addClass('sticky-nav');
           } else {
             $('.profile-header').css('position', 'relative');
             $('.profile-header').removeClass('sticky-nav');
           }
         });
         $(".click-view").click(function () {
           $("body").toggleClass("hidden");
         });

       }
     });
    if (this.$route.query.post_hash_id) {
      this.postId = this.$route.query.post_hash_id;
    }
  },

};
</script>
  
<style lang="scss" >
@import "@core/scss/vue/pages/page-profile.scss";
@media(min-width: 992px) {
  #app.nav-active .header-navbar-shadow {
    display: none;
    transition: all 0.5s ease;
  }
  #app.nav-active .navbar-light {
    z-index: 1;
  }
  #app.nav-active .navbar-light {
    background: transparent;
    box-shadow: 0 0 0 0 transparent;
    transition: all 0.5s ease;
  }
  #app.nav-active  .profile-header.sticky-nav {
    top: -200px;
    z-index: 10;
  }
  #app.nav-active  .profile-header.sticky-nav .profile-img-container {
    display: none !important;
  }
  #app.nav-active .profile-header.sticky-nav .profile-header-nav .profile-tabs {
    margin-left: 0;
  }
}
@media(max-width: 992px) {
  #app.nav-active .profile-header.sticky-nav .profile-header-nav .profile-tabs ul.nav.nav-tabs .d-flex.w-100 {
    flex-direction: row !important;
  }
  #app.nav-active .profile-header.sticky-nav .profile-header-nav .profile-tabs .community-tabs {
    order: 1;
    margin-top: 5px;
  }
  #app.nav-active .profile-header.sticky-nav .profile-header-nav .profile-tabs .ref-info {
    order: 2;
    margin-top: 10px;
  }
}
@media(max-width: 991px) {
  #app.nav-active  #navbar {
    display: block !important;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000; /* Ensure buttons appear above carousel */
  /* Customize positioning as needed */
}
.carousel-control-prev {
  left: -42px; /* Adjust positioning from left */
  color: black !important;
}
.carousel-control-next {
  right: -42px; /* Adjust positioning from right */
}
.carousel-control-prev-icon{
  color: black;
  background-color: black;
}
.carousel-control-next-icon{
  color: black;
  background-color: black;
}

.fa-sync {
  font-size: 50px;
  transition: transform 1s ease;
  cursor: pointer;
}

.fa-spin {
  transform: rotate(360deg);
}

</style>
  