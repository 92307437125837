<template>
  <b-sidebar
    id="add-sidebar-view"
    ref="sidebar_view"
    bg-variant="white"
    sidebar-class="sidebar-lg post-card modal-responsive-width"
    shadow
    backdrop
    no-header
    right
    @shown="getPostData(itemId)"
    @hidden="resetFormData()"
    @change="(val) => $emit('update:sidebarViewActive', val)"
    width="60%"
    :visible="show"
  >
    <template  #default="{ hide }"> 
      <!-- Header -->
      <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <b-card class="shadow-none bottom-body">
        <div
          class="d-flex justify-content-between align-items-center mb-50 post-card post-card-header"
        >
          <div class="d-flex justify-content-start align-items-center">
            <!-- avatar -->
            <b-avatar
              :src="post_data.user.avatar"
              style="background: transparent"
              size="48"
              class="mr-1"
            />
            <!--/ avatar -->
            <div class="profile-user-info">
              <h6 class="mb-0">
                {{ post_data.user.name }}
              </h6>
              <small class="text-muted">
                {{ post_data.user.designation }}
              </small>
            </div>
          </div>

          <div class="d-none d-md-block ml-auto" v-if="post_data.is_published == 'true'">
            {{ post_data.created_at | formatDate }}
          </div>
          <div class="d-none d-md-block ml-auto" v-if="post_data.is_scheduled == true &&
          post_data.is_published == 'false'">
          <b-badge class="d-none d-md-flex schedule-badge" variant="light-info" v-if="post_data.is_scheduled == true && post_data.is_published == 'false'">Scheduled : {{ post_data.created_at}} </b-badge>
          </div>
          
          <b-badge class="m-50 ml-auto" v-if="post_data.is_approved == 'false' &&
            post_data.is_published == 'true'
            " pill variant="light-danger">Need Approval</b-badge>

          <b-badge v-if="post_data.is_draft == 'true'" class="ml-auto"
            variant="light-secondary">Draft</b-badge>
          <b-img v-if="post_data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin-24px.png')"
            size="24" class="ml-1 pin-icon" style="background-color: transparent"
            v-b-tooltip.hover.v-default title="Post is pinned by admin" />
          <b-dropdown class="blog-dropdown" variant="link" size="sm"
            toggle-class="text-decoration-none" no-caret  v-if="post_data.is_published == 'true'">
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16"
                class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                title="More Options" />
            </template>

            <b-dropdown-item class="btn-sm p-0 text-muted"
              v-if="$can('Zircly Admin') && post_data.is_pinned == 'false'" @click="pinPost(post_data)">
              <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                style="background-color: transparent" />
              <span class="">Pin Post</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted"
              v-if="$can('Zircly Admin') && post_data.is_pinned == 'true'" @click="unpinPost(post_data)">
              <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                style="background-color: transparent" />
              <span class="">Un-Pin Post</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && post_data.is_public == false" @click="postPublicURL(post_data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Generate Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && post_data.is_public == true" @click="postRevokePublicURL(post_data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/revoke-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Revoke Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && post_data.is_public == true" @click="copyPublicURL(post_data.public_post.public_post_id)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Copy Public URL</span>
                      </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted"
              @click="triggerEdit(post_data);" v-if="($can('post_edit') &&
                post_data.user.hashid == userData.hashid) ||
                $can('Zircly Admin')
                ">
              <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
              <span class="">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
              post_data.is_approved == 'false' && userData.need_approval == 'true'
              " @click="approvePost(post_data)">
              <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
              <span class="">Approve Post</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
              post_data.is_approved == 'true' && userData.need_approval == 'true'
              " @click="rejectPost(post_data)">
              <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
              <span class="">Reject Post</span>
            </b-dropdown-item>
            <b-dropdown-item v-clipboard:copy="shareUrl(post_data.hashid)" v-clipboard:success="onCopy"
              v-clipboard:error="onError" class="btn-sm p-0 text-muted">
              <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
              <span class="">Copy</span>
            </b-dropdown-item>

            <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(post_data)" v-if="($can('post_delete') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              ">
              <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
              <span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Text Post -->
        <div class="d-flex d-md-none mb-1">
          <b-badge pill variant="light-info"  v-if="post_data.is_scheduled == true && post_data.is_published == 'false'" >Scheduled : {{ post_data.created_at}} </b-badge>
        </div>
        <div class="post-body" v-if="post_data.other_details && post_data.other_details.post_type == 'text-post'">
          <div  class="" style="position: relative">
            <div>
              <b-card-text >
                <h2 class="card-header pb-1 mt-lg-0">{{ post_data.title }}</h2>
              </b-card-text>
              <!-- <b-card-text v-html="post_data.body"></b-card-text> -->

              <b-card-text v-html="post_data.body"></b-card-text>
              
              <div v-if="post_data.attachments.length">
                <h2 class="mt-2 mb-2">Attachments</h2>
                <div
                  class="mb-2 mt-2 d-flex justify-content-center justify-content-lg-start"
                  :key="'carousel-' + banner_index"
                  v-for="(banner, banner_index) in post_data.attachments"
                >
                  <b-img
                    v-if="banner.type == 'image/png' || banner.type == 'image/jpeg'"
                    :src="banner.link"
                    rounded
                    class="mb-25"
                    style="max-height: 400px; max-width: 100%"
                  />
                </div>
              </div>

              <div
                v-for="(video, video_index) in post_data.attachments"
                :key="'attachments-' + video_index"
              >
                <b-embed
                  v-if="video.type && video.type.startsWith('video/') && video.link"
                  type="video"
                  aspect="16by9"
                  controls
                  autoplay="false"
                  autostart="false"
                  class="rounded mb-50"
                >
                  <source :src="video.link" type="video/webm" />
                  <source :src="video.link" type="video/mp4" />
                </b-embed>
                <embed
                  class="mt-1 rounded"
                  v-else-if="video.type == 'application/pdf'"
                  :src="video.link"
                  frameBorder="0"
                  scrolling="auto"
                  width="100%"
                  style="min-height: 50vh"
                />
                <div
                  v-else-if="video.type == 'image/png' || video.type == 'image/jpeg'"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Text Post -->

        <!-- Photo Post -->
        <div class="post-body" v-if="post_data.other_details && post_data.other_details.post_type == 'photo-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" >
                <b-img fluid rounded :src="post_data.attachments[0].link" class="post-img" />
              </div>
              <b-card-text v-html="post_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Photo Post -->
        <!-- Video Post -->
        <div class="post-body" v-if="post_data.other_details && post_data.other_details.post_type == 'video-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" >
                <b-embed type="iframe" aspect="16by9" :src="post_data.attachments[0] && post_data.attachments[0].link" allowfullscreen/>
              </div>
              <b-card-text v-html="post_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Video Post -->

        <!-- Quote Post -->
        <div class="post-body" v-if="post_data.other_details && post_data.other_details.post_type == 'quote-post'">
          <div  class="quote-post" style="position: relative">
            <div>
              <b-card-text>
                {{ post_data.body }}
              </b-card-text>
              <p class="author-name my-1 pt-1" v-if="post_data.other_details.author"><strong>Author Name : {{post_data.other_details.author}}</strong></span></p>
            </div>
          </div>
        </div>
        <!-- End Quote Post -->
        <!-- likes comments  share-->
        <b-row class="mt-1 like-button top-cmd" v-if="post_data.is_published == 'true'">
          <b-col cols="8" class="d-flex justify-content-sm-start pl-0">
            <b-link class="d-flex align-items-center text-muted text-nowrap">
              <div class="likes" v-if="!post_data.youLiked">
                <!-- <feather-icon
                  icon="HeartIcon"
                  class="mr-50 text-primary profile-icon"
                  size="24"
                  title="Like"
                  @click="likePost(post_data, 'like')"
                /> -->
                <!-- <b-img
                  :src="require('@/assets/images/icons/like/beforeLike.png')"
                  width="24"
                  title="Like"
                  class="mr-50 active-like"
                  @click="likePost(post_data, 'like')"
                ></b-img> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"
                                    fill="none" title="Like" class="active-like"
                                    @click="likePost(post_data, 'like')">
                                    <g>
                                      <path
                                        d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                                        stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath>
                                        <rect width="20" height="20" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                <div class="emoji-reactions">
                  <div class="emoji-item" @click="likePost(post_data, 'like')">
                    <b-img
                      :src="require('@/assets/images/icons/like/like.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Like"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'clap')">
                    <b-img
                      :src="require('@/assets/images/icons/like/clapping.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Celebrate"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'love')">
                    <b-img
                      :src="require('@/assets/images/icons/like/love-icon.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Love"
                    ></b-img>
                  </div>
                  <div
                    class="emoji-item"
                    @click="likePost(post_data, 'support')"
                  >
                    <b-img
                      :src="
                        require('@/assets/images/icons/like/hand-heart.svg')
                      "
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Support"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'idea')">
                    <b-img
                      :src="require('@/assets/images/icons/like/lightbulb.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Insightful"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'smile')">
                    <b-img
                      :src="require('@/assets/images/icons/like/smile.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Funny"
                    ></b-img>
                  </div>
                </div>
              </div>
              <div
                class="likes"
                v-if="post_data.youLiked && post_data.likeType"
              >
                <div
                  v-if="post_data.likeType == 'like'"
                  @click="likePost(post_data, 'like')"
                >
                  <b-img
                    :src="require('@/assets/images/icons/like/like.svg')"
                    width="24"
                    title="Like"
                    class="mr-50 active-like"
                  ></b-img>
                </div>
                <div
                  v-if="post_data.likeType == 'clap'"
                  @click="likePost(post_data, 'clap')"
                >
                  <b-img
                    :src="require('@/assets/images/icons/like/clapping.svg')"
                    width="24"
                    title="Celebrate"
                    class=""
                  ></b-img>
                </div>
                <div
                  v-if="post_data.likeType == 'love'"
                  @click="likePost(post_data, 'love')"
                >
                  <b-img
                    :src="require('@/assets/images/icons/like/love-icon.svg')"
                    width="24"
                    title="Love"
                    class=""
                  ></b-img>
                </div>
                <div
                  v-if="post_data.likeType == 'support'"
                  @click="likePost(post_data, 'support')"
                >
                  <b-img
                    :src="require('@/assets/images/icons/like/hand-heart.svg')"
                    width="24"
                    title="Support"
                    class=""
                  ></b-img>
                </div>
                <div
                  v-if="post_data.likeType == 'idea'"
                  @click="likePost(post_data, 'idea')"
                >
                  <b-img
                    :src="require('@/assets/images/icons/like/lightbulb.png')"
                    width="24"
                    title="Insightful"
                    class="mr-50 active-idea"
                  ></b-img>
                </div>
                <div
                  v-if="post_data.likeType == 'smile'"
                  @click="likePost(post_data, 'smile')"
                >
                  <b-img
                    :src="require('@/assets/images/icons/like/smile.svg')"
                    width="24"
                    title="Funny"
                    class=""
                  ></b-img>
                </div>

                <div class="emoji-reactions">
                  <div class="emoji-item" @click="likePost(post_data, 'like')">
                    <b-img
                      :src="require('@/assets/images/icons/like/like.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Like"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'clap')">
                    <b-img
                      :src="require('@/assets/images/icons/like/clapping.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Celebrate"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'love')">
                    <b-img
                      :src="require('@/assets/images/icons/like/love-icon.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Love"
                    ></b-img>
                  </div>
                  <div
                    class="emoji-item"
                    @click="likePost(post_data, 'support')"
                  >
                    <b-img
                      :src="
                        require('@/assets/images/icons/like/hand-heart.svg')
                      "
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Support"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'idea')">
                    <b-img
                      :src="require('@/assets/images/icons/like/lightbulb.svg')"
                      width="35"
                      v-b-tooltip.hover.v-default
                      title="Insightful"
                    ></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data, 'smile')">
                    <b-img
                      :src="require('@/assets/images/icons/like/smile.svg')"
                      width="35"
                      v-b-tooltip.hover.v-defult
                      title="Funny"
                    ></b-img>
                  </div>
                </div>
              </div>
              <span
                v-if="post_data.likes_count"
                v-b-modal="'show-likes-' + post_data.hashid"
                class="text-primary"
                style="margin-left: 4px"
                >{{ kFormatter(post_data.likes_count) }}</span
              >
            </b-link>
          </b-col>
          <b-col
            cols="4"
            class="d-flex justify-content-end align-items-center mb-0 right-icons"
          >
            <!-- <b-link class="text-body text-nowrap">
              <feather-icon icon="MessageCircleIcon" size="20" class="profile-icon mr-50 text-primary" />
              <span v-if="post_data.comments_count" class="text-muted">{{
                kFormatter(post_data.comments_count)
              }}</span>
            </b-link>
            <b-link
              v-if="post_data.visit_count_total"
              class="text-body text-nowrap d-flex align-items-center mx-2"
              @click="showVisits(post_data.hashid)"
            >
              <feather-icon
                icon="EyeIcon"
                size="20"
                class="profile-icon mr-50 text-primary"
              />
              <span class="text-muted">{{
                kFormatter(post_data.visit_count_total)
              }}</span>
            </b-link> -->
            <b-link
                  v-if="post_data.comments_count > 0"
                  v-b-toggle.add-sidebar-view
                  class="text-body text-nowrap d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="20"
                    class="profile-icon mr-25 text-primary"
                    v-b-tooltip.hover.v-default
                    title="Comments"
                  />
                  <span class="text-muted">{{ kFormatter(post_data.comments_count) }}</span>
                </b-link>
                <feather-icon
                  v-else
                  icon="MessageCircleIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Comments"
                  style="cursor: pointer;"
                />
                <b-link v-if="post_data.visit_count_total > 0" class="text-body text-nowrap d-flex align-items-center mx-1"
                      @click="showVisits(post_data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(post_data.visit_count_total)
                      }}</span>
                </b-link>
                    <feather-icon
                  v-else
                  icon="EyeIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Views"
                  style="cursor: pointer;"
                />
            <b-modal
              :id="'open-visits-' + post_data.hashid"
              :ref="'open-visits-' + post_data.hashid"
              hide-footer
              modal-class="modal-primary"
              scrollable
              title="Views"
              class="text-danger"
            >
              <b-list-group v-if="postViews.length">
                <b-list-group-item
                  v-for="user in postViews"
                  :key="'user-' + user.hashid"
                  class="d-flex align-items-center"
                >
                  <b-avatar :src="user.avatar" size="24" class="mr-75" />
                  {{ user.name }}
                </b-list-group-item>
              </b-list-group>
              <b-card-text v-else-if="isBusy">
                <div class="d-flex align-items-center">
                  <strong>Loading...</strong>
                  <b-spinner class="ml-auto" variant="primary" />
                </div>
              </b-card-text>
              <b-card-text v-else>
                <div class="item error text-center my-2 empty-data">
                  <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
                  <h4> OH NO!</h4>
                  <p class="d-block mb-1">No Records Found!</p>
                </div>
              </b-card-text>
            </b-modal>
            <!--<b-link class="text-body text-nowrap">
            <feather-icon
              icon="Share2Icon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="text-muted">{{ kFormatter(post_data.share) }}</span>
          </b-link>-->
          </b-col>
        </b-row>
        <b-row class="mt-1 like-button top-cmd delayed-post" @click.stop v-else>
          <b-col sm="12" class="d-flex justify-content-end pb-2 align-items-center">
            <div v-if="post_data.is_scheduled == false" class="draft-post">
              <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(post_data)" v-if="($can('post_delete') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              " >Delete</b-button>
              <b-button variant="primary" class="edit-btn opacity-100 ml-1" pill @click="triggerEdit(post_data);" v-if="($can('post_edit') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              " >Edit</b-button>
            </div>
            <div v-if="post_data.is_scheduled == true" class="post-schedule">
              <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(post_data)" v-if="($can('post_delete') &&
              post_data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Delete</b-button>
              <b-button variant="outline-secondary" pill class="edit-btn ml-1" @click="triggerEdit(post_data);" v-if="($can('post_edit') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              " >Edit</b-button>
              <b-button variant="primary" class="post-btn opacity-100 ml-1" pill @click="triggerPostNow(post_data);" v-if="($can('post_edit') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              ">Post Now</b-button>
            </div>
          </b-col>
        </b-row>
        <!--/ likes comments  share-->

        <template #footer v-if="post_data.is_published == 'true'" >
          <div class="comment-details">
            <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
            <div
              class="w-100 border p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75 ml-2 my-2 main-post"
            >
              <CommentBox
                placeholder="Share your thoughts..."
                className="w-100 comment-box"
                :id="'comment-field-' + post_data.hashid"
                :toolbar="false"
                :mentionList="employeeListOptions"
              />
            </div>
              <b-button
                variant="primary"
                @click="addComment(post_data)"
                class="blog-btn-block mr-1 search-post rounded-circle p-75"
                :disabled="commentSubmitClicked"
              >
              <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="26"></b-img>
            </b-button>
            </div>
            <!-- comments -->
            <div class="pb-2 px-2 bg-reply" v-if="post_data.comments && post_data.comments.length">
              <div
                v-for="(comment, index) in post_data.comments"
                :key="'comment-' + index"
                class="reply-post mb-1 mt-50 view-cmd"
              >
              <div class="cmd-main view-over">
                <div class="d-flex align-items-start w-100 over-cmd">
                  <b-avatar
                    :src="comment.user.avatar"
                    size="30"
                    class="mr-75 mt-0"
                  />
                  <div
                    v-if="!editComments.includes(comment.hashid)"
                    class="profile-user-info w-100 right-cmt"
                  >
                    <div
                      class="d-flex align-items-start justify-content-between"
                    >
                      <div class="profile-user-info">
                        <h6 class="mb-0">
                          {{ comment.user.name }}
                        </h6>
                        <small style="font-size: 12px">
                          {{ comment.user.designation }}
                        </small>
                      </div>
                      <div
                        class="d-flex flex-column flex-lg-row align-items-lg-center"
                      >
                        <span class="small mr-50">
                          {{ comment.created_at }}
                        </span>
                        <!-- More Options Dots -->
                        <b-dropdown
                          class="blog-dropdown"
                          variant="link"
                          size="sm"
                          toggle-class="text-decoration-none p-0"
                          no-caret
                          v-if="comment.user.hashid == userData.hashid"
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25 text-muted"
                              v-b-tooltip.hover.v-default
                              title="More Options"
                            />
                          </template>
                          <b-dropdown-item
                            class="btn-sm p-0 text-muted"
                            v-if="comment.user.hashid == userData.hashid"
                            @click="
                              editComments.pop(),
                                editComments.push(comment.hashid)
                            "
                          >
                            <feather-icon
                              icon="EditIcon"
                              size="18"
                              v-b-tooltip.hover.v-default
                              class="text-primary mr-50"
                              title="Comment Edit"
                            /><span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            class="btn-sm p-0 text-danger"
                            @click="deleteComment(comment.hashid)"
                            v-if="comment.user.hashid == userData.hashid"
                          >
                            <feather-icon
                              icon="TrashIcon"
                              size="18"
                              v-b-tooltip.hover.v-danger
                              class="text-primary mr-50"
                              title="Delete Comment"
                            />
                            <span class="">Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                        <!-- End More Options Dots -->
                        <!-- <div class="d-none d-lg-block">
                    <b-link
                      @click="likeComment(comment, index)"
                      class="text-body btn btn-icon rounded-circle p-50"
                      v-b-tooltip.html.hover="
                        'Liked by ' + getNames(comment.likes)
                      "
                    >
                      <feather-icon
                        icon="HeartIcon"
                        size="18"
                        class="mr-25"
                        :class="
                          comment.youLiked
                            ? 'profile-likes'
                            : 'profile-icon'
                        "
                      />
                      <span
                        v-if="comment.likes_count"
                        class="text-muted align-middle"
                        >{{ kFormatter(comment.likes_count) }}</span
                      >
                    </b-link>
                    <b-link
                      v-if="comment.user.hashid == userData.hashid"
                      class="btn btn-icon text-primary rounded-circle p-50"
                      @click="
                        editComments.pop(),
                          editComments.push(comment.hashid)
                      "
                    >
                      <feather-icon icon="EditIcon" size="18" 
                      v-b-tooltip.hover.v-default
                      title="Comment Edit"/>
                    </b-link>
                    <b-link
                      v-if="comment.user.hashid == userData.hashid"
                      class="text-danger btn btn-icon rounded-circle p-50"
                      @click="
                        deleteComment(comment.hashid, post.hashid, index)
                      "
                    >
                      <feather-icon icon="TrashIcon" size="18" 
                      v-b-tooltip.hover.v-danger
                      title="Delete Comment"/>
                    </b-link>
                    <b-link
                      class="btn btn-icon text-primary rounded-circle p-50"
                      @click="addReply(comment)"
                    >
                      <feather-icon icon="CornerUpLeftIcon" size="18" 
                      v-b-tooltip.hover.v-default
                      title="Reply to Comment" />
                    </b-link>
                  </div> -->
                      </div>
                    </div>
                    <p
                      v-html="comment.comment"
                      class="mb-0 mt-75"
                      style="letter-spacing: 1px; font-size: 14px"
                    ></p>
                    <div class="d-none mb-lg-0 d-lg-none">
                      <b-link
                        @click="likeComment(comment, index)"
                        class="text-body btn btn-icon rounded-circle pl-0 pt-50 pb-50 pr-50"
                      >
                        <feather-icon
                          icon="HeartIcon"
                          size="18"
                          class="mr-25"
                          :class="
                            comment.youLiked ? 'profile-likes' : 'profile-icon'
                          "
                        />
                        <span
                          v-if="comment.likes_count"
                          class="text-muted align-middle"
                          >{{ kFormatter(comment.likes_count) }}</span
                        >
                      </b-link>
                      <b-link
                        v-if="comment.user.hashid == userData.hashid"
                        class="btn btn-icon text-primary rounded-circle p-50"
                        @click="
                          editComments.pop(), editComments.push(comment.hashid)
                        "
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          v-b-tooltip.hover.v-default
                          title="Edit Comment"
                        />
                      </b-link>
                      <b-link
                        v-if="comment.user.hashid == userData.hashid"
                        class="text-danger btn btn-icon rounded-circle p-50"
                        @click="deleteComment(comment.hashid)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          v-b-tooltip.hover.v-danger
                          title="Delete Comment"
                        />
                      </b-link>
                      <b-link
                        class="btn btn-icon text-primary rounded-circle p-50"
                        @click="addReply(comment)"
                      >
                        <feather-icon
                          icon="CornerUpLeftIcon"
                          size="18"
                          v-b-tooltip.hover.v-default
                          title="Repy to Comment"
                        />
                      </b-link>
                    </div>

                    <!-- <div>
                <b-link class="nav-link" @click="addReply(comment, index)">
                  <feather-icon
                    icon="CornerUpLeftIcon"
                    size="18"
                    class="mr-25"
                  />
                  Reply
                </b-link>
              </div> -->
                  </div>
                  <div v-else="editComments.includes(comment.hashid)" class="w-100 cmt-posts d-flex align-items-end justify-content-centent">
                  <div class="border p-751 d-flex mb-0 flex-row align-items-center justify-content-around commentBox round w-100">
                    <CommentBox
                      placeholder="Share your thoughts..."
                      className="w-100 comment-box"
                      :id="'comment-field-' + comment.hashid"
                      :value="comment.comment"
                      :toolbar="false"
                      :mentionList="employeeListOptions"
                    />
                    </div>
                    <b-button
                      variant="primary"
                      @click="editComment(comment)"
                      pill
                      class="blog-btn-block ml-50 search-post w-auto mb-0 save-button"
                      :disabled="commentSubmitClicked"
                    >
                    <strong><b>Save</b></strong>
                    </b-button>
                    <b-link
                    v-if="editComments.includes(comment.hashid)"
                    class="btn btn-icon rounded-circle btn-outline-danger ml-75 p-50 mb-25"
                    @click="editComments.pop()"
                  >
                    <!-- @click="editComments = []" -->
                    <feather-icon
                      icon="XIcon"
                      size="18"
                      v-b-tooltip.hover.v-default
                      title="Cancel"
                    />
                  </b-link>
                  </div>
                </div>
                <div class="option-reply d-flex align-items-center justify-content-between w-100 flex-row-reverse pl-3">
          <b-row class="like-button mx-0">
            <div class="action-group d-flex align-items-center my-25">
              <b-link
                class="d-flex align-items-center text-muted text-nowrap"
              >
                <div class="likes" v-if="!comment.youLiked">
                  <!-- <feather-icon
                    icon="HeartIcon"
                    class="mr-50 text-primary profile-icon"
                    size="18"
                    title="Like"
                    @click="like(comment.hashid, index, 'like')"
                  /> -->
                  <span  title="Like"
                    class="mr-50 active-like"
                    @click="like(comment.hashid, index, 'like')">Like </span>
                  <div class="emoji-reactions">
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'like')"
                    >
                      <b-img
                        :src="require('@/assets/images/icons/like/like.svg')"
                        width="18"
                        v-b-tooltip.hover.v-default
                        title="Like"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'clap')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/clapping.svg')
                        "
                        width="18"
                        v-b-tooltip.hover.v-default
                        title="Celebrate"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'love')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/love-icon.svg')
                        "
                        width="18"
                        v-b-tooltip.hover.v-default
                        title="Love"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'support')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/hand-heart.svg')
                        "
                        width="18"
                        v-b-tooltip.hover.v-default
                        title="Support"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'idea')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/lightbulb.svg')
                        "
                        width="18"
                        v-b-tooltip.hover.v-default
                        title="Insightful"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'smile')"
                    >
                      <b-img
                        :src="require('@/assets/images/icons/like/smile.svg')"
                        width="18"
                        v-b-tooltip.hover.v-default
                        title="Funny"
                      ></b-img>
                    </div>
                  </div>
                </div>
                <div class="likes" v-if="comment.youLiked && comment.likeType">
                  <div
                    v-if="comment.likeType == 'like'"
                    @click="like(comment.hashid, index, 'like')"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/like/like.svg')"
                      width="20"
                      title="Like"
                      class="mr-50 active-like"
                    ></b-img>
                  </div>
                  <div
                    v-if="comment.likeType == 'clap'"
                    @click="like(comment.hashid, index, 'clap')"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/like/clapping.svg')"
                      width="20"
                      title="Celebrate"
                      class=""
                    ></b-img>
                  </div>
                  <div
                    v-if="comment.likeType == 'love'"
                    @click="like(comment.hashid, index, 'love')"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/like/love-icon.svg')"
                      width="20"
                      title="Love"
                      class=""
                    ></b-img>
                  </div>
                  <div
                    v-if="comment.likeType == 'support'"
                    @click="like(comment.hashid, index, 'support')"
                  >
                    <b-img
                      :src="
                        require('@/assets/images/icons/like/hand-heart.svg')
                      "
                      width="20"
                      title="Support"
                      class=""
                    ></b-img>
                  </div>
                  <div
                    v-if="comment.likeType == 'idea'"
                    @click="like(comment.hashid, index, 'idea')"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/like/lightbulb.svg')"
                      width="20"
                      title="Insightful"
                      class="active-idea"
                    ></b-img>
                  </div>
                  <div
                    v-if="comment.likeType == 'smile'"
                    @click="like(comment.hashid, index, 'smile')"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/like/smile.svg')"
                      width="20"
                      title="Funny"
                      class=""
                    ></b-img>
                  </div>

                  <div class="emoji-reactions">
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'like')"
                    >
                      <b-img
                        :src="require('@/assets/images/icons/like/like.svg')"
                        width="22"
                        v-b-tooltip.hover.v-default
                        title="Like"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'clap')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/clapping.svg')
                        "
                        width="22"
                        v-b-tooltip.hover.v-default
                        title="Celebrate"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'love')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/love-icon.svg')
                        "
                        width="22"
                        v-b-tooltip.hover.v-default
                        title="Love"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'support')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/hand-heart.svg')
                        "
                        width="22"
                        v-b-tooltip.hover.v-default
                        title="Support"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'idea')"
                    >
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/lightbulb.svg')
                        "
                        width="22"
                        v-b-tooltip.hover.v-default
                        title="Insightful"
                      ></b-img>
                    </div>
                    <div
                      class="emoji-item py-0"
                      @click="like(comment.hashid, index, 'smile')"
                    >
                      <b-img
                        :src="require('@/assets/images/icons/like/smile.svg')"
                        width="22"
                        v-b-tooltip.hover.v-default
                        title="Funny"
                      ></b-img>
                    </div>
                  </div>
                </div>

                <!-- <span
                  class="text-primary"
                  v-if="comment.likes_count"
                  v-b-modal="'show-likes-' + comment.hashid"
                  >{{ kFormatter(comment.likes_count) }}</span
                > -->
              </b-link>
              <b-modal
                :id="'show-likes-' + comment.hashid"
                hide-footer
                modal-class="modal-primary modal-likes"
                scrollable
                title="Reactions"
                class="text-danger"
              >
                <b-list-group v-if="comment.likes && comment.likes.length">
                  <b-list-group-item
                    v-for="like in comment.likes"
                    :key="'user-' + like.user.hashid"
                    class="d-flex align-items-center"
                  >
                    <b-avatar :src="like.user.avatar" size="24" class="mr-1" />
                    {{ like.user.name }}
                    <div class="ml-auto" v-if="like.type == 'like'">
                      <b-img
                        :src="require('@/assets/images/icons/like/like.svg')"
                        width="24"
                        title="Like"
                      ></b-img>
                    </div>
                    <div class="ml-auto" v-if="like.type == 'clap'">
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/clapping.svg')
                        "
                        width="24"
                        title="Celebrate"
                      ></b-img>
                    </div>
                    <div class="ml-auto" v-if="like.type == 'love'">
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/love-icon.svg')
                        "
                        width="24"
                        title="Love"
                      ></b-img>
                    </div>
                    <div class="ml-auto" v-if="like.type == 'support'">
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/hand-heart.svg')
                        "
                        width="24"
                        title="Support"
                      ></b-img>
                    </div>
                    <div class="ml-auto" v-if="like.type == 'idea'">
                      <b-img
                        :src="
                          require('@/assets/images/icons/like/lightbulb.svg')
                        "
                        width="24"
                        title="Insightful"
                      ></b-img>
                    </div>
                    <div class="ml-auto" v-if="like.type == 'smile'">
                      <b-img
                        :src="require('@/assets/images/icons/like/smile.svg')"
                        width="24"
                        title="Funny"
                      ></b-img>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-card-text
                  v-else-if="comment.likes && comment.likes.length == 0"
                >
                <div class="item error text-center my-2 empty-data">
                  <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
                  <h4> OH NO!</h4>
                  <p class="d-block mb-1">No Records Found!</p>
                </div>
                </b-card-text>
                <b-card-text v-else-if="comment.likes == null">
                  <div class="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <b-spinner class="ml-auto" variant="primary" />
                  </div>
                </b-card-text>
              </b-modal>
              <!-- <div class="d-none d-lg-flex mobile-avatar align-items-center">                    
                    <b-avatar-group size="18" class="ml-75">
                      <div class="d-flex reaction-details"  v-for="(like, i) in getMosReactions(comment.likes)">
                        <b-img v-if="like == 'love'" :src="require('@/assets/images/icons/like/love-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'idea'" :src="require('@/assets/images/icons/like/idea-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'clap'" :src="require('@/assets/images/icons/like/clap-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'like'" :src="require('@/assets/images/icons/like/thumb-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'support'" :src="require('@/assets/images/icons/like/handlove-emji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'smile'" :src="require('@/assets/images/icons/like/smile-emji.png')" width="18" height="18"/>
                      </div>
                      <span class="px-25"></span>
                      <b-avatar
                        v-for="(like, i) in comment.likes"
                        v-if="i < 3"
                        :key="'avatar-' + i"
                        v-b-tooltip.hover.bottom="like.user.name"
                        class="pull-up m-0"
                        :src="like.user.avatar"
                      />
                    </b-avatar-group>
                    <b-link
                      v-if="comment.likes_count > 3"
                      class="text-primary text-nowrap ml-25"
                      v-b-modal="'show-likes-' + comment.hashid"
                    >
                      +{{ comment.likes_count - 3 }} more
                    </b-link>
                  </div> -->
            </div>
            <div class="reply-action d-flex align-items-center mt-0">
              <b-link
                class="btn btn-icon text-primary rounded-circle p-50 small"
                @click="addReply(comment, index)"
              > Reply
              </b-link>
            </div>
          </b-row>
          </div>
                </div>
                <!-- Reply and Like Section -->
                <div class="reply-cmd">
                  <div
                    v-if="comment_id && comment_id == comment.hashid"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="small">
                      Reply to <span class="font-weight-bolder">{{ reply_to }}</span>
                      <!-- <b-link class="nav-links ml-1">
                        
                      </b-link> -->
                    </div>
                    <b-link
                      class="text-primary btn btn-icon rounded-circle p-50"
                      @click="removeReply"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        v-b-tooltip.hover.v-default
                        title="Cancel"
                      />
                    </b-link>
                  </div>
                  <!--/ add comments -->
                  <div class="w-100 cmt-posts d-flex align-items-end justify-content-center" v-if="comment_id && comment_id == comment.hashid">
                  <div class="border p-751 d-flex mb-1 flex-row align-items-center justify-content-around commentBox round w-100"
                  >
                    <CommentBox
                      placeholder="Enter Your Reply"
                      className="w-100 comment-box"
                      :id="'comment-field-' + comment_id"
                      :toolbar="false"
                      :mentionList="employeeListOptions"
                    />
                  </div>
                    <b-button
                      variant="primary"
                      @click="addReplyComment(comment)"
                      pill
                      class="blog-btn-block search-post rounded-circle p-75 ml-75"
                      :disabled="commentSubmitClicked"
                    >
                      <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="26"></b-img>
                    </b-button>
                  </div>
                  <Replies
                    v-if="comment.reply_count"
                    :itemId="comment.hashid"
                    :reply_count="comment.reply_count"
                    :mentionList="employeeListOptions"
                  />
                </div>
                <!-- End Reply and Like Section -->
              </div>
            </div>
            <!--/ comments -->
          </div>
        </template>
        <b-overlay :show="isBusy" no-wrap> </b-overlay>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BImg,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { kFormatter } from "@core/utils/filter";
import { bus } from "@/main.js";
import axios from "@axios";
// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import Replies from "./Replies.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BImg,
    BEmbed,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    Replies,
    QuillInput,
    CommentBox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarViewActive",
    event: "update:sidebarViewActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarViewActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    employeeListOptions: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: true,
      data_local: {
        channel_type: "community",
      },
      show: false,
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      post_data: {
        user: {},
        attachments: {},
      },
      comment_id: null,
      reply_to: null,
      userData: this.$cookies.get("userData"),
      editComments: [],
      postViews: [],
      postData: null,
      commentSubmitClicked:false,
      hashPostTypes: [
        "####photo-post####",
        "####video-post####",
        "####quote-post####",
      ],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarViewActive(val) {

      if(val){
        const body = document.body;
        body.classList.add('modal-open');

        const stateObj = { postId: this.itemId };
        const title = 'Post Page';
        const url = `?post_hash_id=${stateObj.postId}`; // Update the URL format as needed
        history.replaceState(stateObj, title, url)
      }else{
        const body = document.body;
        body.classList.remove('modal-open');

        const stateObj = { postId: this.itemId };
        const title = 'Community Home Page'; // Update the URL format as needed
        history.replaceState(stateObj, title, this.$route.path)
        this.$refs.sidebar_view.hide();
      }
      this.show = val;
      this.post_data = {
        user: {},
        attachments: {},
      };
      this.data_local = {
        channel_type: "community",
      };
      this.myFiles = [];
      if (this.itemId && this.itemData) {
        this.post_data = this.itemData;
        this.isBusy = false;
      }
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.post_hash_id) {
      // this.getPostData(this.$route.query.post_hash_id);
      this.itemId = this.$route.query.post_hash_id;
      this.show = true;
    }
  },
  methods: {
    shareUrl(hash_id) {
      return window.location.origin + "?post_hash_id=" + hash_id;
    },
    onCopy: function (e) {
      this.$toast.success("You just copied URL to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the URL to the clipboard");
      console.log(e);
    },
    showVisits(postId) {
      const self = this;
      let data = {};
      data.url = "/post-visits/" + postId;
      self.isBusy = true;
      self.postViews = [];
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.postViews = res.data.data;
          var modal = "open-visits-" + postId;
          self.$bvModal.show(modal);
          self.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    kFormatter,
    addReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      self.reply_to = comment.user.name;
      //document.getElementById("comment-field-" + comment.hashid).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = data.null;
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    likeComment(comment, index, type) {
      if (comment.youLiked && comment.likeType == type) {
        this.$http.delete(`/comments/${comment.hashid}/like`).then((res) => {
          // this.$parent.updatePostArray(res.data.data, index);
          comment.youLiked = false;
          comment.likeType = false;
          this.$set(this.post_data.comments, index, comment);
        });
      } else {
        this.$http
          .post(`/comments/${comment.hashid}/like/${type}`)
          .then((res) => {
            comment.likeType = type;
            comment.youLiked = true;
            this.$set(this.post_data.comments, index, comment);
          });
      }
      this.parentRefresh();
    },

    

    likePost(data, type) {
      if (data.youLiked && data.likeType == type) {
        this.$http.delete(`/posts/${data.hashid}/like`).then((res) => {
          data.youLiked = false;
          data.likes_count--;
          this.updatePostArray(res.data.data);
        });
      } else {
        this.$http.post(`/posts/${data.hashid}/like/${type}`).then((res) => {
          data.youLiked = true;
          data.likeType = type;
          data.likes_count++;
          this.updatePostArray(res.data.data);
        });
      }
      this.parentRefresh();
    },
    deleteComment(commentId) {
      this.$http
        .delete(`/posts/${this.itemId}/comments/${commentId}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment deleted successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
          this.getPostData(this.itemId);
          this.updatePostArray(res.data.data);
        });
      this.parentRefresh();

    },
    editComment(comment) {
      this.commentSubmitClicked = true;
      const self = this;
      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.getPostData(this.itemId);
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
            this.commentSubmitClicked = false;
          }
        });
      this.parentRefresh();
      return true;
    },
    getPostData(itemId) {
      if (!itemId) {
        console.log("no itemId");
        return true;
      }
      const self = this;

      let data = {};
      data.url = "/posts/" + itemId;
      if (
        !this.itemData ||
        (this.itemData && this.itemId != this.itemData.hashid)
      ) {
        self.isBusy = true;
      }
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.post_data = res.data.data;
          self.isBusy = false;
        })
        .catch((error) => {
          this.show = false;
          if (error.response.status === 404) {
            return self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: "Post not found",
              },
            });
          }
          // console.log(error.response.status);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    resetFormData() {
      this.post_data = {
        user: {},
        attachments: {},
      };
      // Select the <body> element
      const body = document.body;

      // Remove a class from the <body> element
      body.classList.remove('modal-open');
    },
    openWindow(link) {
      window.open(link);
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-tickets/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addReplyComment(comment_data) {
      this.commentSubmitClicked = true;
      const self = this;
      const comment = document.getElementById(
        `comment-field-${comment_data.hashid}`
      ).value;
      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: self.comment_id,
      };

      this.$http
        .post(`/posts/${this.itemId}/comments`, params)
        .then((res) => {
          self.post_data = res.data.data;
          self.reply_to = null;
          self.comment_id = null;
          document.getElementById(
            `comment-field-${comment_data.hashid}`
          ).value = "";
          $(".ql-editor").html("");
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
            this.commentSubmitClicked = false;
          }
        });
      this.parentRefresh();
      return true;
    },
    addComment(post_data) {
      this.commentSubmitClicked = true;
      const self = this;
      const comment = document.getElementById(
        `comment-field-${post_data.hashid}`
      ).value;
      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: self.comment_id,
      };

      this.$http
        .post(`/posts/${post_data.hashid}/comments`, params)
        .then((res) => {
          self.post_data = res.data.data;
          self.reply_to = null;
          self.comment_id = null;
          document.getElementById(`comment-field-${post_data.hashid}`).value =
            "";
          $(".ql-editor").html("");
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
            this.commentSubmitClicked = false;
          }
        });
      this.parentRefresh();
      return true;
    },
    pinPost(data) {
      axios.get("/pin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          bus.$emit("loadPosts");
          this.getPostData(this.itemId);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Pinned",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to pin post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
      this.parentRefresh();
    },
    unpinPost(data) {
      axios.get("/unpin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPostData(this.itemId);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Un-Pined",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to unpin post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
      this.parentRefresh();
    },

    postPublicURL(data) {
    let payLoad = {
      post_id: data.hashid,
    };
  this.$http
  .post(`/posts/post_public_url`, payLoad)
  .then((res) => {

    this.boxOne = '';
    const modalContent = this.$createElement('div', [
  this.$createElement('p', 'Ready to share? Copy the link to this post. Your organization and personal details will remain confidential.'),
  this.$createElement('div', { style: 'border: 1px solid #ccc;display:flex' }, [
  this.$createElement('strong', `URL: ${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`),,
    this.$createElement('button', {
      class: 'btn btn-primary',
      on: {
        click: () => {
          const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
          navigator.clipboard.writeText(copyText); // Copy text to clipboard
          this.getPostData(this.itemId)
          this.parentRefresh();
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: 'Link copied to clipboard!',
            },
          });
        },
      },
    }, 'Copy Link'),
  ]),
]);

    this.$bvModal.msgBoxConfirm(
      modalContent,
  {
    title: "Generate Public URL",
    cancelVariant: 'outline-secondary',
    centered: true, // Center the popup
    buttonSize: 'md',      
    hideHeaderClose: false,
    headerClass: 'bg-transparent',
    modalClass: 'public-modal',
    footerClass: 'd-none',
    hide: false ,
   'hide-footer': true

  }
).then((value) => {
      // if (value) {
      //   // Perform copy action here
      //   const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
      //   navigator.clipboard.writeText(copyText); // Copy text to clipboard
      //   this.getPosts();
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Success',
      //       icon: 'BellIcon',
      //       variant: 'success',
      //       text: 'link copied to clipboard!',
      //     },
      //   });
      // }
    });
  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
          text: err.toString(),
        },
      });
    }
  });
},
postRevokePublicURL(data){
  this.$bvModal.msgBoxConfirm(
    'you want to revoke the public link for this post? By confirming, the link will be revoked from all places you shared it.',
    {
      title: 'Are you sure ? ',
      size: 'md',
      okVariant: 'danger',
      okTitle: 'revoke',
      cancelTitle: 'cancel',
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      hideFooter: true,
      centered: true,
      headerClass: 'bg-transparent',
      modalClass: 'public-modal',
      footerClass: 'modal-footer',
    }
  ).then((value) => {
      if (value) {
        this.$http
   .delete(`/posts/revoke_public_url/${data.hashid}`)
   .then((res) => {
    this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: res.data.message,
          },
        });
        this.getPostData(this.itemId);

  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
          text: err.toString(),
        },
      });
    }
  });
      }
    });
    this.parentRefresh();
},
copyPublicURL(data){
  const copyText = `${window.location.href.split('?')[0]}public-post/${data}`;
        navigator.clipboard.writeText(copyText); // Copy text to clipboard
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: 'link copied to clipboard!',
          },
        });
},

    approvePost(data) {
      axios.get("post/approve/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPostData(this.itemId);
          // this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Approved",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to Approve post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
      this.parentRefresh();
    },
    rejectPost(data) {
      axios.get("post/reject/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPostData(this.itemId);
          // this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Reject",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to Reject post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
      this.parentRefresh();
    },
    updatePostArray(data) {
      self.post_data = data;
    },

    like(comment_id, index, type) {
      const self = this;
      let data = {};
      data.url = "comments/" + comment_id + "/like/" + type;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.getPostData(this.itemId);
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "liking failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
      this.parentRefresh();
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this post ${data.title }.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    deleteRecord(id) {
      let data = {};
      data.url = "posts/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
            // document.getElementById("post-card-" + id).remove();
            this.parentRefresh();
            this.$refs.sidebar_view.hide();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Post Deleted Successfully.",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          // this.refetchData();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Deletion failed.1",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    parentRefresh(){
      this.$emit('triggerParentRefresh');
    },
    triggerEdit(data){
      this.$emit('triggerEditPost',data);

    },
    triggerPostNow(data){
      this.$emit('triggerPostNow',data);

    }
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>

